<template>
<div class="processc">
  <div class="banner">
    <div class="bannerimg">
      <img src="../images/home/banner.png" alt="" draggable='false' />
    </div>
    <div class="banner_free">
      <div class="banner_registered">
        <p class="bannerFree">19.9元领取</p>
        <p class="banner_valueOF">价值<span>399</span>元试听课</p>
        <div class="banner_input">
          <input id="phone" type="text" maxLength="11" placeholder="请输入手机号" />
          <span id="errorformat"></span>
        </div>
        <div class="banner_button" @click="PopupShow">
          <img src="../images/home/questget.png" alt="" draggable='false' />
        </div>
      </div>
    </div>
  </div>
  <div class="howtosee">
    <p class="childResult">如何看课</p>
    <div class="HowToSee">
      <img src="../images/home/howtosee.png" alt="" draggable='false' />
    </div>
  </div>
  <div class="handsup">
    <p class="childResult">动手编程</p>
    <div class="HandsUp">
      <img src="../images/home/handsup.png" alt="" draggable='false' />
    </div>
  </div>
  <div class="preparationc">
    <p class="childResult">课程准备</p>
    <div class="Preparationc">
      <img src="../images/home/preparation.png" alt="" draggable='false' />
    </div>
  </div>
  <div>
    <div class="bottom_foot">
      <p class="bottom-message">地址: 河南省郑州市金水区文化路与红旗路交叉口西300米广厦城市之巅9号楼</p>
      <p class="bottom-dizhi">COPYRIGHT2020 行知塾教育集团 <span>豫ICP备19005091号-1</span></p>
    </div>
    <div class="bottom_get">
      <div class="bottomfooter">
        <div class="bottom_free">
          <div class="bottom_img">
            <img src="../images/home/twochilds.png" alt="" />
          </div>
          <p class="bottom_valueOF">19.9元领取价值<span>399元</span>体验课</p>
          <div class="bottom_input">
            <input id="bottom_tele" class="bottom_number" type="text" maxLength="11" placeholder="请输入手机号" />
            <span id="bottom_error"></span>
            <button id="bottom_Buttom" class="bottom_button" @click="popupShow">立即领取</button>
          </div>
        </div>
      </div>
    </div>
    <div id="Popupwindow" class="PopupWindow" v-if="poPup">
      <div class="PopupEX">
        <span class="Close_popup">
          <img src="../images/home/closePopup.png" alt="" draggable='false' @click="closePop"/>
        </span>
        <div class="popup_free">
          <p class="popup_get_1">19.9元领取</p>
          <p class="popup_get_2">价值<span>399元</span>编程体验课</p>
        </div>
        <div class="popup_quick">
          <div class="popup_quickly">
            <div class="popup_input">
              <input id="popup_tele" type="text" placeholder="请输入手机号" maxLength="11" name="mobile"/>
            </div>
            <div class="popup_YZM">
              <input id="popup_event" type="text" placeholder="短信验证码" maxLength="4" />
              <span id="popup_span" @click="handleClick">获取验证码</span>
            </div>
            <div class="popup_EXP" @click="successPopShow">
              <img src="../images/home/experience.gif" alt="" draggable='false' />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="Successful_CLASS" class="Successful" v-if="successPop">
      <div class="Successful_code">
        <span class="Successful_Close" @click="closeSPop">
          <img src="../images/home/closePopup.png" alt="" draggable='false' />
        </span>
        <div class="Successful_zero">
          <p class="Successful_look">看这里<span>0</span>元体验6节编程课</p>
        </div>
        <div class="yoyoQrcode">
          <img src="../images/home/yoyoqrcode.png" alt="" draggable='false' />
        </div>
        <div class="Successful_focus">
          <p class="Successful_receive">关注【游研视界】在后台回复<span class="Successful_class">领取课程</span><br />即刻领取价值<span>399</span>元<span>6</span>节编程课<br />快来和YOYO一起体验编程的乐趣吧！</p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import Qs from "qs";
  export default {
    data() {
      return {
        poPup: false,
        successPop: false
      }
    },
    methods: {
      PopupShow(){
        var phone = document.getElementById('phone').value;
        if ((/^1[34578]\d{9}$/.test(phone))) {
          document.getElementById('errorformat').innerHTML = '';
          this.poPup = true
        } else {
          document.getElementById('errorformat').innerHTML = '格式错误'
          return false
        }
      },
      popupShow(){
        var phone = document.getElementById('bottom_tele').value;
        if ((/^1[34578]\d{9}$/.test(phone))) {
          document.getElementById('bottom_error').innerHTML = '';
          this.poPup = true
        } else {
          document.getElementById('bottom_error').innerHTML = '格式错误'
          return false
        }
      },
      closePop(){
        this.poPup = false
      },
      closeSPop(){
        this.successPop = false
      },
      handleClick(){
        axios({
          method: 'post',
          url: 'https://yoback.xzs51.com/api/sms/send',
          dataType: "json",
          data: Qs.stringify({
            mobile: document.getElementById('popup_tele').value,
            event: 'changemobile'
          })
        }).then(function (res) {
          if (res.data.code == 1) {
            alert(res.data.msg)
            document.getElementById('popup_span').innerHTML = "已成功发送";
          } else {
            alert(res.data.msg);
            input.buttom.disabled = false;
          }
          return false;
        })
      },
      successPopShow(){
        this.successPop = true
        this.poPup = false
      }
    },
  }
</script>

<style lang="scss" scoped>
.processc{
  background: #fff;
}
.childResult {
  font-size:35px;
  font-family:PingFang SC;
  font-weight:bold;
  line-height:40.98px;
  color:rgba(51,51,51,1);
  text-align: center;
  padding-top: 100px;
  opacity:1;
}
.howtosee{
  margin-bottom: 100px;
}

.handsup{
  background:rgba(255,251,232,1);
  opacity:1;
  padding-bottom: 100px;
}

.preparationc{
  padding-bottom: 100px;
}

.HowToSee ,.HandsUp ,.Preparationc{
  overflow: hidden;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content:center;
  -ms-flex-pack: center;
  margin-top: 60px;
}
.bannerimg{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;

    img{
      height: 100%;
    }
}

.banner_free{
  position: absolute;
  top: 215px;
  left:70%;
  z-index: 20;
  width: 330px;
  height: 296px;

  .banner_registered{
    // padding: 34px 24px 0;
    height: 100%;
    background: #fff;
    -webkit-box-shadow: 0 8px 20px 0 rgba(0,0,0,0.2);
    box-shadow: 0 8px 20px 0 rgba(0,0,0,0.2);
    border-radius: 30px;
    padding-top: 34px;

    .bannerFree ,.banner_valueOF{
      text-align: center;
      font-size:30px;
      font-family:Nowar Warcraft Rounded CN;
      font-weight:bold;
      line-height:42px;
      color:rgba(51,51,51,1);
      opacity:1;
      letter-spacing: 0;
    }
    .banner_valueOF span{
      letter-spacing: 0;
      text-align: center;
      font-size:40px;
      font-family:PingFang SC;
      font-weight:bold;
      line-height:56px;
      color:rgba(255,203,49,1);
      opacity:1;
    }

    .banner_input {    
      margin:16px 24px 0;
      padding: 19px 43px;
      padding-right: 0;
      width: 238px;
      background:rgba(235,235,235,1);
      opacity:1;
      border-radius: 33px;

      input{
        display: inline-block;
        width: 140px;
        vertical-align: middle;
        font-size:20px;
        font-family:PingFang SC;
        font-weight:500;
        line-height:28px;
        color:rgba(153,153,153,1);
        background: rgba(235,235,235,1);
        opacity:1;
        border: none;
        outline: none;
      }

      #errorformat {    
        color: #ff5c5c;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .banner_button{
      margin-left: 11px;
      cursor: pointer;
    }
  }
}
.bottom_foot {
  width: 100%;
  height:117px;
  background:rgba(241,241,241,1);
  opacity:1;
  padding-top: 60px;
  -webkit-box-shadow: inset 0 1px 0 0 #ececec;
  box-shadow: inset 0 1px 0 0 #ececec;
  position: relative;
  z-index: 15;
  text-align: center;
  margin-bottom: 80px;

  .bottom-message {
      position: relative;
      height:17px;
      font-size:12px;
      font-family:PingFang SC;
      font-weight:500;
      line-height:17px;
      color:rgba(119,119,119,1);
      opacity:1;
  }

  .bottom-dizhi {
      position: relative;
      font-size:12px;
      font-family:PingFang SC;
      font-weight:500;
      line-height:17px;
      color:rgba(119,119,119,1);
      opacity:1;
      margin-top: 20px;

      span{
        cursor: pointer;
      }
  }
}
.PopupWindow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.2);
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9999;
  -webkit-animation: index__fade-in___oyvx2;
  -o-animation: index__fade-in___oyvx2;
  animation: index__fade-in___oyvx2;
  -webkit-animation-duration: .3s;
  -o-animation-duration: .3s;
  animation-duration: .3s;

  .PopupEX {
    position: relative;
    width: 310px;
    height: 389px;
    padding: 51px 30px 0 260px;
    background: 50%/contain url(../images/home/popup.png) no-repeat;

    .Close_popup {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 26px;
      height: 26px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      position: absolute;
      top: 20px;
      right: 20px;
      border-radius: 50%;
      cursor: pointer;

      img {
        display: inline-block;
        width: 27px;
        height: 27px;
      }
    }

    .popup_free {
      width: 100%;
      position: relative;

      .popup_get_1{
        font-size:30px;
        font-family:PingFang SC;
        font-weight:bold;
        line-height:42px;
        color:rgba(51,51,51,1);
        opacity:1;
      }

      .popup_get_2 {
        font-size:26px;
        font-family:PingFang SC;
        font-weight:bold;
        line-height:37px;
        color:rgba(51,51,51,1);
        opacity:1;
        margin-top: 11px;

        span {
          font-size:35px;
          font-family:PingFang SC;
          font-weight:bold;
          line-height:49px;
          color:rgba(6,98,171,1);
          opacity:1;
        }
      }
    }

    .popup_quick {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: left;
      -webkit-justify-content: left;
      -ms-flex-pack: left;
      justify-content: left;
      width: 340px;
      margin: 0 auto;
      margin-top: 23px;

      .popup_quickly {
        display: inline-block;
        vertical-align: top;

        .popup_input {
          height: 49px;
          width: 310px;
          color: #333;
          background: #fff;
          border-radius: 10px;
          padding: 14px 16px;
          position: relative;
          margin-bottom: 25px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;

          input {
            width: 100%;
            height: 100%;
            padding: 0;
            font-size:16px;
            font-family:PingFang SC;
            font-weight:500;
            line-height:22px;
            color:rgba(153,153,153,1);
            opacity:1;
            border: none;
            outline: none;
          }
        }

        .popup_YZM {
          height: 49px;
          width: 310px;
          color: #333;
          background: #fff;
          border-radius: 10px;
          padding: 14px 16px;
          position: relative;
          margin-bottom: 47px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;

          input {
            width: 100%;
            height: 100%;
            padding: 0;
            font-size:16px;
            font-family:PingFang SC;
            font-weight:500;
            line-height:22px;
            color:rgba(153,153,153,1);
            opacity:1;
            outline: none;
            border: none;
          }

          span {
            display: inline-block;
            position: absolute;
            right: 0;
            top: 12px;
            width: 106px;
            height: 25px;
            padding-left: 16px;
            border-left: 2px solid rgba(204,204,204,1);
            font-size:16px;
            font-family:PingFang SC;
            font-weight:500;
            line-height:22px;
            color:rgba(6,98,171,1);
            opacity:1;
            cursor: pointer;
          }
        }

        .popup_EXP {
          cursor: pointer;
        }
      }
    }
  }
}
.Successful {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.2);
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9999;
  -webkit-animation: index__fade-in___oyvx2;
  -o-animation: index__fade-in___oyvx2;
  animation: index__fade-in___oyvx2;
  -webkit-animation-duration: .3s;
  -o-animation-duration: .3s;
  animation-duration: .3s;

  .Successful_code {
    position: relative;
    width: 503px;
    height: 397px;
    padding: 43px 0 34px 97px;
    background: 50%/contain url(../images/home/popupcode.png) no-repeat;

    .Successful_Close {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 28px;
      height: 28px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      position: absolute;
      top: 36px;
      right: 20px;
      border-radius: 50%;
      cursor: pointer;

      img {
        display: inline-block;
        width: 28px;
        height: 28px;
      }
    }

    .Successful_zero {
      width: 414px;
      position: relative;
      margin-left: 20px;

      p{
        font-size:30px;
        font-family:PingFang SC;
        font-weight:bold;
        line-height:42px;
        color:#fff;
        opacity:1;
        text-shadow: -10px -2px 8px rgb(255, 145, 0) ;

        span {
          font-size:40px;
          font-family:PingFang SC;
          font-weight:bold;
          line-height:56px;
          color:rgba(6,98,171,1);
          opacity:1;
          margin-left: 8px;
          margin-right: 8px;
          text-shadow: none;
        }
      }
    }

    .yoyoQrcode {
      width: 188px;
      height: 188px;
      margin:18px 0 15px 109px;

      img {
        height: 100%;
      }
    }

    .Successful_focus {
      width: 100%;
      margin-left: 31px;
      padding-bottom: 34px;

      .Successful_receive {
        font-size:20px;
        font-family:PingFang SC;
        font-weight:bold;
        line-height:36px;
        color:#fff;
        opacity:1;

        span {
          font-family:PingFang SC;
          font-weight:bold;
          color:rgba(6,98,171,1);
          opacity:1;
        }

        .Successful_class {
          font-size:25px;
          line-height:36px;
        }
      }
    }
  }
}
.bottom_get {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 160px;
  z-index: 24;
  // -webkit-transform: translateY(180px);
  // -o-transform: translateY(180px);
  // transform: translateY(180px);
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out,-o-transform .3s ease-in-out;
  min-width: 1080px;

  .bottomfooter {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-shadow: 0 2px 16px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 16px 0 rgba(0,0,0,.12);
    background:rgba(38,38,38,0.6);
    opacity:1;
    
    .bottom_free {
      position: absolute;
      bottom: 0;
      padding-left: 307px;
      -webkit-flex-basis: 1200px;
      -ms-flex-preferred-size: 1200px;
      flex-basis: 1200px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 100px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-transition: padding .3s ease-out;
      -o-transition: padding .3s ease-out;
      transition: padding .3s ease-out;
  
      .bottom_img {
        position: absolute;
        bottom: 0;
        left: 0;
        background: url('../images/home/twochilds.png') no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        width: 285px;
        height: 180px;
        opacity: 1;
        // -webkit-transition: all .3s ease-out;
        // -o-transition: all .3s ease-out;
        // transition: all .3s ease-out;
      }
  
      .bottom_valueOF {
        font-size:30px;
        font-family:PingFang SC;
        font-weight:bold;
        line-height:100px;
        color:rgba(255,255,255,1);
        opacity:1;
        margin-right: 20px;
  
        span {
          font-size:40px;
          font-family:PingFang SC;
          font-weight:800;
          line-height:56px;
          color:rgba(255,203,49,1);
          opacity:1;
          margin: 0 8px;
        }
      }
  
      .bottom_input {
        position: relative;
        -webkit-flex-basis: 569px;
        -ms-flex-preferred-size: 569px;
        flex-basis: 569px;
        overflow: hidden;
  
        .bottom_number {
          margin-right: 5px;
          background: rgba(255,255,255,1);
          border-radius: 10px;
          width: 282px;
          height: 60px;
          padding-left: 20px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          font-size: 16px;
          line-height: 42px;
          color: rgba(153,153,153,1);
          border: none;
          outline: none;
          float: left;
        }
  
        #bottom_error {
          position: absolute;
          right: 300px;
          top: 10px;
          line-height: 42px;
          margin-left: 10px;
          color: #ff5c5c;
          font-size: 16px;
        }

        .bottom_button {
          float: left;
          background: rgba(255,203,49,1);
          border-radius: 10px;
          color: #fff;
          width: 282px;
          height: 60px;
          line-height: 60px;
          font-size: 30px;
          cursor: pointer;
          -webkit-transition: all .2s;
          -o-transition: all .2s;
          transition: all .2s;
          border: none;
          outline: none;
        }

        .bottom_button:hover {
          background:rgba(255,191,0,1);
        }
      }
    }
  }
}

@media screen and (max-width:1313px){

  .bottom_img {
    display: none;
  }
  .bottom_valueOF{
    margin-left: -285px;
  }
}
</style>