<template>
  <div class="hometitle">
    <div class="banner">
      <div class="bannerimg">
        <img src="../images/home/banner.png" alt="" draggable="false" />
      </div>
      <div class="banner_free">
        <div class="banner_registered">
          <p class="bannerFree">19.9元领取</p>
          <p class="banner_valueOF">价值<span>399</span>元试听课</p>
          <div class="banner_input">
            <input
              id="phone"
              type="text"
              maxLength="11"
              placeholder="请输入手机号"
            />
            <span id="errorformat"></span>
          </div>
          <div class="banner_button" @click="PopupShow">
            <img src="../images/home/questget.png" alt="" draggable="false" />
          </div>
        </div>
      </div>
    </div>
    <div class="results">
      <p class="childResult">学编程，能带给孩子什么改变?</p>
      <p class="eleYOYO">
        从兴趣出发启蒙思维  在游戏中发掘创造力  探索学科融合的奥秘
      </p>
      <div class="home_children">
        <div id="Home_TuWen_1" class="home_TuWen" :style="overL == 1 ? 'transform:scale(1.05);transition:.1s' : 'transition:.1s'" @mouseover="overL = 1" @mouseleave="overL = ''">
          <img
            src="../images/home/learning_ability.png"
            alt=""
            draggable="false"
          />
          <p class="home_Wenzi">用编程激发学习力</p>
          <p class="home_XWenzi">紧扣小初高课程设计<br />兴趣启迪数理思维</p>
        </div>
        <div id="Home_TuWen_2" class="home_TuWen" :style="overL == 2 ? 'transform:scale(1.05);transition:.1s' : 'transition:.1s'" @mouseover="overL = 2" @mouseleave="overL = ''">
          <img
            src="../images/home/mining_talent.png"
            alt=""
            draggable="false"
          />
          <p class="home_Wenzi">用编程挖掘天赋</p>
          <p class="home_XWenzi">利用编程的开放性思维<br />开发孩子更多潜能</p>
        </div>
        <div id="Home_TuWen_4" class="home_TuWen" :style="overL == 3 ? 'transform:scale(1.05);transition:.1s' : 'transition:.1s'" @mouseover="overL = 3" @mouseleave="overL = ''">
          <img src="../images/home/knowfuture.png" alt="" draggable="false" />
          <p class="home_Wenzi">用编程了解未来</p>
          <p class="home_XWenzi">
            英语是现在的通用语言<br />编程是未来的通用语言
          </p>
        </div>
      </div>
    </div>
    <div class="fuse">
      <p class="childResult">多学科融合 让孩子学到的远超过编程</p>
      <p class="eleYOYO">YO小程希望每一个孩子都能成为未来的创造者</p>
      <div class="home_fuse">
        <div class="home_video">
          <video
            id="home_myVideo"
            src="http://yoback.xzs51.com/uploads/20200904/d5b720d4c0797daef2f77de866ac0515.mp4"
            type="video/mp4"
            controls
            autoplay
            loop
            controlsList="nodownload"
            onContextMenu="return false"
          ></video>
        </div>
        <div class="home_introduce">
          <h4>与小象Yoyo一起<br />在编程的世界成为最牛的编程师</h4>
          <p>
            深入海底两万里与鲨鱼一起保护亚特兰蒂斯最后的太阳宫；<br />化身星际小队的队长，带领星际小队保卫人类与地球；<br />带领小伙伴前往地心挑战世界，与霸王龙一起穿梭于雨林之中；<br />还可以做编程的创世神，制造火山、洪水、科技来改变世界。
          </p>
        </div>
      </div>
      <div class="home_subject">
        <div class="home_Sub">
          <div>
            <img src="../images/home/math.png" alt="" draggable="false" />
          </div>
          <p>数学</p>
        </div>
        <div class="home_Sub">
          <div>
            <img src="../images/home/physics.png" alt="" draggable="false" />
          </div>
          <p>物理</p>
        </div>
        <div class="home_Sub">
          <div>
            <img src="../images/home/arts.png" alt="" draggable="false" />
          </div>
          <p>艺术</p>
        </div>
        <div class="home_Sub">
          <div>
            <img src="../images/home/geography.png" alt="" draggable="false" />
          </div>
          <p>地理</p>
        </div>
        <div class="home_Sub">
          <div>
            <img src="../images/home/astronomy.png" alt="" draggable="false" />
          </div>
          <p>天文</p>
        </div>
        <div>
          <div>
            <img src="../images/home/history.png" alt="" draggable="false" />
          </div>
          <p>历史</p>
        </div>
      </div>
    </div>
    <div class="home_advanced">
      <p class="childResult">CSM进阶思维教学目标</p>
      <p class="home_eleYOYO">
        同步国际公认 K12 阶段 《计算机科学教师协会标准》（CSTA）引导孩子发散思维
        遵循《麻省理工创造性学习螺旋》
      </p>
      <p class="home_eleyoyo">
        注重孩子实际应用和个性化需求 结合教育部颁布《信息技术课程标准》
      </p>
      <div class="home_objective">
        <div class="home_step">
          <img
            src="../images/home/home_objectives.png"
            alt=""
            draggable="false"
          />
          <div
            class="home_step_1"
            @mouseenter="StepFirst"
            @mouseleave="StepOne"
          >
            <div class="home_step_one">
              <h3>1</h3>
              <p>解构能力</p>
            </div>
          </div>
          <div
            class="home_step_2"
            @mouseenter="StepSecond"
            @mouseleave="StepTwo"
          >
            <div class="home_step_two">
              <h3>2</h3>
              <p>算法能力</p>
            </div>
          </div>
          <div
            class="home_step_3"
            @mouseenter="StepThird"
            @mouseleave="StepThree"
          >
            <div class="home_step_three">
              <h3>3</h3>
              <p>沟通协作</p>
            </div>
          </div>
          <div
            class="home_step_4"
            @mouseenter="StepFourth"
            @mouseleave="StepFour"
          >
            <div class="home_step_four">
              <h3>4</h3>
              <p>创意能力</p>
            </div>
          </div>
          <div
            class="home_step_5"
            @mouseenter="StepFifth"
            @mouseleave="StepFive"
          >
            <div class="home_step_five">
              <h3>5</h3>
              <p>抽象能力</p>
            </div>
          </div>
          <div
            class="home_step_6"
            @mouseenter="StepSixth"
            @mouseleave="StepSix"
          >
            <div class="home_step_six">
              <h3>6</h3>
              <p>模式提取</p>
            </div>
          </div>
          <div
            id="home_explanation_one"
            class="home_explanation_1"
            v-if="OneShow"
          >
            <div class="home_explanation_jian"></div>
            <p>
              学会有效、有序地解构复杂的问题，将大问题拆解成小问题，最终逆向建构结构类知识和过程类知识
            </p>
          </div>
          <div
            id="home_explanation_two"
            class="home_explanation_2"
            v-if="TwoShow"
          >
            <div class="home_explanation_jian"></div>
            <p>
              算法能力是编程和数学课程学习能力的综合表现，掌握了它就掌握了打开数理思维的启蒙大门，它由阅读算法能力、提炼算理能力、算法语言能力、应用算法能力四部分构成
            </p>
          </div>
          <div
            id="home_explanation_three"
            class="home_explanation_3"
            v-if="ThreeShow"
          >
            <div class="home_explanation_jian"></div>
            <p>
              和其他小工程师共同完成大型编程作品，发挥每个成员不同位置上的最大作用，让团队优势翻倍增长
            </p>
          </div>
          <div
            id="home_explanation_four"
            class="home_explanation_4"
            v-if="FourShow"
          >
            <div class="home_explanation_jian"></div>
            <p>
              玩出未来创意能力，经过充分系统的比较和可靠预测，灵活调整程序形态，从你的键盘下诞生更多创意作品
            </p>
          </div>
          <div
            id="home_explanation_five"
            class="home_explanation_5"
            v-if="FiveShow"
          >
            <div class="home_explanation_jian"></div>
            <p>
              把具体变成概念，进而驾驭概念进行思考，从复杂的逻辑步骤中抓住事物的本质，让程序变得简单和有趣
            </p>
          </div>
          <div
            id="home_explanation_six"
            class="home_explanation_6"
            v-if="SixShow"
          >
            <div class="home_explanation_jian"></div>
            <p>
              解决抽象形式的分类问题，使用机器的思维方式解决计算机的问题，踏入人工智能的第一步
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="home_class_system">
      <p class="childResult">ESPM+多元思维课程体系</p>
      <p class="eleYOYO">思维启蒙-熟练应用-逻辑强化-创意创造-视野开拓</p>
      <div class="home_series">
        <div
          v-for="(item, index) in navList"
          :key="index"
          :class="
            menuIndex == index ? 'home_series_1 home_series_2' : 'home_series_2'
          "
          @click="menuShow(index)"
        >
          <p>{{ item }}</p>
        </div>
      </div>
      <div class="home_series_one" v-show="menuIndex == 0">
        <div class="home_series_jian"></div>
        <div class="home_series_first">
          <div class="home_series_img_1">
            <img src="../images/home/Eseries.png" alt="" draggable="false" />
          </div>
          <div class="home_series_img_2">
            <h3>Scratch 图形化编程</h3>
            <img
              class="home_series_img_3"
              src="../images/home/righttop.png"
              alt=""
              draggable="false"
            />
            <p class="home_series_p_1">
              思维启蒙 潜力激发<br />像玩游戏一样学编程<br />快乐完成编程基础知识搭建，寓教于乐中动手完成简单程序的制作
            </p>
            <div class="home_series_img_4"><p>适合 6-11岁</p></div>
            <div class="home_series_img_5">
              <img src="../images/home/Stars.png" alt="" draggable="false" />
              <span>课程知识点：天文、地理、数学、物理、艺术、英语</span>
            </div>
            <div class="home_series_img_6">
              <img src="../images/home/Stars.png" alt="" draggable="false" />
              <span
                >跨学科知识点：紧贴小学阶段K12课程，融合1200个小学阶段
                <p>课程知识点</p></span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="home_series_two" v-show="menuIndex == 1">
        <div class="home_series_jian"></div>
        <div class="home_series_first">
          <div class="home_series_img_1">
            <img src="../images/home/Sseries.png" alt="" draggable="false" />
          </div>
          <div class="home_series_img_2">
            <h3>Scratch 高级图形化编程</h3>
            <img
              class="home_series_img_3"
              src="../images/home/righttop.png"
              alt=""
              draggable="false"
            />
            <p class="home_series_p_1">
              系统思维 熟练应用<br />逐步实现与现实应用的无缝对接<br />算法和数据结构的学习，最后综合应用所学知识
            </p>
            <div class="home_series_img_4"><p>适合 8岁以上</p></div>
            <div class="home_series_img_5">
              <img src="../images/home/Stars.png" alt="" draggable="false" />
              <span>课程知识点：天文、地理、数学、物理、艺术、英语</span>
            </div>
            <div class="home_series_img_6">
              <img src="../images/home/Stars.png" alt="" draggable="false" />
              <span>制造项目：贪吃蛇、超级玛丽、愤怒的老鼠等综合游戏设计</span>
            </div>
          </div>
        </div>
      </div>
      <div class="home_series_three" v-show="menuIndex == 2">
        <div class="home_series_jian"></div>
        <div class="home_series_first">
          <div class="home_series_img_1">
            <img src="../images/home/Pseries.png" alt="" draggable="false" />
          </div>
          <div class="home_series_img_2">
            <h3>Python 代码编程课</h3>
            <img
              class="home_series_img_3"
              src="../images/home/righttop.png"
              alt=""
              draggable="false"
            />
            <p class="home_series_p_1">
              逻辑强化 融会贯通<br />北京、浙江、山东已将编程列入中高考科目<br />认证赛事通用编程语言，孩子学习就是在比赛
            </p>
            <div class="home_series_img_4"><p>适合 9岁以上</p></div>
            <div class="home_series_img_5">
              <img src="../images/home/Stars.png" alt="" draggable="false" />
              <span
                >课程知识点：循环、判断等常用语法，数据结构、函数等知识</span
              >
            </div>
            <div class="home_series_img_6">
              <img src="../images/home/Stars.png" alt="" draggable="false" />
              <span>制造项目：AI 机器人、开发语音管家、人脸识别、智能导航</span>
            </div>
          </div>
        </div>
      </div>
      <div class="home_series_four" v-show="menuIndex == 3">
        <div class="home_series_jian"></div>
        <div class="home_series_first">
          <div class="home_series_img_1">
            <img src="../images/home/Mseries.png" alt="" draggable="false" />
          </div>
          <div class="home_series_img_2">
            <h3>Micro: Bit 迈酷硬件课</h3>
            <img
              class="home_series_img_3"
              src="../images/home/righttop.png"
              alt=""
              draggable="false"
            />
            <p class="home_series_p_1">
              创意创造 触摸未来<br />着眼科创能力的提升与拓展编程外延，将学到的编程知识，系统<br />应用到
              micro:bit硬件硬件组装+前沿科技模拟+学科知识融入
            </p>
            <div class="home_series_img_4"><p>适合 10岁以上</p></div>
            <div class="home_series_img_5">
              <img src="../images/home/Stars.png" alt="" draggable="false" />
              <span
                >课程知识点：物联网知识接触、人工智能、航天航空、物理机
                <p>械、生物化学</p></span
              >
            </div>
            <div class="home_series_img_6">
              <img src="../images/home/Stars.png" alt="" draggable="false" />
              <span>制造项目：智能家居、智慧城市系统</span>
            </div>
          </div>
        </div>
      </div>
      <div class="home_series_five" v-show="menuIndex == 4">
        <div class="home_series_jian"></div>
        <div class="home_series_first">
          <div class="home_series_img_1">
            <img src="../images/home/Useries.png" alt="" draggable="false" />
          </div>
          <div class="home_series_img_2">
            <h3>U系列-Unique</h3>
            <div class="home_series_img_4"><p>面向国际 视野开拓</p></div>
            <img
              class="home_series_img_3"
              src="../images/home/righttop.png"
              alt=""
              draggable="false"
            />
            <p class="home_series_p_1">
              U 系列课程，发现每个孩子独一无二的闪光点<br />U系列，YO小程特别系列课程，接轨国际通用PBL项目制课程模<br />式，每阶段课程会从一个实际问题出发，
              编程+兴趣，针对孩子不<br />同阶段的年龄、特长、爱好进行设计。
            </p>
            <div class="home_series_img_5">
              <img src="../images/home/Stars.png" alt="" draggable="false" />
              <span>名师1v1国际赛事营</span>
            </div>
            <div class="home_series_img_6">
              <img src="../images/home/Stars.png" alt="" draggable="false" />
              <span>“创”艺术编程营</span>
            </div>
            <div class="home_series_img_6">
              <img src="../images/home/Stars.png" alt="" draggable="false" />
              <span>新奇“造物”编程营</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="teacherteam">
      <p class="childResult">教师团队</p>
      <p class="eleYOYO">大咖级名师团队坐镇教学 联合北大、北师大名师研发课程</p>
      <div class="TeacherT">
        <el-carousel
          style="width: 1200px; height: 607px"
          :interval="3000"
          arrow="never"
        >
          <el-carousel-item
            style="width: 1200px; height: 507px"
            v-for="(item, index) in ImgList"
            :key="index"
          >
            <img
              style="width: 1000px; height: 507px; margin: auto; display: block"
              :src="item.url"
              alt=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="home_teachmode">
      <p class="childResult">教学模式</p>
      <p class="eleYOYO">YO小程多场景课堂</p>
      <div class="teachWay">
        <div class="way">
          <img src="../images/home/live.png" alt="" draggable="false" />
          <h4>学霸直播课堂</h4>
          <p>面对面指导教学，再也不用担心辅导孩子作业</p>
        </div>
        <div class="way home_way">
          <img src="../images/home/smart.png" alt="" draggable="false" />
          <h4>AI动画录播课堂</h4>
          <p>课程可反复观看，可根据需求随时随地听课</p>
        </div>
        <div class="way">
          <img src="../images/home/interaction.png" alt="" draggable="false" />
          <h4>社群互动课堂</h4>
          <p>开学典礼、编程知识讲座毕业颁奖典礼都将在这里举行</p>
        </div>
      </div>
    </div>
    <div class="home_five">
      <p class="childResult">FIVE教学法</p>
      <p class="eleYOYO">“学、练、测、赛、创”</p>
      <div class="home_FiveTeach">
        <img src="../images/home/fiveteach.png" alt="" draggable="false" />
      </div>
    </div>
    <div class="aboutyoyo">
      <p class="childResult">关于YO小程</p>
      <p class="eleYOYO">为青少年打造轻松愉悦的在线学习空间</p>
      <div class="AboutYOYO">
        <div class="BoyAGirl">
          <img src="../images/home/boyandgirl.png" alt="" draggable="false" />
        </div>
        <div class="boyANDgirl">
          <p class="home_boy">
            YO小程，是一款充满乐趣与欢乐的在线少儿编程课程，这里集结了全世界近100万的孩子在线共同学习，北大、北师大名师独家研发的优质编程课程等你来体验全新矩阵式课程体系，开放自由的学习环境、超乎想象的课堂全场景打造，加载丰富的动画特效，在乐趣中吸收多种学科知识、提升编程思维，为青少年打造轻松愉快的在线学习空间。
          </p>
          <p class="home_girl">
            快拉上小伙伴一同加入YOYO冒险大家庭，开启编程世界的大门，创造属于你们的未来世界！
          </p>
        </div>
      </div>
    </div>
    <div class="home_number">
      <div class="home_chance">
        <h3>21<span>个</span></h3>
        <p>21个省份孩子共同选择</p>
      </div>
      <div class="home_chance home_chance_1">
        <h3>10<span>万</span></h3>
        <p>10万+高知家庭父母选择</p>
      </div>
      <div class="home_chance">
        <h3>100<span>家</span></h3>
        <p>100+知名中小学选择</p>
      </div>
    </div>
    <div class="home_problem">
      <p class="childResult">常见问题</p>
      <div class="home_problem_1" @mouseenter="PFirst" @mouseleave="Pone">
        <p>1. 上课支持工具？</p>
        <div class="home_problem_img">
          <img
            id="Home_problem_1"
            src="../images/home/down.png"
            alt=""
            draggable="false"
          />
        </div>
      </div>
      <div id="Home_answer_1" class="home_answer_1" v-if="PoneShow">
        <div class="home_answer_jian"></div>
        <p>
          学生课：支持Win7级以上系统、苹果系统上课<br />家长课：支持手机上课
        </p>
      </div>
      <div class="home_problem_2" @mouseenter="PSecond" @mouseleave="Ptwo">
        <p>2. 孩子学校学过一点，从入门级别学习合适吗？</p>
        <div class="home_problem_img">
          <img
            id="Home_problem_2"
            src="../images/home/down.png"
            alt=""
            draggable="false"
          />
        </div>
      </div>
      <div id="Home_answer_2" class="home_answer_2" v-if="PtwoShow">
        <div class="home_answer_jian"></div>
        <p>
          绝大多数在学校学Scratch课程的内容都比较基础，相当于我们前三课的水平，建议比较系统的去学习我们的课程。
        </p>
      </div>
      <div class="home_problem_3" @mouseenter="PThird" @mouseleave="Pthree">
        <p>3. 家长需要参与课程吗？</p>
        <div class="home_problem_img">
          <img
            id="Home_problem_3"
            src="../images/home/down.png"
            alt=""
            draggable="false"
          />
        </div>
      </div>
      <div id="Home_answer_3" class="home_answer_3" v-if="PthreeShow">
        <div class="home_answer_jian"></div>
        <p>
          大部分时间，家长不用参与到课程中。前1-2次课程，家长需要配合做一些电脑上的操作和设置。孩子完成作业后，家长可以在公众号上收到老师的点评。
        </p>
      </div>
    </div>
    <div class="home_add">
      <div class="home_addTeacher">
        <div class="home_add_WeiXin">
          <h3>添加“卡卡老师“微信，获得价值<span>399</span>元编程课</h3>
          <p>
            7*24小时在线贴心服务的卡卡老师，会随时解答您的疑问，而且他<br />还会不定时发送优惠活动哦！
          </p>
        </div>
        <div class="home_add_code">
          <img src="../images/home/erweima.png" alt="" draggable="false" />
        </div>
      </div>
    </div>
    <div>
      <div class="bottom_foot">
        <p class="bottom-message">
          地址: 河南省郑州市金水区文化路与红旗路交叉口西300米广厦城市之巅9号楼
        </p>
        <p class="bottom-dizhi">
          COPYRIGHT2020 行知塾教育集团 <span>豫ICP备19005091号-1</span>
        </p>
      </div>
      <div class="bottom_get">
        <div class="bottomfooter">
          <div class="bottom_free">
            <div class="bottom_img">
              <img src="../images/home/twochilds.png" alt="" />
            </div>
            <p class="bottom_valueOF">19.9元领取价值<span>399元</span>体验课</p>
            <div class="bottom_input">
              <input
                id="bottom_tele"
                class="bottom_number"
                type="text"
                maxLength="11"
                placeholder="请输入手机号"
              />
              <span id="bottom_error"></span>
              <button
                id="bottom_Buttom"
                class="bottom_button"
                @click="popupShow"
              >
                立即领取
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="Popupwindow" class="PopupWindow" v-if="poPup">
        <div class="PopupEX">
          <span class="Close_popup">
            <img
              src="../images/home/closePopup.png"
              alt=""
              draggable="false"
              @click="closePop"
            />
          </span>
          <div class="popup_free">
            <p class="popup_get_1">19.9元领取</p>
            <p class="popup_get_2">价值<span>399元</span>编程体验课</p>
          </div>
          <div class="popup_quick">
            <div class="popup_quickly">
              <div class="popup_input">
                <input
                  id="popup_tele"
                  type="text"
                  placeholder="请输入手机号"
                  maxLength="11"
                  name="mobile"
                />
              </div>
              <div class="popup_YZM">
                <input
                  id="popup_event"
                  type="text"
                  placeholder="短信验证码"
                  maxLength="4"
                />
                <span id="popup_span" @click="handleClick">获取验证码</span>
              </div>
              <div class="popup_EXP" @click="successPopShow">
                <img
                  src="../images/home/experience.gif"
                  alt=""
                  draggable="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Successful_CLASS" class="Successful" v-if="successPop">
        <div class="Successful_code">
          <span class="Successful_Close" @click="closeSPop">
            <img src="../images/home/closePopup.png" alt="" draggable="false" />
          </span>
          <div class="Successful_zero">
            <p class="Successful_look">看这里<span>0</span>元体验6节编程课</p>
          </div>
          <div class="yoyoQrcode">
            <img src="../images/home/yoyoqrcode.png" alt="" draggable="false" />
          </div>
          <div class="Successful_focus">
            <p class="Successful_receive">
              关注【游研视界】在后台回复<span class="Successful_class"
                >领取课程</span
              ><br />即刻领取价值<span>399</span>元<span>6</span>节编程课<br />快来和YOYO一起体验编程的乐趣吧！
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Qs from "qs";
import i1 from "../images/home/1.png";
import i2 from "../images/home/2.png";
import i3 from "../images/home/3.png";
export default {
  data() {
    return {
      OneShow: false,
      TwoShow: false,
      ThreeShow: false,
      FourShow: false,
      FiveShow: false,
      SixShow: false,
      PoneShow: false,
      PtwoShow: false,
      PthreeShow: false,
      poPup: false,
      successPop: false,
      ImgList: [
        {
          url: i1,
        },
        { url: i3 },
      ],
      menuIndex:0,
      navList:['E系列-Enlighten','S系列-Systemetic','P系列-Proficient','M系列-Maker','U系列-Unique'],
      overL:''
    };
  },
  methods: {
    menuShow (index) {
      this.menuIndex = index
    },
    StepFirst() {
      this.OneShow = true;
    },
    StepOne() {
      this.OneShow = false;
    },
    StepSecond() {
      this.TwoShow = true;
    },
    StepTwo() {
      this.TwoShow = false;
    },
    StepThird() {
      this.ThreeShow = true;
    },
    StepThree() {
      this.ThreeShow = false;
    },
    StepFourth() {
      this.FourShow = true;
    },
    StepFour() {
      this.FourShow = false;
    },
    StepFifth() {
      this.FiveShow = true;
    },
    StepFive() {
      this.FiveShow = false;
    },
    StepSixth() {
      this.SixShow = true;
    },
    StepSix() {
      this.SixShow = false;
    },
    PFirst() {
      this.PoneShow = true;
    },
    Pone() {
      this.PoneShow = false;
    },
    PSecond() {
      this.PtwoShow = true;
    },
    Ptwo() {
      this.PtwoShow = false;
    },
    PThird() {
      this.PthreeShow = true;
    },
    Pthree() {
      this.PthreeShow = false;
    },
    PopupShow() {
      var phone = document.getElementById("phone").value;
      if (/^1[34578]\d{9}$/.test(phone)) {
        document.getElementById("errorformat").innerHTML = "";
        this.poPup = true;
      } else {
        document.getElementById("errorformat").innerHTML = "格式错误";
        return false;
      }
    },
    popupShow() {
      var phone = document.getElementById("bottom_tele").value;
      if (/^1[34578]\d{9}$/.test(phone)) {
        document.getElementById("bottom_error").innerHTML = "";
        this.poPup = true;
      } else {
        document.getElementById("bottom_error").innerHTML = "格式错误";
        return false;
      }
    },
    closePop() {
      this.poPup = false;
    },
    closeSPop() {
      this.successPop = false;
    },
    handleClick() {
      axios({
        method: "post",
        url: "https://yoback.xzs51.com/api/sms/send",
        dataType: "json",
        data: Qs.stringify({
          mobile: document.getElementById("popup_tele").value,
          event: "changemobile",
        }),
      }).then(function (res) {
        if (res.data.code == 1) {
          alert(res.data.msg);
          document.getElementById("popup_span").innerHTML = "已成功发送";
        } else {
          alert(res.data.msg);
          input.buttom.disabled = false;
        }
        return false;
      });
    },
    successPopShow() {
      this.successPop = true;
      this.poPup = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.hometitle {
  width: 100%;
  background: #fff;
}

.childResult {
  font-size: 35px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 40.98px;
  color: rgba(51, 51, 51, 1);
  text-align: center;
  padding-top: 100px;
  opacity: 1;
}
.eleYOYO {
  width: 100%;
  height: 22px;
  text-align: center;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 22px;
  color: rgba(119, 119, 119, 1);
  opacity: 1;
  margin: 16px auto 0;
}

.results {
  margin-bottom: 100px;

  .home_children {
    padding-top: 60px;
    height: 440px;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;

    .home_TuWen {
      width: 380px;
      height: 420px;
      margin-right: 20px;
      box-shadow: 0px 3px 6px rgba(204, 204, 204, 0.5);
      border-radius: 20px;
      cursor: pointer;

      img {
        width: 100%;
        border-radius: 20px 20px 0 00;
      }

      .home_Wenzi,
      .home_XWenzi {
        margin-left: 20px;
        margin-right: 20px;
        text-align: center;
      }

      .home_Wenzi {
        font-size: 30px;
        font-family: Nowar Warcraft Rounded CN;
        font-weight: bold;
        line-height: 41px;
        color: #feae35;
        margin-top: 20px;
        opacity: 1;
      }

      .home_XWenzi {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 30px;
        color: #333333;
        margin-top: 15px;
        opacity: 1;
      }
    }
  }
}

.fuse {
  background: #fffbe8;

  .home_fuse {
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    margin-top: 60px;

    .home_video {
      width: 637px;
      height: 358.3125px;
      border-radius: 20px;
      border: 1px solid #707070;
      outline: none;
      margin-right: 40px;

      video {
        width: 637px;
        height: 358.3125px;
        border-radius: 20px;
        outline: none;
      }
    }

    .home_introduce {
      h4 {
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 44px;
        color: #f59f1a;
        opacity: 1;
        margin-top: 35px;
      }

      p {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 40px;
        color: #777777;
        opacity: 1;
        margin-top: 50px;
      }
    }
  }

  .home_subject {
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    padding-bottom: 100px;
    margin-top: 60px;

    .home_Sub {
      margin-right: 96px;
    }

    p {
      text-align: center;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 40px;
      color: #1d1a1a;
      opacity: 1;
      margin-top: 10px;
    }
  }
}

.home_advanced {
  .home_eleYOYO,
  .home_eleyoyo {
    width: 100%;
    height: 22px;
    text-align: center;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 22px;
    color: rgba(119, 119, 119, 1);
    opacity: 1;
  }

  .home_eleYOYO {
    margin: 16px auto 0;
  }

  .home_eleyoyo {
    margin: 10px auto 0;
    margin-bottom: 132px;
  }

  .home_objective {
    // overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;

    .home_step {
      position: relative;

      img {
        margin-right: 96px;
      }

      .home_step_1 {
        position: absolute;
        left: 40px;
        top: 16px;
        width: 145px;
        height: 145px;
        background: #fff9e7;
        border-radius: 50%;
        opacity: 1;
        padding: 23px 0 0 23px;
        cursor: pointer;

        .home_step_one {
          width: 122px;
          height: 122px;
          background: #fec93a;
          border-radius: 50%;
          opacity: 1;

          h3 {
            font-size: 45px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 41px;
            color: #ffffff;
            opacity: 1;
            text-align: center;
            padding-top: 20px;
          }

          p {
            font-size: 20px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 34px;
            color: #ffffff;
            opacity: 1;
            text-align: center;
          }
        }
      }

      .home_step_2 {
        position: absolute;
        left: 706px;
        top: -72px;
        width: 127px;
        height: 127px;
        background: #fff6dc;
        border-radius: 50%;
        opacity: 1;
        padding: 20px 0 0 20px;
        cursor: pointer;

        .home_step_two {
          width: 107px;
          height: 107px;
          background: #ffab38;
          border-radius: 50%;
          opacity: 1;

          h3 {
            font-size: 40px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 36px;
            color: #ffffff;
            opacity: 1;
            text-align: center;
            padding-top: 15px;
          }

          p {
            font-size: 18px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 30px;
            color: #ffffff;
            opacity: 1;
            text-align: center;
            margin-top: 5px;
          }
        }
      }

      .home_step_3 {
        position: absolute;
        left: 1034px;
        top: 94px;
        width: 141.5px;
        height: 141.5px;
        background: #ffeee2;
        border-radius: 50%;
        opacity: 1;
        padding: 22.5px 0 0 22.5px;
        cursor: pointer;

        .home_step_three {
          width: 120px;
          height: 120px;
          background: #ff953e;
          border-radius: 50%;
          opacity: 1;

          h3 {
            font-size: 45px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 41px;
            color: #ffffff;
            opacity: 1;
            text-align: center;
            padding-top: 20px;
          }

          p {
            font-size: 20px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 33px;
            color: #ffffff;
            opacity: 1;
            text-align: center;
          }
        }
      }

      .home_step_4 {
        position: absolute;
        left: 809px;
        top: 315px;
        width: 127px;
        height: 127px;
        background: #fff1ec;
        border-radius: 50%;
        opacity: 1;
        padding: 20px 0 0 20px;
        cursor: pointer;

        .home_step_four {
          width: 107px;
          height: 107px;
          background: #ff7846;
          border-radius: 50%;
          opacity: 1;

          h3 {
            font-size: 40px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 36px;
            color: #ffffff;
            opacity: 1;
            text-align: center;
            padding-top: 15px;
          }

          p {
            font-size: 18px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 30px;
            color: #ffffff;
            opacity: 1;
            text-align: center;
            margin-top: 5px;
          }
        }
      }

      .home_step_5 {
        position: absolute;
        left: 574px;
        top: 494px;
        width: 147px;
        height: 147px;
        background: #ffefe4;
        border-radius: 50%;
        opacity: 1;
        padding: 23px 0 0 23px;
        cursor: pointer;

        .home_step_five {
          width: 124px;
          height: 124px;
          background: #ff5c4e;
          border-radius: 50%;
          opacity: 1;

          h3 {
            font-size: 45px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 41px;
            color: #ffffff;
            opacity: 1;
            text-align: center;
            padding-top: 20px;
          }

          p {
            font-size: 20px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 33px;
            color: #ffffff;
            opacity: 1;
            text-align: center;
            margin-top: 5px;
          }
        }
      }

      .home_step_6 {
        position: absolute;
        left: 1067px;
        top: 604px;
        width: 127px;
        height: 127px;
        background: #ffe3e6;
        border-radius: 50%;
        opacity: 1;
        padding: 20px 0 0 20px;
        cursor: pointer;

        .home_step_six {
          width: 107px;
          height: 107px;
          background: #ff4355;
          border-radius: 50%;
          opacity: 1;

          h3 {
            font-size: 40px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 36px;
            color: #ffffff;
            opacity: 1;
            text-align: center;
            padding-top: 15px;
          }

          p {
            font-size: 18px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 30px;
            color: #ffffff;
            opacity: 1;
            text-align: center;
            margin-top: 5px;
          }
        }
      }

      .home_explanation_1 {
        position: absolute;
        left: 228px;
        top: 38px;
        width: 374px;
        height: 102px;
        background: #ffffff;
        box-shadow: 0px 10px 20px rgba(226, 226, 226, 0.76);
        border-radius: 20px;
        padding: 39px 52px 31px 52px;
        opacity: 1;

        .home_explanation_jian {
          width: 60px;
          height: 60px;
          box-shadow: -5px 5px 10px rgba(226, 226, 226, 0.5);
          transform: rotate(45deg);
          background: #ffffff;
          position: absolute;
          left: -30px;
          top: 60px;
        }

        p {
          width: 374px;
          height: 102px;
          font-size: 20px;
          font-family: Nowar Warcraft Rounded CN;
          font-weight: 400;
          line-height: 35px;
          color: #333333;
          opacity: 1;
        }
      }

      .home_explanation_2 {
        position: absolute;
        left: 198px;
        top: -90px;
        width: 374px;
        height: 172px;
        background: #ffffff;
        box-shadow: 0px 10px 20px rgba(226, 226, 226, 0.76);
        border-radius: 20px;
        padding: 39px 52px 31px 52px;
        opacity: 1;

        .home_explanation_jian {
          width: 60px;
          height: 60px;
          box-shadow: 5px -5px 10px rgba(226, 226, 226, 0.5);
          transform: rotate(45deg);
          background: #ffffff;
          position: absolute;
          left: 450px;
          top: 90px;
        }

        p {
          width: 374px;
          height: 102px;
          font-size: 20px;
          font-family: Nowar Warcraft Rounded CN;
          font-weight: 400;
          line-height: 35px;
          color: #333333;
          opacity: 1;
        }
      }

      .home_explanation_3 {
        position: absolute;
        left: 528px;
        top: 108px;
        width: 374px;
        height: 102px;
        background: #ffffff;
        box-shadow: 0px 10px 20px rgba(226, 226, 226, 0.76);
        border-radius: 20px;
        padding: 39px 52px 31px 52px;
        opacity: 1;

        .home_explanation_jian {
          width: 60px;
          height: 60px;
          box-shadow: 5px -5px 10px rgba(226, 226, 226, 0.5);
          transform: rotate(45deg);
          background: #ffffff;
          position: absolute;
          left: 450px;
          top: 60px;
        }

        p {
          width: 374px;
          height: 102px;
          font-size: 20px;
          font-family: Nowar Warcraft Rounded CN;
          font-weight: 400;
          line-height: 35px;
          color: #333333;
          opacity: 1;
        }
      }

      .home_explanation_4 {
        position: absolute;
        left: 300px;
        top: 318px;
        width: 374px;
        height: 102px;
        background: #ffffff;
        box-shadow: 0px 10px 20px rgba(226, 226, 226, 0.76);
        border-radius: 20px;
        padding: 39px 52px 31px 52px;
        opacity: 1;

        .home_explanation_jian {
          width: 60px;
          height: 60px;
          box-shadow: 5px -5px 10px rgba(226, 226, 226, 0.5);
          transform: rotate(45deg);
          background: #ffffff;
          position: absolute;
          left: 450px;
          top: 60px;
        }

        p {
          width: 374px;
          height: 102px;
          font-size: 20px;
          font-family: Nowar Warcraft Rounded CN;
          font-weight: 400;
          line-height: 35px;
          color: #333333;
          opacity: 1;
        }
      }

      .home_explanation_5 {
        position: absolute;
        left: 768px;
        top: 508px;
        width: 374px;
        height: 102px;
        background: #ffffff;
        box-shadow: 0px 10px 20px rgba(226, 226, 226, 0.76);
        border-radius: 20px;
        padding: 39px 52px 31px 52px;
        opacity: 1;

        .home_explanation_jian {
          width: 60px;
          height: 60px;
          box-shadow: -5px 5px 10px rgba(226, 226, 226, 0.5);
          transform: rotate(45deg);
          background: #ffffff;
          position: absolute;
          left: -30px;
          top: 60px;
        }

        p {
          width: 374px;
          height: 102px;
          font-size: 20px;
          font-family: Nowar Warcraft Rounded CN;
          font-weight: 400;
          line-height: 35px;
          color: #333333;
          opacity: 1;
        }
      }

      .home_explanation_6 {
        position: absolute;
        left: 558px;
        top: 608px;
        width: 374px;
        height: 102px;
        background: #ffffff;
        box-shadow: 0px 10px 20px rgba(226, 226, 226, 0.76);
        border-radius: 20px;
        padding: 39px 52px 31px 52px;
        opacity: 1;

        .home_explanation_jian {
          width: 60px;
          height: 60px;
          box-shadow: 5px -5px 10px rgba(226, 226, 226, 0.5);
          transform: rotate(45deg);
          background: #ffffff;
          position: absolute;
          left: 450px;
          top: 60px;
        }

        p {
          width: 374px;
          height: 102px;
          font-size: 20px;
          font-family: Nowar Warcraft Rounded CN;
          font-weight: 400;
          line-height: 35px;
          color: #333333;
          opacity: 1;
        }
      }
    }
  }
}

.home_class_system {
  .home_series {
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    margin-top: 60px;

    div {
      width: 216px;
      height: 61px;
      border-radius: 45px;
      opacity: 1;
      cursor: pointer;

      p {
        font-size: 20px;
        font-family: PingFang SC;
        line-height: 60px;
        opacity: 1;
        text-align: center;
      }
    }

    .home_series_1,
    .home_series_2,
    .home_series_3,
    .home_series_4 {
      margin-right: 30px;
    }

    .home_series_1{
      background: #F59F1A !important;

      p{
        font-weight: bold !important;
        color: #FFFFFF !important;
      }
    }

    .home_series_2,
    .home_series_3,
    .home_series_4,
    .home_series_5 {
      background: #eeeeee;

      p {
        font-weight: 500;
        color: #333333;
      }
    }
  }
}

.home_series_one {
  background: #fffbe8;
  padding: 60px 0;
  margin-top: 68px;
  position: relative;

  .home_series_jian {
    width: 83px;
    height: 83px;
    background: #fffbe8;
    opacity: 1;
    transform: rotate(45deg);
    position: absolute;
    left: 410px;
    top: -40px;
  }

  .home_series_first {
    width: 1200px;
    background: #ffffff;
    opacity: 1;
    border-radius: 20px;
    margin: auto;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    padding-top: 60px;
    padding-bottom: 60px;

    .home_series_img_1 {
      margin-right: 60px;
    }

    .home_series_img_2 {
      width: 536px;
      position: relative;

      h3 {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 60px;
        color: #333333;
        opacity: 1;
      }

      .home_series_img_3 {
        position: absolute;
        right: 0px;
        top: -20px;
      }

      .home_series_p_1 {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 28px;
        color: #333333;
        opacity: 1;
      }
    }

    .home_series_img_4 {
      width: 136px;
      background: #f59f1a;
      opacity: 1;
      border-radius: 20px 0px 20px 0px;

      p {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 35px;
        color: #ffffff;
        opacity: 1;
        text-align: center;
        margin-top: 45px;
      }
    }

    .home_series_img_5 {
      margin-top: 53px;
      overflow: hidden;

      img {
        float: left;
      }

      span {
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }

    .home_series_img_6 {
      margin-top: 15px;
      overflow: hidden;

      img {
        float: left;
      }

      span {
        width: 495px;
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;

        p {
          margin-top: 15px;
        }
      }
    }
  }
}

.home_series_two {
  background: #fffbe8;
  padding: 60px 0;
  margin-top: 68px;
  position: relative;

  .home_series_jian {
    width: 83px;
    height: 83px;
    background: #fffbe8;
    opacity: 1;
    transform: rotate(45deg);
    position: absolute;
    left: 655px;
    top: -40px;
  }

  .home_series_first {
    width: 1200px;
    background: #ffffff;
    opacity: 1;
    border-radius: 20px;
    margin: auto;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    padding-top: 60px;
    padding-bottom: 60px;

    .home_series_img_1 {
      margin-right: 60px;
    }

    .home_series_img_2 {
      width: 536px;
      position: relative;

      h3 {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 60px;
        color: #333333;
        opacity: 1;
      }

      .home_series_img_3 {
        position: absolute;
        right: 0px;
        top: -20px;
      }

      .home_series_p_1 {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 28px;
        color: #333333;
        opacity: 1;
      }
    }

    .home_series_img_4 {
      width: 136px;
      background: #f59f1a;
      opacity: 1;
      border-radius: 20px 0px 20px 0px;

      p {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 35px;
        color: #ffffff;
        opacity: 1;
        text-align: center;
        margin-top: 45px;
      }
    }

    .home_series_img_5 {
      margin-top: 53px;
      overflow: hidden;

      img {
        float: left;
      }

      span {
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }

    .home_series_img_6 {
      margin-top: 15px;
      overflow: hidden;

      img {
        float: left;
      }

      span {
        width: 495px;
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }
  }
}

.home_series_three {
  background: #fffbe8;
  padding: 60px 0;
  margin-top: 68px;
  position: relative;

  .home_series_jian {
    width: 83px;
    height: 83px;
    background: #fffbe8;
    opacity: 1;
    transform: rotate(45deg);
    position: absolute;
    left: 900px;
    top: -40px;
  }

  .home_series_first {
    width: 1200px;
    background: #ffffff;
    opacity: 1;
    border-radius: 20px;
    margin: auto;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    padding-top: 60px;
    padding-bottom: 60px;

    .home_series_img_1 {
      margin-right: 60px;
    }

    .home_series_img_2 {
      width: 536px;
      position: relative;

      h3 {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 60px;
        color: #333333;
        opacity: 1;
      }

      .home_series_img_3 {
        position: absolute;
        right: 0px;
        top: -20px;
      }

      .home_series_p_1 {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 28px;
        color: #333333;
        opacity: 1;
      }
    }

    .home_series_img_4 {
      width: 136px;
      background: #f59f1a;
      opacity: 1;
      border-radius: 20px 0px 20px 0px;

      p {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 35px;
        color: #ffffff;
        opacity: 1;
        text-align: center;
        margin-top: 45px;
      }
    }

    .home_series_img_5 {
      margin-top: 53px;
      overflow: hidden;

      img {
        float: left;
      }

      span {
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }

    .home_series_img_6 {
      margin-top: 15px;
      overflow: hidden;

      img {
        float: left;
      }

      span {
        width: 495px;
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }
  }
}

.home_series_four {
  background: #fffbe8;
  padding: 60px 0;
  margin-top: 68px;
  position: relative;

  .home_series_jian {
    width: 83px;
    height: 83px;
    background: #fffbe8;
    opacity: 1;
    transform: rotate(45deg);
    position: absolute;
    left: 1145px;
    top: -40px;
  }

  .home_series_first {
    width: 1200px;
    background: #ffffff;
    opacity: 1;
    border-radius: 20px;
    margin: auto;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    padding-top: 60px;
    padding-bottom: 60px;

    .home_series_img_1 {
      margin-right: 60px;
    }

    .home_series_img_2 {
      width: 536px;
      position: relative;

      h3 {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 60px;
        color: #333333;
        opacity: 1;
      }

      .home_series_img_3 {
        position: absolute;
        right: 0px;
        top: -20px;
      }

      .home_series_p_1 {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 28px;
        color: #333333;
        opacity: 1;
      }
    }

    .home_series_img_4 {
      width: 136px;
      background: #f59f1a;
      opacity: 1;
      border-radius: 20px 0px 20px 0px;

      p {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 35px;
        color: #ffffff;
        opacity: 1;
        text-align: center;
        margin-top: 45px;
      }
    }

    .home_series_img_5 {
      margin-top: 53px;
      overflow: hidden;

      img {
        float: left;
      }

      span {
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;

        p {
          margin-top: 15px;
        }
      }
    }

    .home_series_img_6 {
      margin-top: 15px;
      overflow: hidden;

      img {
        float: left;
      }

      span {
        width: 495px;
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }
  }
}

.home_series_five {
  background: #fffbe8;
  padding: 60px 0;
  margin-top: 68px;
  position: relative;

  .home_series_jian {
    width: 83px;
    height: 83px;
    background: #fffbe8;
    opacity: 1;
    transform: rotate(45deg);
    position: absolute;
    left: 1395px;
    top: -40px;
  }

  .home_series_first {
    width: 1200px;
    background: #ffffff;
    opacity: 1;
    border-radius: 20px;
    margin: auto;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    padding-top: 60px;
    padding-bottom: 60px;

    .home_series_img_1 {
      margin-right: 60px;
    }

    .home_series_img_2 {
      width: 536px;
      position: relative;

      h3 {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 60px;
        color: #333333;
        opacity: 1;
      }

      .home_series_img_3 {
        position: absolute;
        right: 0px;
        top: -20px;
      }

      .home_series_p_1 {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 33px;
        color: #333333;
        opacity: 1;
      }
    }

    .home_series_img_4 {
      width: 186px;
      background: #f59f1a;
      opacity: 1;
      border-radius: 20px 0px 20px 0px;
      margin-bottom: 18px;

      p {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 35px;
        color: #ffffff;
        opacity: 1;
        text-align: center;
      }
    }

    .home_series_img_5 {
      margin-top: 15px;
      overflow: hidden;

      img {
        float: left;
      }

      span {
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }

    .home_series_img_6 {
      margin-top: 15px;
      overflow: hidden;

      img {
        float: left;
      }

      span {
        width: 495px;
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }
  }
}

.teacherteam {
  .TeacherT {
    width: 1218px;
    margin: auto;
  }
}

.home_teachmode {
  background: #fffbe8;

  .teachWay {
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    margin-top: 50px;
    padding-bottom: 100px;

    .way {
      width: 255px;

      img {
        width: 160px;
        height: 160px;
        margin-left: 48px;
      }

      h4 {
        font-size: 24px;
        font-family: Nowar Warcraft Rounded CN;
        font-weight: 500;
        line-height: 33px;
        color: #333333;
        opacity: 1;
        text-align: center;
        margin-top: 16px;
      }

      p {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 30px;
        color: #777777;
        opacity: 1;
        margin-top: 20px;
      }
    }

    .home_way {
      margin: 0 219px;
    }
  }
}

.home_five {
  .home_FiveTeach {
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    margin-top: 60px;
  }
}

.aboutyoyo {
  .AboutYOYO {
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    margin-top: 60px;

    .boyANDgirl {
      width: 576px;
      margin-left: 30px;

      p {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 46px;
        color: #333333;
        opacity: 1;
        text-align: justify;
        text-indent: 30px;
      }

      .home_boy {
        margin-top: 60px;
      }

      .home_girl {
        margin-top: 50px;
      }
    }
  }
}

.home_number {
  background: #f7f9ff;
  padding-top: 127px;
  padding-bottom: 63px;
  margin-top: 100px;
  overflow: hidden;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  .home_chance {
    h3 {
      font-size: 100px;
      font-family: PingFang SC;
      font-weight: 800;
      line-height: 40px;
      color: #2762cc;
      opacity: 1;

      span {
        font-size: 30px;
      }
    }

    p {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 40px;
      color: #333333;
      opacity: 1;
      text-align: center;
      margin-top: 20px;
    }
  }

  .home_chance_1 {
    margin-right: 311px;
    margin-left: 368px;
  }
}

.home_problem {
  .home_problem_1,
  .home_problem_2,
  .home_problem_3 {
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    width: 1200px;
    background: #f7fbff;
    opacity: 1;
    border-radius: 20px;
    margin: auto;
    padding: 34px 0;
    cursor: pointer;

    p {
      font-size: 24px;
      font-family: Nowar Warcraft Rounded CN;
      font-weight: 500;
      line-height: 33px;
      color: #333333;
    }

    .home_problem_img {
      padding-top: 6px;

      img {
        width: 34px;
        height: 20px;
      }
    }
  }

  .home_problem_1 {
    margin-top: 85px;

    p {
      margin-right: 906px;
    }
  }

  .home_problem_2 {
    margin-top: 40px;

    p {
      margin-right: 592px;
    }
  }

  .home_problem_3 {
    margin-top: 40px;

    p {
      margin-right: 836px;
    }
  }

  .home_answer_1,
  .home_answer_2,
  .home_answer_3 {
    width: 1023px;
    margin: 40px auto;
    margin-bottom: 40px;
    background: #eef6ff;
    opacity: 1;
    border-radius: 20px;
    padding: 20px 137px 20px 40px;
    border-bottom: 14px solid #ffe15d;
    position: relative;

    .home_answer_jian {
      position: absolute;
      left: 100px;
      top: -20px;
      width: 40px;
      height: 40px;
      background: #eef6ff;
      transform: rotate(45deg);
    }

    p {
      font-size: 20px;
      font-family: Nowar Warcraft Rounded CN;
      font-weight: 500;
      line-height: 60px;
      color: #333333;
      opacity: 1;
    }
  }
}

.home_add {
  margin-top: 100px;
  background-image: url("../images/home/code_back.png");
  overflow: hidden;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  .home_addTeacher {
    padding-top: 50px;
    padding-bottom: 40px;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;

    .home_add_WeiXin {
      margin-right: 342px;
      margin-top: 50px;

      h3 {
        font-size: 30px;
        font-family: Nowar Warcraft Rounded CN;
        font-weight: bold;
        line-height: 60px;
        color: #333333;
        opacity: 1;

        span {
          font-size: 40px;
          color: #ffcb31;
        }
      }

      p {
        margin-top: 20px;
        font-size: 20px;
        font-family: Nowar Warcraft Rounded CN;
        font-weight: 500;
        line-height: 40px;
        color: #333333;
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 1900px) {
  .home_series_one {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 370px;
      top: -40px;
    }
  }

  .home_series_two {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 620px;
      top: -40px;
    }
  }

  .home_series_three {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 860px;
      top: -40px;
    }
  }

  .home_series_four {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 1110px;
      top: -40px;
    }
  }

  .home_series_five {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 1360px;
      top: -40px;
    }
  }
}
@media screen and (max-width: 1650px) {
  .home_series_one {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 210px;
      top: -40px;
    }
  }

  .home_series_two {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 460px;
      top: -40px;
    }
  }

  .home_series_three {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 710px;
      top: -40px;
    }
  }

  .home_series_four {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 950px;
      top: -40px;
    }
  }

  .home_series_five {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 1200px;
      top: -40px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .home_series_one {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 150px;
      top: -40px;
    }
  }

  .home_series_two {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 400px;
      top: -40px;
    }
  }

  .home_series_three {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 640px;
      top: -40px;
    }
  }

  .home_series_four {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 890px;
      top: -40px;
    }
  }

  .home_series_five {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 1140px;
      top: -40px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .home_series_one {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 90px;
      top: -40px;
    }
  }

  .home_series_two {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 340px;
      top: -40px;
    }
  }

  .home_series_three {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 580px;
      top: -40px;
    }
  }

  .home_series_four {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 830px;
      top: -40px;
    }
  }

  .home_series_five {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 1080px;
      top: -40px;
    }
  }
}
.bottom_foot {
  width: 100%;
  height: 117px;
  background: rgba(241, 241, 241, 1);
  opacity: 1;
  padding-top: 60px;
  -webkit-box-shadow: inset 0 1px 0 0 #ececec;
  box-shadow: inset 0 1px 0 0 #ececec;
  position: relative;
  z-index: 15;
  text-align: center;
  margin-bottom: 80px;

  .bottom-message {
    position: relative;
    height: 17px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 17px;
    color: rgba(119, 119, 119, 1);
    opacity: 1;
  }

  .bottom-dizhi {
    position: relative;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 17px;
    color: rgba(119, 119, 119, 1);
    opacity: 1;
    margin-top: 20px;

    span {
      cursor: pointer;
    }
  }
}

.PopupWindow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9999;
  -webkit-animation: index__fade-in___oyvx2;
  -o-animation: index__fade-in___oyvx2;
  animation: index__fade-in___oyvx2;
  -webkit-animation-duration: 0.3s;
  -o-animation-duration: 0.3s;
  animation-duration: 0.3s;

  .PopupEX {
    position: relative;
    width: 310px;
    height: 389px;
    padding: 51px 30px 0 260px;
    background: 50% / contain url(../images/home/popup.png) no-repeat;

    .Close_popup {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 26px;
      height: 26px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      position: absolute;
      top: 20px;
      right: 20px;
      border-radius: 50%;
      cursor: pointer;

      img {
        display: inline-block;
        width: 27px;
        height: 27px;
      }
    }

    .popup_free {
      width: 100%;
      position: relative;

      .popup_get_1 {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 42px;
        color: rgba(51, 51, 51, 1);
        opacity: 1;
      }

      .popup_get_2 {
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 37px;
        color: rgba(51, 51, 51, 1);
        opacity: 1;
        margin-top: 11px;

        span {
          font-size: 35px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 49px;
          color: rgba(6, 98, 171, 1);
          opacity: 1;
        }
      }
    }

    .popup_quick {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: left;
      -webkit-justify-content: left;
      -ms-flex-pack: left;
      justify-content: left;
      width: 340px;
      margin: 0 auto;
      margin-top: 23px;

      .popup_quickly {
        display: inline-block;
        vertical-align: top;

        .popup_input {
          height: 49px;
          width: 310px;
          color: #333;
          background: #fff;
          border-radius: 10px;
          padding: 14px 16px;
          position: relative;
          margin-bottom: 25px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;

          input {
            width: 100%;
            height: 100%;
            padding: 0;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 22px;
            color: rgba(153, 153, 153, 1);
            opacity: 1;
            border: none;
            outline: none;
          }
        }

        .popup_YZM {
          height: 49px;
          width: 310px;
          color: #333;
          background: #fff;
          border-radius: 10px;
          padding: 14px 16px;
          position: relative;
          margin-bottom: 47px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;

          input {
            width: 100%;
            height: 100%;
            padding: 0;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 22px;
            color: rgba(153, 153, 153, 1);
            opacity: 1;
            outline: none;
            border: none;
          }

          span {
            display: inline-block;
            position: absolute;
            right: 0;
            top: 12px;
            width: 106px;
            height: 25px;
            padding-left: 16px;
            border-left: 2px solid rgba(204, 204, 204, 1);
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 22px;
            color: rgba(6, 98, 171, 1);
            opacity: 1;
            cursor: pointer;
          }
        }

        .popup_EXP {
          cursor: pointer;
        }
      }
    }
  }
}

.Successful {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9999;
  -webkit-animation: index__fade-in___oyvx2;
  -o-animation: index__fade-in___oyvx2;
  animation: index__fade-in___oyvx2;
  -webkit-animation-duration: 0.3s;
  -o-animation-duration: 0.3s;
  animation-duration: 0.3s;

  .Successful_code {
    position: relative;
    width: 503px;
    height: 397px;
    padding: 43px 0 34px 97px;
    background: 50% / contain url(../images/home/popupcode.png) no-repeat;

    .Successful_Close {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 28px;
      height: 28px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      position: absolute;
      top: 36px;
      right: 20px;
      border-radius: 50%;
      cursor: pointer;

      img {
        display: inline-block;
        width: 28px;
        height: 28px;
      }
    }

    .Successful_zero {
      width: 414px;
      position: relative;
      margin-left: 20px;

      p {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 42px;
        color: #fff;
        opacity: 1;
        text-shadow: -10px -2px 8px rgb(255, 145, 0);

        span {
          font-size: 40px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 56px;
          color: rgba(6, 98, 171, 1);
          opacity: 1;
          margin-left: 8px;
          margin-right: 8px;
          text-shadow: none;
        }
      }
    }

    .yoyoQrcode {
      width: 188px;
      height: 188px;
      margin: 18px 0 15px 109px;

      img {
        height: 100%;
      }
    }

    .Successful_focus {
      width: 100%;
      margin-left: 31px;
      padding-bottom: 34px;

      .Successful_receive {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 36px;
        color: #fff;
        opacity: 1;

        span {
          font-family: PingFang SC;
          font-weight: bold;
          color: rgba(6, 98, 171, 1);
          opacity: 1;
        }

        .Successful_class {
          font-size: 25px;
          line-height: 36px;
        }
      }
    }
  }
}

.bottom_get {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 160px;
  z-index: 24;
  // -webkit-transform: translateY(180px);
  // -o-transform: translateY(180px);
  // transform: translateY(180px);
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out,
    -o-transform 0.3s ease-in-out;
  min-width: 1080px;

  .bottomfooter {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
    background: rgba(38, 38, 38, 0.6);
    opacity: 1;

    .bottom_free {
      position: absolute;
      bottom: 0;
      padding-left: 307px;
      -webkit-flex-basis: 1200px;
      -ms-flex-preferred-size: 1200px;
      flex-basis: 1200px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 100px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-transition: padding 0.3s ease-out;
      -o-transition: padding 0.3s ease-out;
      transition: padding 0.3s ease-out;

      .bottom_img {
        position: absolute;
        bottom: 0;
        left: 0;
        background: url("../images/home/twochilds.png") no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        width: 285px;
        height: 180px;
        opacity: 1;
        // -webkit-transition: all .3s ease-out;
        // -o-transition: all .3s ease-out;
        // transition: all .3s ease-out;
      }

      .bottom_valueOF {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 100px;
        color: rgba(255, 255, 255, 1);
        opacity: 1;
        margin-right: 20px;

        span {
          font-size: 40px;
          font-family: PingFang SC;
          font-weight: 800;
          line-height: 56px;
          color: rgba(255, 203, 49, 1);
          opacity: 1;
          margin: 0 8px;
        }
      }

      .bottom_input {
        position: relative;
        -webkit-flex-basis: 569px;
        -ms-flex-preferred-size: 569px;
        flex-basis: 569px;
        overflow: hidden;

        .bottom_number {
          margin-right: 5px;
          background: rgba(255, 255, 255, 1);
          border-radius: 10px;
          width: 282px;
          height: 60px;
          padding-left: 20px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          font-size: 16px;
          line-height: 42px;
          color: rgba(153, 153, 153, 1);
          border: none;
          outline: none;
          float: left;
        }

        #bottom_error {
          position: absolute;
          right: 300px;
          top: 10px;
          line-height: 42px;
          margin-left: 10px;
          color: #ff5c5c;
          font-size: 16px;
        }

        .bottom_button {
          float: left;
          background: rgba(255, 203, 49, 1);
          border-radius: 10px;
          color: #fff;
          width: 282px;
          height: 60px;
          line-height: 60px;
          font-size: 30px;
          cursor: pointer;
          -webkit-transition: all 0.2s;
          -o-transition: all 0.2s;
          transition: all 0.2s;
          border: none;
          outline: none;
        }

        .bottom_button:hover {
          background: rgba(255, 191, 0, 1);
        }
      }
    }
  }
}

@media screen and (max-width: 1313px) {
  .bottom_img {
    display: none;
  }
  .bottom_valueOF {
    margin-left: -285px;
  }
}

.bannerimg {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  img {
    height: 100%;
  }
}

.banner_free {
  position: absolute;
  top: 215px;
  left: 70%;
  z-index: 20;
  width: 330px;
  height: 296px;

  .banner_registered {
    // padding: 34px 24px 0;
    height: 100%;
    background: #fff;
    -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    padding-top: 34px;

    .bannerFree,
    .banner_valueOF {
      text-align: center;
      font-size: 30px;
      font-family: Nowar Warcraft Rounded CN;
      font-weight: bold;
      line-height: 42px;
      color: rgba(51, 51, 51, 1);
      opacity: 1;
      letter-spacing: 0;
    }
    .banner_valueOF span {
      letter-spacing: 0;
      text-align: center;
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 56px;
      color: rgba(255, 203, 49, 1);
      opacity: 1;
    }

    .banner_input {
      margin: 16px 24px 0;
      padding: 19px 43px;
      padding-right: 0;
      width: 238px;
      background: rgba(235, 235, 235, 1);
      opacity: 1;
      border-radius: 33px;

      input {
        display: inline-block;
        width: 140px;
        vertical-align: middle;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 28px;
        color: rgba(153, 153, 153, 1);
        background: rgba(235, 235, 235, 1);
        opacity: 1;
        border: none;
        outline: none;
      }

      #errorformat {
        color: #ff5c5c;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .banner_button {
      margin-left: 11px;
      cursor: pointer;
    }
  }
}
</style>