<template>
<div>
    <div class="expand_header">
        <router-link to="/index">
            <img src="../images/goback.png" alt="" draggable="false">
        </router-link>
    </div>
    <div class="expand_body">
        <img src="../images/noclass.png" alt="" draggable="false">
    </div>
    <div class="expand_last">
        <h3>暂无课程</h3>

    </div>
</div>
</template>

<script>
    export default {
        name: "noCourse"
    }
</script>


<style lang="scss" scoped>
    .expand_header {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        margin: 80px 1020px 0 0;
    }

    .expand_body {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        margin-top: 100px;
    }

    .expand_last {
        text-align: center;

        h3 {
            font-size: 40px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 40px;
            color: #333333;
            opacity: 1;
            margin-top: 80px;
        }

        p {
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 40px;
            color: #333333;
            opacity: 1;
            margin-top: 15px;
        }
    }
    @media screen and (max-width:1600px) {
        .expand_header{
            margin-top: 40px !important;
        }
        .expand_body{
            margin-top: 60px;
        }
        .expand_last{
            h3{
                margin-top: 40px;
            }
        }
    }
</style>
