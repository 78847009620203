<template>
  <div>
    <div class="curriculum_header">
      <img style="cursor:pointer;height:98px;" src="../images/goback.png" alt="" draggable="false" @click="goBack">
      <div class="curriculum_CN" v-clickoutside="handleClose">
        <h1 :title="this.chapterName ">{{ this.chapterName }}</h1>
        <div class="xiala">
          <img v-if="show" src="../images/down.png" alt="" draggable="false" @click="isChapter"/>
          <img v-else src="../images/up1.png" alt="" draggable="false" @click="isChapter"/>
        </div>
        <div class="index_down" v-show="isShowC">
          <p style="cursor: pointer" @click="changeItem(item.id, index,item.name)" v-for="(item, index) in chapterList" :key="index">{{ item.name }}</p>
        </div>
      </div>
      <div class="curriculum_setUp">
        <img class="curriculum_setUp1" src="../images/setUp.png" alt="" draggable="false" @click="outLogin"/>
        <div class="curriculum_outlogin" v-if="isShow" @click="OutLogin">
          <div>
            <img src="../images/loginout.png" alt="" draggable="false" />
          </div>
          <span>退出登录</span>
        </div>
      </div>
    </div>
    <div class="swiper-container1">
      <!-- swiper -->
      <swiper :options="swiperOption">
        <swiper-slide v-for="(item, index) in curriculumList" :key="index" style="cursor:pointer;">
          <div :class="{ bgcolor: active == index }">
            <div class="sta_time" v-show="item.state==0">
              <p class="sta_time_1">{{item.sta_time}} 开始学习</p>
            </div>
            <div v-if="item.state==0" style="width:100%;height:100%;position:absolute;left:0;top:0;border-radius:20px;background:rgba(211,211,211,0.55);z-index:9999;" @click="TheLock(index)">
            </div>
            <div class="curriculum_img" style="position:relative;">
              <img :src="'http://static.yocode.com.cn' + item.image" alt="" draggable="false"/>
              <div class="curriculum_Status">
                <img v-show="item.state==0" src="../images/lock.png" alt="" draggable="false">
              </div>
              <div class="curriculum_status">
                <img v-show="item.state==0" src="../images/lock.png" alt="" draggable="false">
                <img v-show="item.state==1&&item.schedule==100" src="../images/complete.png" alt="" draggable="false">
                <img v-show="item.state==1&&item.schedule<100" src="../images/player.png" alt="" draggable="false">
              </div>
            </div>
            <div style="overflow: hidden" class="curriculum_classFloat">
              <p style=" float: left; margin-left: 20px; font-size: 18px; font-family: PingFang SC; font-weight: 500; line-height: 25px; color: #333333; opacity: 1;" class="curriculum_class">第{{ index + 1 }}课</p>
            </div>
            <h3 style="margin-left: 20px">{{ item.title }}</h3>
            <div style="margin-left: 20px; margin-top: 15px" class="curriculum_block">
              <p style=" font-size: 14px; font-family: Nowar Warcraft Rounded CN; font-weight: 500; color: #333333; opacity: 1;">学习进度</p>
              <el-progress v-show="item.schedule<100" style="width: 94%; margin-top: 8px" :text-inside="true" :stroke-width="26" :percentage="item.schedule" :color="customColors"></el-progress>
              <el-progress v-show="item.schedule>=100" style="width: 94%; margin-top: 8px" :text-inside="true" :stroke-width="26" :percentage="100" :color="customColors"></el-progress>
            </div>
            <div style="margin: 20px 0 0 20px; overflow: hidden" class="curriculum_block">
              <!-- 当进度条为0时 -->
              <div v-show="item.schedule == 0" style="float:left;position:relative;" @click="ToStudy(item.id, item.title, index)">
                <img src="../images/studyback.png" alt="" draggable="false">
                <div style="position:absolute;left:16px;top:14px;overflow:hidden;">
                  <img style="float:left;height:19px;" src="../images/studyicon.png" alt="" draggable="false">
                  <span style="float:left;font-size: 20px;font-family: PingFang SC;font-weight: bold;line-height: 19px;color: #FFFFFF;opacity: 1;margin-left:10px;">马上学习</span>
                </div>
              </div>
              <!-- 当进度条大于0小于80 -->
              <div v-show="item.schedule>0&&item.schedule<80" style="float:left;position:relative;" @click="ToStudy(item.id, item.title, index)">
                <img src="../images/studyback.png" alt="" draggable="false">
                <div style="position:absolute;left:16px;top:14px;overflow:hidden;">
                  <img style="float:left;height:19px;" src="../images/studyicon.png" alt="" draggable="false">
                  <span style="float:left;font-size: 20px;font-family: PingFang SC;font-weight: bold;line-height: 19px;color: #FFFFFF;opacity: 1;margin-left:10px;">继续学习</span>
                </div>
              </div>
              <!-- 当进度条大于等于80 -->
              <div v-show="item.schedule>=80" style="float:left;position:relative;" @click="ToBStudy(item.id, index)">
                <img src="../images/studyback.png" alt="" draggable="false">
                <div style="position:absolute;left:16px;top:14px;overflow:hidden;">
                  <img style="float:left;height:19px;" src="../images/studyicon.png" alt="" draggable="false">
                  <span style="float:left;font-size: 20px;font-family: PingFang SC;font-weight: bold;line-height: 19px;color: #FFFFFF;opacity: 1;margin-left:10px;">复习课程</span>
                </div>
              </div>
              <!-- 当前课程作业还没有通过时,点击跳转到scratch工作台 2021.7.4 需求改变 -->
              <div v-show="item.is_task == 0"  style="float:right;position:relative;margin-right:20px;">
                <img src="../images/workback.png" alt="" draggable="false">
                <div style="position:absolute;left:16px;top:14px;overflow:hidden;">
                  <img style="float:left;height:19px;" src="../images/workicon.png" alt="" draggable="false">

                  <span v-show="item.openwork_switch==1" @click="ToScratch(item.id)" style="float:left;font-size: 20px;font-family: PingFang SC;font-weight: bold;line-height: 19px;color: #FFFFFF;opacity: 1;margin-left:10px;">打开作品</span>

                  <span v-show="item.uploadjob_switch==1" @click="handleModal(item.id)" style="float:left;font-size: 20px;font-family: PingFang SC;font-weight: bold;line-height: 19px;color: #FFFFFF;opacity: 1;margin-left:10px;">上传作业</span>
                </div>
              </div>
              <!-- 已经通过的作业,点击跳转到对应课程作业的位置 -->
              <div v-show="item.is_task == 1" @click="ToWork(index)" style="float:right;position:relative;margin-right:20px;">
                <img src="../images/workback.png" alt="" draggable="false">
                <div style="position:absolute;left:16px;top:14px;overflow:hidden;">
                  <img style="float:left;height:19px;" src="../images/workicon.png" alt="" draggable="false">
                  <span style="float:left;font-size: 20px;font-family: PingFang SC;font-weight: bold;line-height:19px;color: #FFFFFF;opacity: 1;margin-left:10px;">查看作业</span>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
    <!-- 退出登录 -->
    <div v-if="theShow" class="curriculum_modal">
      <div class="modal_box">
        <div class="modal_header">
          <img src="../images/warning.png" alt="" draggable="false" />
        </div>
        <p class="modal_text">确定要退出登录吗？确认后将返回登录界面哟！</p>
        <div class="modal_body">
          <div class="modal_sure" @click="Outlogin">
            <p>确定</p>
          </div>
          <div class="modal_no" @click="backpage">
            <p>取消</p>
          </div>
        </div>
      </div>
    </div>
     <!-- 上传附件弹框 -->
     <div class='uploadModal'>
      <el-dialog
          title="上传作业"
          :visible.sync="dialogVisible"
          width="40%">
           <div class="upload_con">
            <img class="img_pig" src="../images/noclass.png" alt="" width="200px"/>
            <div class="up_info">
              <el-row :gutter="20">
                <el-col :span="8">
                  上传附件：
                  </el-col>
                  <el-col :span="16">
                        <el-upload
                          class="upload-demo"
                          action="https://upload-z1.qiniup.com/"
                          :data="{token:qiniuToken}"
                          :before-remove="beforeRemove"
                          :before-upload="beforeUpload"
                          :on-success="successUpload"
                          :limit="1"
                          :on-exceed="handleExceed"
                          :file-list="fileList">
                          <el-button size="small" type="primary">点击上传</el-button>
                          <div slot="tip" class="el-upload__tip">.rar/.zip文件，且不超过500MB</div>
                        </el-upload>
                  </el-col>
              </el-row>
            </div>
           </div>
          
         
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="uploadModalOK">确 定</el-button>
        </span>
      </el-dialog>
     </div>
    
  </div>
 
</template>

<script>
const clickoutside = {
  // 初始化指令
  bind(el, binding, vnode) {
    function documentHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false;
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e);
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.vueClickOutside = documentHandler;
    document.addEventListener("click", documentHandler);
  },
  update() {},
  unbind(el, binding) {
    // 解除事件监听
    document.removeEventListener("click", el.vueClickOutside);
    delete el.vueClickOutside;
  },
};
import axios from "axios";
import Qs from "qs";

export default {
  data() {
    return {
      show: true,
      modalShow: false,
      dialogVisible: false, //上传作业弹框
      qiniuToken: "", //七牛的token值
      fileList: [], // 上传文件列表
      curriculumID:"", //课程ID
      contentUrl:"", //七牛云上传的路径
      upLoadFileName:"", //上传文件名字
      catalogShow: false,
      VideoShow: false,
      videoSHOW: false,
      isShow: false,
      isShowC: false,
      theShow: false,
      other: 0,
      chapterList: [],
      curriculumList: [],
      // swiper轮播
      swiperOption: {
        slideToClickedSlide: true,
        centeredSlides: true,
        slidesPerView: 5,
        spaceBetween: 30,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        keyboardControl: true,
        mousewheelControl: true,
        initialSlide: "",
      },
      // 进度条各阶段颜色
      customColors: [
        { color: "#f56c6c", percentage: 40 },
        { color: "#e6a23c", percentage: 80 },
        { color: "#50c034", percentage: 100 },
      ],
      // cIndex: 0,
      active: 0,
      gettime: "",
      chapterid: "",
      timeDisplay: 0,
      timeResult: 0,
      time1: null,
      timer: 0,
      pre: "",
      video_file: "",
      video_name: "",
      chapterName: "",
    };
  },
  // computed: {
  //   swiper:function() {
  //     return this.$refs.mySwiper.swiper
  //   }
  // },
  directives: { clickoutside },
  created() {
    // 获取从作业或班级界面传过来的章节id
    if (this.$route.query.chapter_id) {
      this.chapterid = this.$route.query.chapter_id;
    } else {
      this.chapterid = "";
    }
    // 获取从作业或班级界面传过来的下标值，使对应的课程移到中间位置
    if (this.$route.query.index) {
      this.swiperOption.initialSlide = this.$route.query.index;
    } else {
      this.swiperOption.initialSlide = 0;
    }
    this.creatPage();
  },
  mounted() {},
  methods: {
    
    //上传作业弹框
    handleModal(id) {
      this.dialogVisible = true;
      this.curriculumID=id;
      const storage = window.localStorage;
      var fd = new FormData();
      fd.append("token", storage.token);
      axios({
        method: "post",
        //https://back.yocode.com.cn/api/user/upload_token
        url: `${this.$axios.url}/user/upload_token`,
        // dataType: "json",
        data: fd,
      }).then((res) => {
        this.qiniuToken = res.data.data;
        console.log("token:" + this.qiniuToken);
      });
    },
    // 上传移除之前
    beforeRemove(file, fileList) {
      let status = true;
      if (status && file.status === "success") {
        status = this.$confirm(`确定移除 ${file.name}？`);
        if (status) {
          this.fileList = [];
        }
      }
      return status;
    },
    //上传之前判断格式
    beforeUpload(file) {
      const imgType = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isLt150MB = file.size / 1024 / 1024 < 500;
      if (imgType !== "zip" && imgType !== "rar") {
        this.$message.error("上传文件只能是rar和zip格式!");
        return false;
      }
      if (!isLt150MB) {
        this.$message.error("上传图片大小不能超过 500MB!");
        return false;
      }
    },
    //上传成功
    successUpload(response, file, fileList) {
      if (response.hash) {
        this.fileList.push({
          name: file.name,
          // url: response.code.fullurl,
        });
        this.contentUrl=response.key;
        this.upLoadFileName=file.name;
      }
    },
    //上传提示
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    //上传提交
    uploadModalOK(curriculumId) {
       const storage = window.localStorage;
       if(this.fileList.length==0){
         this.$message.warning("请上传作业!");
         return false;
       }
       let params={
         user_id:parseInt(storage.user_id) ,
         classes_id:parseInt(storage.class_id),
         curriculum_id:this.curriculumID,
         name:this.upLoadFileName,
         type:4,
         types:0,
         content_url:`https://static.yocode.com.cn/${this.contentUrl}`
       }
      
      axios({
        method: "post",
        //https://back.yocode.com.cn/api/user/upload_token
        url: `${this.$axios.url}/Task/add`,
        data: {...params},
      }).then((res) => {
        if(res.data.code==1){
           this.$message.success("上传成功!");
           this.dialogVisible=false;
        }else{
           this.$message.error(res.data.msg);
        }
        
      });
    },
    handleClose(e) {
      this.isShowC = false;
    },
    TheLock(i) {},
    creatPage() {
      // 无章节id时初始化渲染章节课程
      if (!this.$store.state.chapterID) {
        axios({
          method: "post",
          // url: "https://back.yocode.com.cn/api/index/chapterList",
          url: `${this.$axios.url}/index/chapterList`,
          dataType: "json",
          data: Qs.stringify({
            token: window.localStorage.token,
            classes_id:window.localStorage.getItem("classId")
          }),
        }).then((res) => {
          if (res.data.code == 1) {
            this.chapterList = res.data.data;
            axios({
              method: "post",
              //  url: "https://back.yocode.com.cn/api/index/curriculumList",
              url: `${this.$axios.url}/index/curriculumList`,
              dataType: "json",
              data: Qs.stringify({
                token: window.localStorage.token,
                chapter_id: "",
                classes_id:window.localStorage.getItem("classId")
              }),
            }).then((res) => {
              if (res.data.code == 1) {
                this.curriculumList = res.data.data.curriculum_list;
                this.chapterName = res.data.data.chapter_name;
                this.$store.commit("changechapter", res.data.data.chapter_name);
                window.localStorage.setItem(
                  "chapter",
                  res.data.data.chapter_name
                );
                this.$store.commit("changechapterID", res.data.data.chapter_id);
              } else {
              }
            });
          } else {
            this.$router.push({
              path: "/index/noCourse",
            });
          }
        });
      } else {
        // 有章节id时初始化渲染章节课程
        axios({
          method: "post",
          //url: "https://back.yocode.com.cn/api/index/chapterList",
          url: `${this.$axios.url}/index/chapterList`,
          dataType: "json",
          data: Qs.stringify({
            token: window.localStorage.token,
            classes_id:window.localStorage.getItem("classId")
          }),
        }).then((res) => {
          if (res.data.code == 1) {
            this.chapterList = res.data.data;
            axios({
              method: "post",
              //url: "https://back.yocode.com.cn/api/index/curriculumList",
              url: `${this.$axios.url}/index/curriculumList`,
              dataType: "json",
              data: Qs.stringify({
                token: window.localStorage.token,
                chapter_id:res.data.data[0].id,
                classes_id:window.localStorage.getItem("classId")
              }),
            }).then((res) => {
              if (res.data.code == 1) {
                this.curriculumList = res.data.data.curriculum_list;
                this.$store.commit("changechapter", res.data.data.chapter_name);
                window.localStorage.setItem(
                  "chapter",
                  res.data.data.chapter_name
                );
                this.$store.commit("changechapterID", res.data.data.chapter_id);

                this.chapterName = res.data.data.chapter_name;
              } else {
              }
            });
          }
        });
      }
    },
    // 切换章节查看课程
    changeItem(c, i, k) {
      this.isShowC = false;
      // this.cIndex = i;
      this.$store.commit("changechapter", k);
      window.localStorage.setItem("chapter", k);
      this.$store.commit("changechapterID", c);
      axios({
        method: "post",
        //url: "https://back.yocode.com.cn/api/index/curriculumList",
        url: `${this.$axios.url}/index/curriculumList`,
        dataType: "json",
        data: Qs.stringify({
          token: window.localStorage.token,
          chapter_id: c,
          classes_id:window.localStorage.getItem("classId")
        }),
      }).then((res) => {
        if (res.data.code == 1) {
          this.curriculumList = res.data.data.curriculum_list;
          this.chapterName = res.data.data.chapter_name;
        } else {
        }
      });
    },
    // 课程视频的课程目录
    showCatalog() {
      this.catalogShow = true;
      axios({
        method: "post",
        //url: "https://back.yocode.com.cn/api/index/curriculumList",
        url: `${this.$axios.url}/index/curriculumList`,
        dataType: "json",
        data: Qs.stringify({
          token: window.localStorage.token,
          chapter_id: this.$store.state.chapterID,
          classes_id:window.localStorage.getItem("classId")
        }),
      }).then((res) => {
        if (res.data.code == 1) {
          this.curriculumList = res.data.data.curriculum_list;
        } else {
        }
      });
    },
    // 点击课程目录中的课程切换视频
    checkVideo(b, s, num) {
      this.$refs.video.pause();
      this.$store.commit("changecurriculumID", b);
      axios({
        method: "post",
        // url: "https://back.yocode.com.cn/api/index/video",
        url: `${this.$axios.url}/index/video`,
        dataType: "json",
        data: Qs.stringify({
          token: window.localStorage.token,
          id: b,
          classes_id:window.localStorage.getItem("classId")
        }),
      }).then((res) => {
        const storage = window.localStorage;
        if (res.data.code == 1) {
          this.video_name = res.data.data.video_name;
          this.video_file = res.data.data.video_file;
          this.catalogShow = false;
        } else {
        }
      });
    },
    closeCatalog() {
      this.catalogShow = false;
    },
    outLogin() {
      this.isShow = !this.isShow;
      localStorage.removeItem("classId");
      localStorage.removeItem("className");
    },
    OutLogin() {
      this.theShow = true;
      this.isShow = false;
    },
    // 点击退出登录
    Outlogin() {
      localStorage.removeItem("token");
      this.$router.push({
        path: "/",
      });
    },
    backpage() {
      this.theShow = false;
    },
    isChapter() {
      this.show = !this.show;
      this.isShowC = !this.isShowC;
    },
    // 向作业界面传参，以达到可以切换到对应课程的作业
    ToWork(e) {
      this.$router.push({
        path: "/index/myWork",
        query: {
          index: e,
        },
      });
      this.$store.commit("changeaIndex", e);
    },
    // 视频中打开scratch工作台
    LookIsWork(a) {
      window.open("about:blank").location.href = "http://scratch.xzs51.com/index.html?token=" + window.localStorage.token + "&class_id=" + window.localStorage.getItem("classId") + "&chapter_id=" + this.$store.state.chapterID + "&curriculum_id=" + a;

    },
    // 当没有作业通过的时候打开scratch工作台
    ToScratch(id) {
      window.open("about:blank").location.href = "http://scratch.xzs51.com/index.html?token=" + window.localStorage.token + "&class_id=" + window.localStorage.getItem("classId") + "&chapter_id=" + this.$store.state.chapterID + "&curriculum_id=" + id;

    },
    // 课程学习完成后复习该课程时打开对应课程视频
    ToBStudy(v, i) {
      this.$store.commit("changecurriculumID", v);
      this.$router.push({
        path: "/index/playVideo",
        query: {
          index: i,
          curriculum_id: v,
        },
      });
      // this.VideoShow = true;
      // axios({
      //   method: "post",
      //   url: "https://back.yocode.com.cn/api/index/video",
      //   dataType: "json",
      //   data: Qs.stringify({
      //     token: window.localStorage.token,
      //     id: p,
      //   }),
      // }).then((res)=>{
      //   if (res.data.code == 1) {
      //     this.video_file = res.data.data.video_file;
      //     this.video_name = res.data.data.video_name;
      //   } else {
      //   }
      // });
    },
    // 课程未完成或开始时打开对应课程视频
    ToStudy(v, n, t) {
      this.$store.commit("changecurriculumID", v);
      this.$router.push({
        path: "/index/playVideo",
        query: {
          index: t,
          curriculum_id: v,
        },
      });
      // this.VideoShow = true;
      // this.active = t
      // this.$store.commit("changeActive", t);
      // window.localStorage.setItem("Active", t);
      // this.$store.commit("changename", n);
      // window.localStorage.setItem("name", n);
      // axios({
      //   method: "post",
      //   url: "https://back.yocode.com.cn/api/index/video",
      //   dataType: "json",
      //   data: Qs.stringify({
      //     token: window.localStorage.token,
      //     id: v,
      //   }),
      // }).then((res)=>{
      //   if (res.data.code == 1) {
      //     this.video_file = res.data.data.video_file;
      //     this.video_name = res.data.data.video_name;
      //   } else {
      //   }
      // });
    },
    // 返回课程页
    TOCurriculum() {
      this.VideoShow = false;
      this.$refs.video.pause();
      // if (this.$refs.video.pause()) {
      //   clearInterval(this.time1);
      //   this.time1 = null
      //   this.timer = 0
      // }
      this.$once("hook:beforeDestroy", () => {
        clearInterval(timer);
        timer = null;
      });
    },
    // 返回上一级
    goBack() {
      this.$router.go(-1);
    },
  },
  beforeDestroy() {
    console.log("beforeDestroy" + "==========" + this.time1);
    // clearInterval(this.timer)
  },
  destroyed() {
    console.log("destroyed" + "==========" + this.time1);
    clearInterval(this.time1);
    this.time1 = null;
    this.timer = 0;
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__header {
  background: hsla(215, 100%, 65%, 1);
  /* border-radius: 8px 8px 0px 0px; */
  height: 60px;
  line-height: 60px;
  /* text-align: center; */
  color: #fff;
  padding: 0 0 0 20px;
}
/deep/ .el-dialog__title {
  color: #fff;
}
/deep/ .el-dialog__close {
  color: #fff;
}
/deep/.el-dialog__headerbtn:focus .el-dialog__close {
  color: #fff;
}
/deep/ .el-dialog__headerbtn:hover .el-dialog__close {
  color: #fff;
}
.upload_con{
  width:270px;
  margin:0 auto;
}
.up_info{
  margin-top:20px;
}
.img_pig {
  margin-left: 20px;
}
.curriculum_header {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-top: 60px;

  .curriculum_CN {
    height: 80px;
    opacity: 1;
    border-radius: 49px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    position: relative;
    margin: 6px 300px 0 264px;

    h1 {
     
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 75px;
      color: #333333;
      opacity: 1;
      margin-right: 30px;
    }

    div {
      margin-top: 18px;
    }

    img {
      cursor: pointer;
    }
  }

  .curriculum_setUp {
    position: relative;

    .curriculum_setUp1 {
      cursor: pointer;
    }

    .curriculum_outlogin {
      width: 169px;
      height: 53px;
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(204, 204, 204, 0.42);
      opacity: 1;
      border-radius: 20px;
      position: absolute;
      right: 10px;
      bottom: -70px;
      padding-top: 27px;
      z-index: 99;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      cursor: pointer;

      span {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
        opacity: 1;
        margin-left: 14px;
      }
    }
  }
}

.index_down {
  width: 305px;
  height: 271px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(204, 204, 204, 0.42);
  opacity: 1;
  border-radius: 20px;
  position: absolute;
  left: 15px;
  top: 60px;
  z-index: 1;
  padding: 0 10px;
  z-index: 999;

  p {
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 42px;
    color: #777777;
    opacity: 1;
    text-align: center;
    border-bottom: 2px solid #cccccc;
    padding-top: 24px;
    padding-bottom: 23px;
  }
}

.index_down::-webkit-scrollbar {
  width: 0px;
}

.curriculum_modal {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(204, 204, 204, 0.42);
  z-index: 99;

  .modal_box {
    width: 450px;
    height: 260px;
    background: rgba(255, 255, 255, 1);
    border-radius: 30px;
    margin: 15% auto;
    z-index: 9999;
    padding-top: 40px;

    .modal_header {
      margin-left: 181px;
    }

    .modal_text {
      width: 287px;
      height: 55px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 29px;
      color: #333333;
      opacity: 1;
      text-align: center;
      margin: 23px auto;
    }

    .modal_body {
      margin-top: 23px;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;

      .modal_sure {
        width: 110px;
        height: 36px;
        background: #ba6a00;
        opacity: 1;
        border-radius: 18px;
        text-align: center;
        cursor: pointer;

        p {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 33px;
          color: #ffffff;
          opacity: 1;
        }
      }

      .modal_no {
        width: 110px;
        height: 36px;
        background: #ffffff;
        border: 1px solid #999999;
        opacity: 1;
        border-radius: 18px;
        text-align: center;
        margin-left: 78px;
        cursor: pointer;

        p {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 33px;
          color: #333333;
          opacity: 1;
        }
      }
    }
  }
}

.swiper-slide {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  width: 340px !important;
  height: 380px !important;
  background: #ffffff;
  opacity: 1;
  border-radius: 20px;
  padding-top: 20px;
  margin-top: 142px;

  .sta_time {
    margin-top: 160px;
    text-align: center;
    display: none;
    font-size: 20px;
    .sta_time_1 {
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 40px;
      color: #333333;
      opacity: 1;
    }
  }

  .curriculum_img {
    width: 300px;
    height: 213px;
    opacity: 1;
    border-radius: 20px;
    margin-left: 20px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
    .curriculum_Status {
      display: none;
    }
    .curriculum_status {
      display: block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #ffffff;
      padding: 5px 5px;
      position: absolute;
      bottom: -25px;
      left: 120px;
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
  h3 {
    margin-top: 15px;
  }
  .curriculum_classFloat {
    margin-top: 34px;
  }
  .curriculum_block {
    display: none;
  }
}

.swiper-slide-active,
.swiper-slide-duplicate-active {
  width: 416px !important;
  height: 564px !important;
  background: #ffffff;
  opacity: 1;
  border-radius: 20px;
  padding-top: 20px;
  margin-top: 42px;
  .sta_time {
    margin-top: 240px;
    text-align: center;
    display: block;
    font-size: 20px;
    position: absolute;
    left: 78px;
    top: -282px;
    .sta_time_1 {
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 40px;
      color: #333333;
      opacity: 1;
    }
  }
  .curriculum_img {
    width: 376px;
    height: 288px;
    opacity: 1;
    border-radius: 20px;
    margin-left: 20px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }

    .curriculum_Status {
      width: 100px;
      height: 100px;
      position: absolute;
      left: 36%;
      top: 79%;
      display: block;
    }
    .curriculum_status {
      display: none;
    }
  }
  h3,
  .curriculum_classFloat {
    margin-top: 20px;
  }
  .curriculum_block {
    display: block;
  }
}

@media screen and(max-width:1600px) {
  .swiper-slide {
    width: 260px !important;
    height: 290px !important;
    border-radius: 20px;
    padding-top: 10px;
    margin-top: 82px;

    .sta_time {
      margin-top: 120px;
      .sta_time_1 {
        font-size: 14px;
      }
    }
    .curriculum_img {
      width: 240px;
      height: 143px;
      border-radius: 20px;
      margin-left: 10px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
      .curriculum_status {
        width: 40px;
        height: 40px;
        left: 100px;
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    width: 366px !important;
    height: 430px !important;
    padding-top: 20px;
    margin-top: 0;
    .sta_time {
      margin-top: 180px;
      .sta_time_1 {
        font-size: 18px;
      }
    }
    .curriculum_img {
      width: 326px;
      height: 188px;
      border-radius: 20px;
      margin-left: 20px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }
    h3,
    .curriculum_classFloat {
      margin-top: 20px;
    }
    .curriculum_block {
      display: block;
    }
  }
  .curriculum_header {
    margin-top: 40px;
  }
  .swiper-pagination {
    width: 100%;
    margin-top: 0 !important;
  }
  .swiper-container {
    height: 500px !important;
  }
  .swiper-button-prev,
  .swiper-button-next {
    bottom: 0;
    width: 100px;
    height: 100px;
  }
  .video_header {
    height: 60px !important;
  }
  .video_back {
    width: 60px !important;
    height: 60px !important;
  }
  .video_text {
    line-height: 60px !important;
  }
  .video_catalog {
    width: 180px !important;
    height: 40px !important;
    padding-top: 10px !important;
    margin-top: 1px !important;
  }
  #videobox {
    height: 580px !important;
  }
  .video_checkwork {
    height: 80px !important;
    .video_towork {
      width: 220px !important;
      height: 50px !important;
      font-size: 26px !important;
      line-height: 50px !important;
      margin-right: 20px;
      margin-top: 10px !important;
    }
  }
}
</style>
<style lang="scss">
/*1026 add --wuyg*/
.swiper-container1 .swiper-pagination-bullet {
  margin-right: 4px !important;
}
</style>
