<template>
<div>
  <div class="userInfo_header">
    <router-link to="/index">
      <img src="../images/goback.png" alt="" draggable="false">
    </router-link>
    <h1>修改个人信息</h1>
    <div style="cursor:pointer;" @click="CheckPassword">
      <img src="../images/checkpassword.png" alt="" draggable="false">
    </div>
  </div>
  <div style="position:fixed;left:0;top:0;width:100%;height:100%;background:rgba(0, 0, 0, 0.5);z-index:99;" v-show="itShow">
    <div class="Login_box">
      <div class="logininput">
        <div style="border-bottom: 1px solid #CCCCCC;text-align:center;">
          <h2>修改密码</h2>
        </div>
        <p style="font-size: 14px;font-family: PingFang SC;font-weight: 500;line-height: 29px;color: #333333;opacity: 1;margin-top:16px;">建议您定期更换密码，设置安全性高的密码可以使账号更安全哟！</p>
        <form>
          <input :type="this.registration_data.pwdType" name="password" v-model="Password" placeholder="请输入原密码" class="input-1" maxLength="11" />
          <div style="position:relative">
            <input :type="this.registration_data.pwdType" name="password" v-model="password" placeholder="请输入新密码" class="input-2" />
            <img style="position:absolute;right:20px;top:15px;width:40px;cursor:pointer;" :src="this.registration_data.src" alt="" @click="changeType()" draggable="false">
          </div>
          <div class="checkBack" @click="SubmitPassword">
            <p style="font-size: 30px;font-family: PingFang SC;font-weight: bold;line-height: 76px;color: #FFFFFF;opacity: 1;">确认修改</p>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="userInfo_body">
    <div class="userInfo_img">
      <div class="userInfo_image">
        <el-upload action="123" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" webkitdirectory>
          <img class="img_absolute" src="../images/imgchange.png" alt="" draggable="false">
          <img style="width:260px;height:260px;border-radius:50%;" v-if="imageUrl" :src="imageUrl" class="avatar">
        </el-upload>
      </div>
      <div class="userInfo_age">
        <p>年龄<span @change="getAge">{{Age}}</span>岁</p>
      </div>
    </div>
    <div class="userInfo_message">
      <div class="userInfo_nickname">
        <span>昵称</span>
        <input type="text" v-model="nickname" placeholder="请输入昵称">
        <img src="../images/textchange.png" alt="" draggable="false">
      </div>
      <div class="userInfo_name">
        <span>签名</span>
        <input type="text" v-model="bio" placeholder="请输入个人签名">
        <img src="../images/textchange.png" alt="" draggable="false">
      </div>
      <div class="userInfo_sex">
        <span>性别</span>
        <span class="selectSex">{{sex}}</span>
        <img style="cursor:pointer;" class="sexX" src="../images/birthdaydown.png" alt="" draggable="false" @click="Select">
      </div>
      <div class="userInfo_sexSelect" v-if="isShow">
        <div class="sex_girl" style="cursor:pointer;" @click="changeGirl">
          <img src="../images/girl.png" alt="" draggable="false">
          <span>女孩</span>
        </div>
        <div class="sex_boy" style="cursor:pointer;" @click="changeBoy">
          <img src="../images/boy.png" alt="" draggable="false">
          <span>男孩</span>
        </div>
      </div>
    </div>
  </div>
  <div style="" class="userInfo_birth">
    <h3 style="float:left;" class="birth">生日</h3>
    <input style="float:left;margin-left:100px;" type="text" placeholder="请选择" v-model="formData.year">
    <span style="float:left;">年</span>
    <img style="cursor:pointer;float:left;display:inline-block;margin-top:12px;margin-left:5px;" src="../images/birthdaydown.png" alt="" draggable="false" @click="birthY">
    <input style="float:left;margin-left:110px;" type="text" placeholder="请选择" v-model="formData.month">
    <span style="float:left;">月</span>
    <img style="cursor:pointer;float:left;display:inline-block;margin-top:12px;margin-left:5px;" src="../images/birthdaydown.png" alt="" draggable="false" @click="birthM">
    <img style="cursor:pointer;float:right;margin-right:40px;display:inline-block;margin-top:12px;margin-left:5px;" src="../images/birthdaydown.png" alt="" draggable="false" @click="birthD">
    <span style="float:right;">日</span>
    <input style="float:right;" type="text" placeholder="请选择" v-model="formData.day">
    <div style="position:absolute;top:94px;left:194px;" class="item" v-if="yearShow">
      <p style="cursor:pointer;" v-for="(item,index) in year" :key="index" :value="item" @click="changeYear(item)">{{item}}</p>
    </div>
    <div style="position:absolute;top:94px;left:468px;" class="item" v-if="monthShow">
      <p style="cursor:pointer;" v-for="(item,index) in month" :key="index" :value="item" @click="changeMonth(item)" @change="getDay">{{item}}</p>
    </div>
    <div style="position:absolute;top:94px;right:36px;" class="item" v-if="dayShow">
      <p style="cursor:pointer;" v-for="(item,index) in day" :key="index" :value="item" @click="changeDay(item)">{{item}}</p>
    </div>
  </div>
  <div style="cursor:pointer;" class="userInfo_save">
    <img src="../images/savechange.png" alt="" draggable="false" @click="saveChange">
  </div>
  <div style="position:fixed;left:0;top:0;width:100%;height:100%;background:rgba(192 ,192 ,192 ,0.4)" v-if="theShow">
    <div style="margin:15% auto;width:450px;height:260px;background:#FFFFFF;border-radius:30px;text-align:center;padding-top:40px">
      <img src="../images/sure.png" alt="" draggable="false" style="width:88px">
      <h3 style="margin-top:27px;font-size:18px;color:#333333;font-weight: 500;font-family: PingFang SC;">修改成功，点击确定返回主页！</h3>
      <router-link to="/index">
        <div style="width: 110px;height: 40px;opacity: 1;border-radius: 25px;margin:34px auto;"><img src="../images/sure_1.png" alt="" draggable="false"></div>
      </router-link>
    </div>
  </div>
  <div style="position:fixed;left:0;top:0;width:100%;height:100%;background:rgba(192 ,192 ,192 ,0.4)" v-if="checkShow">
    <div style="margin:15% auto;width:450px;height:260px;background:#FFFFFF;border-radius:30px;text-align:center;padding-top:40px">
      <img src="../images/sure.png" alt="" draggable="false" style="width:88px">
      <h3 style="margin-top:27px;font-size:18px;color:#333333;font-weight: 500;font-family: PingFang SC;">修改成功，点击确定重新登录！</h3>
      <router-link to="/">
        <div style="width: 110px;height: 40px;opacity: 1;border-radius: 25px;margin:34px auto;"><img src="../images/sure_1.png" alt="" draggable="false"></div>
      </router-link>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import Qs from "qs";
export default {
  data() {
    return {
      year: [],
      month: [],
      day: [],
      formData: {
        year: '',
        month: '',
        day: ''
      },
      nickname: '',
      username: '',
      checkShow:false,
      isShow: false,
      itShow: false,
      imageUrl: '',
      changeSex: '',
      sex: '请选择',
      Age: '',
      bio:'',
      gender:'',
      yearShow: false,
      monthShow: false,
      theShow: false,
      dayShow: false,
      password: '',
      Password:'',
      registration_data:{
        pwdType: "password",
        src: require("../images/close_eyes.png")
      },
    }
  },
  created() {
    this.getuserInfo();
  },
  methods: {
    // 页面初始化的时候渲染用户个人信息
    getuserInfo(){
      axios({
        method: 'post',
       // url: 'https://back.yocode.com.cn/api/user/userinfo',
        url: `${this.$axios.url}/user/userinfo`,
        dataType: 'json',
        data: Qs.stringify({
          token: window.localStorage.token
        })
      }).then(res => {
        if (res.data.code == 1) {
          this.nickname = res.data.data.nickname
          if(!window.localStorage.avatar){
            this.imageUrl = this.$axios.iconUrl+res.data.data.avatar;
          }else{
            this.imageUrl = this.$axios.iconUrl+window.localStorage.avatar
          }
          this.username = window.localStorage.getItem('username'),
          this.formData.year = window.localStorage.getItem('year'),
          this.formData.month = window.localStorage.getItem('month'),
          this.formData.day = window.localStorage.getItem('day'),
          this.bio = window.localStorage.getItem('bio')
          if (!window.localStorage.sex) {
            this.sex = '请选择'
          } else {
            this.sex = window.localStorage.getItem('sex')
          }
        } else {
          alert(res.data.msg);
        }
        return false;
      })
    },
    // 根据选择的生日计算用户年龄
    getAge() {
      let d = new Date();
      let age = d.getFullYear() - window.localStorage.year - (d.getMonth() < window.localStorage.month || (d.getMonth() == window.localStorage.month && d.getDate() < window.localStorage.day) ? 1 : 0);
      this.Age = age;
    },
    birthY() {
      this.yearShow = !this.yearShow
    },
    birthM() {
      if (!this.formData.year) {
        alert('请先选择年份哦')
      } else {
        this.monthShow = !this.monthShow
      }
    },
    // 需要先点击年月,才可以选择日
    birthD() {
      if (!this.formData.month) {
        alert('请先选择月份哦')
      }else{
        this.dayShow = !this.dayShow
        this.getDay();
      }
    },
    // 选择年
    changeYear(e) {
      this.formData.year = e
      window.localStorage.setItem('year', e)
      this.yearShow = false
    },
    // 选择月
    changeMonth(e) {
      this.formData.month = e
      window.localStorage.setItem('month', e)
      this.monthShow = false
    },
    // 选择日
    changeDay(e) {
      this.formData.day = e
      window.localStorage.setItem('day', e)
      this.dayShow = false
    },
    // 用户选择女孩
    changeGirl() {
      this.isShow = false
      this.sex = '女孩'
      this.gender = 0
      this.$store.commit('changegender', this.gender)
      window.localStorage.setItem('sex', this.sex)
    },
    // 用户选择男孩
    changeBoy() {
      this.isShow = false
      this.sex = '男孩'
      this.gender = 1
      this.$store.commit('changegender', this.gender)
      window.localStorage.setItem('sex', this.sex)
    },
    Select() {
      this.isShow = !this.isShow
    },
    init() {
      this.getYear();
      this.getMonth();
      this.getDay();
    },
    //获取年列表
    getYear() {
      let date = new Date;
      let current_year = date.getFullYear();
      for (let i = 0; i < 31; i++) {
        let y = current_year - i;
        this.year.push(y);
      }
    },
    //获取月列表
    getMonth() {
      for (let i = 1; i < 13; i++) {
        this.month.push(i);
      }
    },
    //获取日列表
    getDay() {
      this.day = [];
      let day = this.getDaysInMonth(this.formData.year, this.formData.month)
      for (let i = 1; i <= day; i++) {
        this.day.push(i);
      }
      if(!window.localStorage.year){
        this.Age = ''
      }else{
        let e = new Date();
        let age =
          e.getFullYear() - window.localStorage.year -
          (e.getMonth() < window.localStorage.month ||
            (e.getMonth() == window.localStorage.month &&
              e.getDate() < window.localStorage.day) ?
            1 :
            0);
        this.Age = age;
      }
    },
    //获取某个月的天数
    getDaysInMonth(year, month) {
      month = parseInt(month, 10);
      let d = new Date(year, month, 0);
      return d.getDate();
    },
    //数字前填充0方法
    padding0(num, length) {
      for (let len = (num + "").length; len < length; len = num.length) {
        num = "0" + num;
      }
      return num;
    },
    // 保存修改的个人信息
    saveChange() {
      if (!this.bio) {
        alert('个人签名不能为空')
      } else if (!this.formData.year) {
        alert('生日（年）不能为空')
      }else if (!this.formData.month) {
        alert('生日（月）不能为空')
      }else if (!this.formData.day) {
        alert('生日（日）不能为空')
      }else{
        axios({
          method: 'post',
          //url: 'https://back.yocode.com.cn/api/User/profile',
          url: `${this.$axios.url}/User/profile`,
          dataType: 'json',
          data: Qs.stringify({
            token: window.localStorage.token,
            nickname: this.nickname,
            bio: this.bio,
            age: this.Age,
            gender: this.$store.state.gender,
            avatar: this.imageUrl
          })
        }).then(res => {
          if (res.data.code == 1) {
            window.localStorage.setItem('avatar', this.imageUrl)
            window.localStorage.setItem('nickname', this.nickname)
            this.nickname = this.$store.state.nickname
            window.localStorage.setItem('username', this.username)
            this.username = this.$store.state.username
            window.localStorage.setItem('sex', this.sex)
            this.sex = this.$store.state.sex
            window.localStorage.setItem('year', this.formData.year)
            this.formData.year = this.$store.state.year
            window.localStorage.setItem('month', this.formData.month)
            this.formData.month = this.$store.state.month
            window.localStorage.setItem('day', this.formData.day)
            this.formData.day = this.$store.state.day
            window.localStorage.setItem('bio', this.bio)
            this.bio = this.$store.state.bio
            this.theShow = true
          } else {
          }
          return false;
        })
      }
    },

    // 上传成功后替换
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },

    // 上传用户头像
    beforeAvatarUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJpgOrPng && !isLt2M) {
        this.$message.error('上传头像图片只能是 JPG或者PNG 格式且大小不能超过 2MB!');
      } else {
        const storage = window.localStorage;
        // 获取七牛云token
        const formD = new FormData();
        formD.append('token', storage.token);
        axios({
          method: 'post',
         // url: 'https://back.yocode.com.cn/api/user/upload_token',
          url: `${this.$axios.url}/user/upload_token`,
          dataType: 'json',
          data: formD
        }).then(res => {
          if (res.data.code == 1) {
            // 上传到七牛云，返回用户头像的url
            const form = new FormData();
            form.append('token', res.data.data);
            form.append('file', file);
            axios({
              method: 'post',
              url: 'https://upload-z1.qiniup.com/',
              dataType: 'json',
              data: form
            }).then(ress => {
              // 拼接用户头像的地址
              var ImgSrc = 'http://static.yocode.com.cn/' + ress.data.key; //头像
              this.imageUrl = 'http://static.yocode.com.cn/' + ress.data.key;
            })
          } else {
            alert(res.data.msg);
          }
          return false;
        })
      }
      return isJpgOrPng && isLt2M;
    },
    CheckPassword(){
      this.itShow = true
    },
    // 密码显示隐藏
    changeType(){
      this.registration_data.pwdType = this.registration_data.pwdType==='password'?'text':'password';
      this.registration_data.src=this.registration_data.src==require("../images/close_eyes.png")?require("../images/open_eyes.png"):require("../images/close_eyes.png");
    },
    SubmitPassword(){
      axios({
        method: 'post',
        //url: 'https://back.yocode.com.cn/api/user/resetpwd',
        url: `${this.$axios.url}/user/resetpwd`,
        dataType: 'json',
        data: Qs.stringify({
          token: window.localStorage.token,
          user_id: window.localStorage.user_id,
          oldpassword: this.Password,
          newpassword: this.password
        }),
      }).then(res => {
        if (res.data.code == 1) {
          this.checkShow = true
          this.itShow = false
        } else {
          alert(res.data.msg);
        }
        return false;
      })
    }
  },
  mounted() {
    this.init();
  }
}
</script>

<style lang="scss" scoped>
.userInfo_header {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  position: relative;
  margin-top: 60px;
}

h1 {
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 36px;
  color: #333333;
  opacity: 1;
  text-align: center;
  margin: 25px 240px 0 300px;
}

.userInfo_body {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  position: relative;
}

.userInfo_body {
  margin-top: 54px;

  .userInfo_img {
    margin-right: 108px;

    .userInfo_image {
      position: relative;
      margin-bottom: 30px;

      div {
        width: 260px;
        height: 260px;
        background: #FFFFFF;
        border: 10px solid #94DBFF;
        border-radius: 50%;
        opacity: 1;
      }

      .img_absolute {
        position: absolute;
        right: 43px;
        bottom: -16px;
      }
    }

    .userInfo_age {
      width: 280px;
      height: 81px;
      background: #FFFFFF;
      opacity: 1;
      border-radius: 20px;
      text-align: center;
      padding-top: 39px;

      p {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 42px;
        color: #777777;
        opacity: 1;

        span {
          margin-left: 74px;
        }
      }
    }
  }

  .userInfo_nickname,
  .userInfo_name,
  .userInfo_sex {
    width: 544px;
    height: 81px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 20px;
    padding-left: 40px;
    padding-top: 39px;

    span {
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 36px;
      color: #777777;
      opacity: 1;
    }

    input {
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 36px;
      color: #333333;
      opacity: 1;
      border: none;
      outline: none;
      margin-left: 30px;
    }
  }

  .userInfo_name {
    margin-top: 30px;
  }

  .userInfo_sex {
    position: relative;
    margin-top: 35px;

    .selectSex {
      color: #999999;
      margin-left: 147px;
    }

    .sexX {
      position: absolute;
      right: 41px;
      top: 50px;
    }
  }

  .userInfo_sexSelect {
    width: 355px;
    height: 140px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 20px;
    z-index: 1;
    padding-left: 229px;
    padding-top: 20px;
    position: absolute;

    .sex_girl,
    .sex_boy {
      overflow: hidden;
    }

    .sex_boy {
      margin-top: 20px;
    }

    span {
      float: left;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 46px;
      color: #333333;
      opacity: 1;
      height: 42px;
      margin-left: 45px;
    }

    span:hover {
      color: #94DBFF;
    }

    img {
      height: 46px;
      float: left;
    }
  }
}
.Login_box {
  width: 450px;
  height: 450px;
  background: rgba(255, 255, 255, 0.38);
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 30px;
  padding: 25px;
  position: fixed;
  left:38vw;
  top: 25vh;
  .logininput {
    width: 406px;
    height: 410px;
    background: rgba(255, 255, 255, 1);
    padding: 40px 22px 0 22px;
    border-radius: 30px;
    .checkBack{
      width:388px;
      height:86px;
      margin-top: 27px;
      background-image:url('../images/checkPass.png');
      text-align:center;
      margin-left: 8px;
      cursor: pointer;
    }
    form {
      width: 100%;

      .input-1,
      .input-2 {
        width: 360px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(204, 204, 204, 1);
        opacity: 1;
        border-radius: 14px;
        outline: none;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 46px;
        color: #333333;
        display: block;
        margin: 0 auto;
        padding-left: 10px;
        margin-top: 30px;
      }
    }
  }
  h2{
    width: 120px;
    margin-left: 130px;
    padding-bottom: 12px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 42px;
    color: #333333;
    border-bottom: 5px solid #FFCB31;
  }
}
.userInfo_birth {
  width: 932px;
  height: 81px;
  background: #FFFFFF;
  opacity: 1;
  border-radius: 20px;
  margin: 30px auto;
  padding-left: 40px;
  padding-top: 39px;
  position: relative;

  .birth {
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 42px;
    color: #777777;
    opacity: 1;
  }

  span,
  p {
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 42px;
    color: #333333;
    opacity: 1;
    text-align: center;
  }

  input {
    width: 100px;
    height: 42px;
    border: none;
    outline: none;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 42px;
    color: #333333;
    opacity: 1;
  }

  div {
    width: 162px;
    height: 190px;
    background: #FFFFFF;
    border: 5px solid #94DBFF;
    opacity: 1;
    border-radius: 20px;
    overflow-y: auto;
  }
}

.userInfo_save {
  overflow: hidden;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
</style>
