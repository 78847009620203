<template>
<div class="student-wrap">
  <div v-show="isShow" class="index_top">
    <div class="index_header">
      <div class="index_head">
        <router-link to="/index/userInfo">
          <div class="index_userimg">
            <img style="width:90px;height:90px;border-radius:50%;" :src="this.avatar" alt="" draggable="false">
          </div>
        </router-link>
      </div>
      <div class="index_username">
        <p :title="this.nickname">{{this.nickname}}</p>
      </div>
       <!-- 新加功能，一个学员可以选择多个班级 -->
       <div class="curriculum_CN" v-clickoutside="handleClose">
        <h1 :title="this.chapterName">{{ this.chapterName }}</h1>
        <div class="xiala">
          <img v-if="show" src="../images/down.png" alt="" draggable="false" @click="isChapter"/>
          <img v-else src="../images/up1.png" alt="" draggable="false" @click="isChapter"/>
        </div>
        <div class="index_down" v-show="isShowC">
          <p style="cursor: pointer" @click="changeItem(item.classes_id, index,item.classes_name)" v-for="(item, index) in chapterList" :key="item.classes_id">{{ item.classes_name }}</p>
        </div>
      </div>
    </div>
    <div class="index_flex">
      <div class="index_class">
        <div class="index_classType">
          <div class="index_float">
            <span :title="this.chapterName">{{this.chapterName}}</span>
          </div>
          <div class="index_time">
            <p>{{this.staTime}} 开始学习</p>
          </div>
        </div>
        <div class="index_unit">
          <p class="index_progress">单元进度</p>
          <el-progress :text-inside="true" :stroke-width="26" :percentage="pro"></el-progress>
          <div class="index_allright">
            <h3>已经学到</h3>
            <div class="index_name">
              <p v-if="classNameS">{{$store.state.chapter}}<span> {{$store.state.name}}</span></p>
              <p v-if="classShow">你尚未学习新课程，快点击按钮去学习吧</p>
              <!-- <p v-if="classSHOW">恭喜你完成了全部的课程</p> -->
              <div style="cursor:pointer;" @click="ToStudy()">
                <img src="../images/rightnow.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="index_after">
        <router-link to="/index/myWork">
          <div class="index_work">
            <h3>我的作业</h3>
            <p>共一份原创作品</p>
          </div>
        </router-link>
        <router-link to="/index/expandClass">
          <div class="index_expand">
            <h3>拓展课堂</h3>
          </div>
        </router-link>
      </div>
      <div class="index_activity" @click="ToScratch()">
        <p>在线练习</p>
        <!--<p>线上活动<br />即将上线<br />敬请期待</p>-->
      </div>
    </div>
  </div>
  <div v-show="theShow">
    <div class="expand_body">
      <img src="../images/noclass.png" alt="" draggable="false">
    </div>
    <div class="expand_last">
      <h3>课程正在快速赶来中哟</h3>
      <p>过段时间再来看看吧</p>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import Qs from "qs";
export default {
  data() {
    return {
      // classSHOW: false,
      isShow:true,
      theShow:false,
      classNameS: true,
      classShow: false,
      cIndex: 0,
      nickname: '',
      avatar: '',
      pro:0,
      staTime:'',
      classesName:'',
      chapterName:'',
     show:true,
       isShowC: false,
       chapterList: [],
    }
  },
  created() {
    this.getMyClassList()  //20220816 一个学员有多个班级
    this.getUserInfo()
  },
  methods: {
    // 获取首页用户头像和昵称
    getUserInfo(){
      axios({
        method: 'post',
        url:`${this.$axios.url}/user/userinfo`,
        dataType: 'json',
        data: Qs.stringify({
          token: window.localStorage.token
        })
      }).then(res => {
        if (res.data.code == 1) {
          this.nickname = res.data.data.nickname
          if(!window.localStorage.avatar){
            this.avatar =this.$axios.iconUrl+res.data.data.avatar;
          }else{
            this.avatar = this.$axios.iconUrl+window.localStorage.avatar
          }
        } else {
        }
        return false;
      })
    },
    // 获取首页用户购买班级信息
    getClass(classid){
      axios({
        method: 'post',
       // url: 'https://back.yocode.com.cn/api/index/getUserClasses',
        url: `${this.$axios.url}/index/getUserClasses`,
        dataType: "json",
        data: Qs.stringify({
          token: window.localStorage.token,
          classes_id:classid
        })
      }).then((res) =>{
        if (res.data.code == 1) {
          this.staTime = res.data.data.sta_time
          // this.classesName = res.data.data.classes_name
          if(res.data.data.length == 1){
            this.ClassShow = false
          }else{
            this.ClassShow = true
          }
          if (!window.localStorage.chapter) {
            this.classNameS = false
            this.classShow = true
          } else {
            this.classNameS = true
            this.classShow = false
          }
          if (res.data.data.schedule<=100) {
            this.pro = res.data.data.schedule
          }else{
            this.pro = 0
            // this.classSHOW = true
            // this.classNameS = false
            // this.classShow = false
          }
          if (res.data.data.switch == 0) {
            this.theShow = true
            this.isShow = false
          }else if (res.data.data.switch == 1) {
            this.theShow = false
            this.isShow = true
          }
        } else {}
      })
    },

    ToStudy(){
      if(!this.$store.state.chapter){
        // 当没有章节名称的时候，直接跳转到课程界面
        this.$router.push({
          path: "/index/curriculum",
        })
      }else{
        // 当没有显示课程名称的时候跳转到相应章节下的第一节课
        if(!this.$store.state.name){
          this.$router.push({
            path: "/index/curriculum",
            query: {
              index: 0,
              chapter_id: this.$store.state.chapterID,
              classes_id:window.localStorage.getItem("classId")
            }
          })
        }else{
          // 当存在课程名时跳转到对应课程
          this.$router.push({
            path: "/index/curriculum",
            query: {
              index: this.$store.state.Active,
              chapter_id: this.$store.state.chapterID,
              classes_id:window.localStorage.getItem("classId")
            }
          })
        }
      }
    },
    ToScratch(){
      // window.open("about:blank").location.href = "http://scratch.xzs51.com/index.html?token=" + window.localStorage.token + "&class_id=" + window.localStorage.class_id + "&chapter_id=" + this.$store.state.chapterID + "&curriculum_id=" + id;
      window.open("about:blank").location.href = "http://scratch.xzs51.com/index.html?token=" + window.localStorage.token + "&class_id=" +window.localStorage.getItem("classId") + "";
    },
    isChapter() {
      this.show = !this.show
      this.isShowC = !this.isShowC;
    },
      // 切换班级
    changeItem(id, key, name) {
      this.isShowC = false;
      this.chapterName = name;
      this.classesName=name;
      window.localStorage.setItem("className", name);
       window.localStorage.setItem("classId", id);
       this.getClass(window.localStorage.getItem("classId")) 
    },
     handleClose(e){
      this.isShowC = false
    },
    //2022/8/26新加需求：一个学员多个账号
    getMyClassList(){
        axios({
        method: 'post',
        url: `${this.$axios.url}/index/getMyClassesList`,
        data: Qs.stringify({
          token: window.localStorage.token
        })
      }).then((res) =>{
        if(res){
          
  this.chapterList=res.data.data;
  if(!window.localStorage.getItem("classId") && !window.localStorage.getItem("className")){
 this.chapterName=res.data.data[0].classes_name;
 window.localStorage.setItem("classId", res.data.data[0].classes_id);
   window.localStorage.setItem("className", res.data.data[0].classes_name);
   this.getClass(res.data.data[0].classes_id) 
  }else{
    this.chapterName=window.localStorage.getItem("className");
    this.getClass(window.localStorage.getItem("classId")) 
  }
  
        }
      
      })
    }
    
  },
}
</script>

<style lang="scss" scoped>
  /*.student-wrap{*/
    /*padding-bottom: 60px;*/
    /*background: url("../images/indexback.png") no-repeat;*/
    /*overflow: hidden;*/
  /*}*/
  .index_top{
    display: flex;
    flex-direction: column;
  }
.expand_body {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-top: 240px;
}

.expand_last {
  text-align: center;

  h3 {
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 40px;
    color: #333333;
    opacity: 1;
    margin-top: 80px;
  }

  p {
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 40px;
    color: #333333;
    opacity: 1;
    margin-top: 15px;
  }
}
.index_header {
  width:1179px;
  margin:40px auto 20px auto;
  /*margin-top: 40px;*/
  /*margin-bottom: 20px;*/
  /*margin-left: 140px;*/
  /*display: flex;*/
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  .index_username {
    max-width: 354px;
    height: 52px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 31px;
    margin-top: 26px;
    /*margin-left: 63px;*/
    padding-top: 10px;
    p {

      font-size: 30px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 42px;
      color: #333333;
      opacity: 1;
      text-align: center;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }
  }

  .index_head {
    float: left;
    width: 90px;
    height: 90px;
    background: #F6FCFF;
    border-radius: 50%;
    padding: 10px;
    opacity: 1;
    /*<!--margin-left: -330px;-->*/

    .index_userimg {
      width: 90px;
      height: 90px;
      opacity: 1;
      border-radius: 50%;
    }
  }
}

.index_flex {
  overflow: hidden;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  .index_class {
    width: 660px;
    height: 710px;

    .index_classType {
      width: 610px;
      height: 326px;
      padding-top: 60px;
      padding-left: 50px;
      background: url("../images/yoyotype.png") no-repeat;
      position: relative;

      .index_float {
        overflow: hidden;
        span {
          width: 460px;
           overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
          font-size: 35px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 56px;
          color: #FFFFFF;
          opacity: 1;
          float: left;
        }

        img {
          height: 56px;
          float: left;
          margin-left: 20px;
          cursor: pointer;
        }
      }

      .index_time {
        width: 240px;
        background: #FFFFFF;
        opacity: 1;
        border-radius: 15px;
        text-align: center;
        margin-top: 22px;

        p {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 22px;
          color: #333333;
          opacity: 1;
        }
      }

      .index_down {
        width: 305px;
        height: 271px;
        overflow-y: auto;
        background: #FFFFFF;
        box-shadow: 0px 3px 10px rgba(204, 204, 204, 0.42);
        opacity: 1;
        border-radius: 20px;
        position: absolute;
        left: 48px;
        top: 137px;
        padding: 0 10px;

        p {
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 42px;
          color: #777777;
          opacity: 1;
          text-align: center;
          border-bottom: 2px solid #CCCCCC;
          padding-top: 24px;
          padding-bottom: 23px;
        }
      }

      .index_down::-webkit-scrollbar {
        width: 0px;
      }
    }

    .index_unit {
      height: 272px;
      background: #FFFFFF;
      opacity: 1;
      border-radius: 0 0 20px 20px;
      padding: 52px 30px 0 30px;

      .index_progress {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #999999;
        opacity: 1;
        margin-left: 16px;
        margin-bottom: 16px;
      }

      .index_allright {
        margin-top: 55px;

        h3 {
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 42px;
          color: #333333;
          opacity: 1;
        }

        .index_name {
          overflow: hidden;

          p {
            float: left;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 22px;
            color: #333333;
            opacity: 1;
            margin-top: 17px;
          }

          div {
            float: right;
          }
        }
      }
    }
  }

  .index_after {
    margin: 0 20px;

    h3,
    p {
      color: #FFFFFF;
    }

    h3 {
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 36px;
      color: #FFFFFF;
      opacity: 1;
    }

    .index_work {
      width: 235px;
      height: 383px;
      padding-top: 32px;
      padding-left: 25px;
      background: url("../images/yoyowork.png") no-repeat;

      p {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #FFFFFF;
        opacity: 1;
        margin-top: 11px;
      }
    }

    .index_expand {
      width: 240px;
      height: 239px;
      margin-top: 18px;
      padding-top: 36px;
      padding-left: 20px;
      background: url("../images/yoyoexpand.png") no-repeat;
    }
  }

  .index_activity {
    width: 170px;
    height: 436px;
    background: #8BDDF2;
    border-radius: 20px;
    padding-top: 274px;
    padding-left: 50px;
    cursor: pointer;

    p {
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 60px;
      color: #FFFFFF;
      opacity: 1;
    }
  }
  
}
.curriculum_CN {
    height: 80px;
    opacity: 1;
    border-radius: 49px;
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    position: relative;
    top: -67px;
    h1 {
      max-width:480px;
      overflow: hidden;
      text-overflow: ellipsis;
    white-space: nowrap;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 75px;
      color: #333333;
      opacity: 1;
      margin-right: 30px;
    }

    div {
      margin-top: 18px;
    }

    img {
      cursor: pointer;
    }
    .index_down {

  height: 271px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(204, 204, 204, 0.42);
  opacity: 1;
  border-radius: 20px;
  position: absolute;
  top: 60px;
  z-index: 1;
  padding: 0 10px;
  z-index: 999;

  p {
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 42px;
    color: #777777;
    opacity: 1;
    text-align: center;
    border-bottom: 2px solid #cccccc;
    padding-top: 24px;
    padding-bottom: 23px;
  }
}

.index_down::-webkit-scrollbar {
  width: 0px;
}
}
</style>
