<template>
  <div>
    <div class="myWork_header">
      <img style="cursor: pointer" src="../images/goback.png" alt="" draggable="false" @click="goBack"/>
      <div class="myWork_CN" @click="showDown">
        <h1>{{ this.chapterName }}</h1>
        <div>
          <img v-if="show" src="../images/down1.png" alt="" draggable="false" />
          <img v-else src="../images/up.png" alt="" draggable="false" />
        </div>
        <div class="index_down" v-show="isShow">
          <p style="cursor: pointer" @click="changeItem(item.id, index)" v-for="(item, index) in chapterList" :key="index">
            {{ item.name }}
          </p>
        </div>
      </div>
      <router-link class="myWork_check" to="/index/myWork/classType">
        <img src="../images/check.png" alt="" draggable="false" />
      </router-link>
    </div>
    <div class="myWork_Tab">
      <el-popover class="myWork_K" placement="top" :title="item.title" trigger="hover" v-for="(item, index) in curriculumList" :key="index" :content="item.subtitle">
        <el-button v-if="index > 9" :class="{ bgcolor: active == index }" class="myWork_tabF" slot="reference" @click="changeTab(item.id,index)">
          {{ item.title }}
        </el-button>
      </el-popover>
    </div>
    <div class="myWork_tab">
      <el-popover class="myWork_K" :title="item.title" trigger="hover" v-for="(item, index) in curriculumList" :key="index" :content="item.subtitle">
        <el-button v-if="index < 10" :class="{ bgcolor: active == index }" class="myWork_tabF" slot="reference" @click="changeTab(item.id,index)">
          {{ item.title }}
        </el-button>
      </el-popover>
    </div>
    <div>
      <div class="myWork_body" v-if="workShow">
        <div class="myWork_bodyL">
          <div class="myWork_bodyLW">
            <div class="myWork_bodyLN">
              <video id="videobox" style="width:100%;height:100%;border: none; outline: none" class="video-js vjs-default-skin vjs-big-play-centered" preload="none" oncontextmenu="return false" controlslist="nodownload" controls autoplay="autoplay" ref="video" :src="dataList.content_url" type="video/mp4"></video>
            </div>
            <div class="myWork_adopt" v-show="dataList.state == 2">
              <img src="../images/adopt.png" alt="" draggable="false" />
            </div>
            <div class="myWork_adopt" v-show="dataList.state == 3">
              <img src="../images/noPass.png" alt="" draggable="false" />
            </div>
            <div class="myWork_adopt" v-show="dataList.state == 1">
              <img src="../images/checking.png" alt="" draggable="false" />
            </div>
          </div>
          <div class="myWork_bodyLX">
            <div class="myWork_bodyLX_work">
              <h3>{{ dataList.name }}</h3>
              <div>
                <p>{{ dataList.createtime }}</p>
              </div>
            </div>
            <div>
              <div style="cursor: pointer" class="myWork_review" @click="ToStudy(active)">
                <img src="../images/review.png" alt="" draggable="false" />
              </div>
              <div class="myWork_opening" style="cursor: pointer" @click="ToScratch(dataList.id)">
                <img src="../images/opening.png" alt="" draggable="false" />
              </div>
            </div>
          </div>
        </div>
        <div class="myWork_bodyR">
          <div class="myWork_comment">
            <div class="myWork_bodyRN">
              <h3>老师点评</h3>
            </div>
          </div>
          <div class="myWork_commentT">
            <div class="myWork_commentTeacher">
              <img v-if="picShow" style="border-radius:50%;width:80px;height:80px" :src="'http://static.yocode.com.cn'+ dataList.teacher_avatar" alt="" draggable="false" />
              <img v-else style="border-radius:50%;width:80px;height:80px" src="../images/yo.jpg" alt="" draggable="false" />
            </div>
            <div class="myWork_commentText">
              <div></div>
              <p v-show="dataList.comment_text">{{ dataList.comment_text }}</p>
              <p v-show="!dataList.comment_text">老师暂时还没有批改作业,请同学耐心等待哟!</p>
            </div>
          </div>
        </div>
      </div>
      <div class="myWork_body2" v-if="WorkIsShow">
        <div style="margin-top: 40px">
          <img src="../images/yoyo.png" alt="" draggable="false" />
          <p style=" margin-top: 20px; font-size: 20px; font-family: PingFang SC; font-weight: 500; line-height: 36px; color: #333333; opacity: 1; text-align: center;">你还没有提交作业哟，快去写作业吧！</p>
          <div style="margin: 50px 50px; cursor: pointer" @click="ToStudy(active)">
            <img src="../images/fast.png" alt="" draggable="false" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Qs from "qs";
export default {
  data() {
    return {
      picShow:true,
      show:true,
      activeImg: "",
      active: 0,
      isShow: false,
      chapterList: [],
      chapterID: "",
      chapterName:'',
      dataList: {},
      curriculumList: [],
      taskID: "",
      name: "",
      title: "",
      create_time_text: "",
      cIndex: 0,
      WorkIsShow:false,
      workShow:false
    };
  },
  created() {
    // 获取从课程界面传过来的下标值
    if (this.$route.query.index) {
      this.active = this.$route.query.index;
    } else {
      this.active = 0;
    }
    this.creatWork();
  },
  methods: {
    showDown() {
      this.show = !this.show
      this.isShow = !this.isShow;
    },
    creatWork() {
      // 当没有章节id时渲染课程列表
      if(!this.$store.state.chapterID){
        axios({
          method: "post",
         // url: "https://back.yocode.com.cn/api/index/chapterList",
          url: `${this.$axios.url}/index/chapterList`,
          dataType: "json",
          data: Qs.stringify({
            token: window.localStorage.token,
            classes_id:window.localStorage.getItem("classId")
          }),
        }).then((res) => {
          if (res.data.code == 1) {
            this.chapterList = res.data.data;
            axios({
              method: "post",
              //url: "https://back.yocode.com.cn/api/index/curriculumList",
              url: `${this.$axios.url}/index/curriculumList`,
              dataType: "json",
              data: Qs.stringify({
                token: window.localStorage.token,
                chapter_id:'',
                classes_id:window.localStorage.getItem("classId")
              }),
            }).then((res) => {
              if (res.data.code == 1) {
                this.curriculumList = res.data.data.curriculum_list;
                this.chapterName = res.data.data.chapter_name
                this.$store.commit('changechapterID', res.data.data.chapter_id)
                this.creatTask()
              } else {
              }
            });
          } else {
            this.$router.push({
              path: "/index/noCourse",
            })
          }
        });
      }else{
      // 当存在章节id时渲染课程列表
        axios({
          method: "post",
          //url: "https://back.yocode.com.cn/api/index/chapterList",
          url: `${this.$axios.url}/index/chapterList`,
          dataType: "json",
          data: Qs.stringify({
            token: window.localStorage.token,
            classes_id:window.localStorage.getItem("classId")
          }),
        }).then((res) => {
          if (res.data.code == 1) {
            this.chapterList = res.data.data;
            axios({
              method: "post",
             // url: "https://back.yocode.com.cn/api/index/curriculumList",
              url: `${this.$axios.url}/index/curriculumList`,
              dataType: "json",
              data: Qs.stringify({
                token: window.localStorage.token,
                chapter_id: this.$store.state.chapterID,
                classes_id:window.localStorage.getItem("classId")
              }),
            }).then((res) => {
              if (res.data.code == 1) {
                this.curriculumList = res.data.data.curriculum_list;
                this.chapterName = res.data.data.chapter_name
                this.creatTask()
              } else {
              }
            });
          }
        });
      }
    },
    // 初始化渲染作业
    creatTask(){
      this.$store.commit('changecurriculumID', this.curriculumList[this.active].id)
      axios({
        method: "post",
       // url: "https://back.yocode.com.cn/api/index/getCurriculumTask",
        url: `${this.$axios.url}/index/getCurriculumTask`,
        dataType: "json",
        data: Qs.stringify({
          token: window.localStorage.token,
          curriculum_id: this.curriculumList[this.active].id,
          classes_id:window.localStorage.getItem("classId")
        }),
      }).then((res)=> {
        if (res.data.code == 1) {
          this.dataList = res.data.data[res.data.data.length-1]
          // this.taskID = res.data.data[res.data.data.length-1].id
          // this.$store.commit('changetaskId', this.taskID)
          if(!this.dataList){
            this.WorkIsShow = true
            this.workShow = false
          }else{
            this.WorkIsShow = false
            this.workShow = true
            if(!res.data.data[res.data.data.length-1].teacher_avatar){
              this.picShow = false
            }else{
              this.picShow = true
            }
          }
        } else {
        }
      });
    },
    // 切换章节获取课程
    changeItem(c, i) {
      this.cIndex = i;
      axios({
        method: "post",
        //url: "https://back.yocode.com.cn/api/index/curriculumList",
        url: `${this.$axios.url}/index/curriculumList`,
        dataType: "json",
        data: Qs.stringify({
          token: window.localStorage.token,
          chapter_id:c,
          classes_id:window.localStorage.getItem("classId")
        }),
      }).then((res) => {
        if (res.data.code == 1) {
          this.curriculumList = res.data.data.curriculum_list;
          this.chapterName = res.data.data.chapter_name
          this.$store.commit('changechapterID', res.data.data.chapter_id)
        } else {
        }
      });
    },
    // 切换课程获取对应最新上传的作业
    changeTab(id,index) {
      this.active = index;
      this.$store.commit('changecurriculumID', id)
      axios({
        method: "post",
       // url: "https://back.yocode.com.cn/api/index/getCurriculumTask",
        url: `${this.$axios.url}/index/getCurriculumTask`,
        dataType: "json",
        data: Qs.stringify({
          token: window.localStorage.token,
          curriculum_id: id,
          classes_id:window.localStorage.getItem("classId")
        }),
      }).then((res)=>{
        if (res.data.code == 1) {
          this.dataList = res.data.data[res.data.data.length-1]
          if(!this.dataList){
            this.WorkIsShow = true
            this.workShow = false
          }else{
            this.WorkIsShow = false
            this.workShow = true
          }
        } else {
        }
      });
    },
    // 跳转至对应作业的课程
    ToStudy(e) {
      this.$router.push({
        path: "/index/curriculum",
        query: {
          index: e,
          chapter_id: this.$store.state.chapterID,
        },
      });
    },
    // 跳转到scratch工作台查看自己的作业
    ToScratch(r) {
      window.open("about:blank").location.href = "http://scratch.xzs51.com/index.html?token=" + window.localStorage.token + "&class_id=" + window.localStorage.getItem("classId") + "&chapter_id=" + this.$store.state.chapterID + "&curriculum_id=" + this.curriculumList[this.active].id + "&task_id=" + r;

    },
    // 返回上一级
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.myWork_header {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-top: 60px;

  .myWork_CN {
    width: 357px;
    height: 75px;
    background: #ffffff;
    opacity: 1;
    border-radius: 49px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    position: relative;
    margin: 6px 341px 0 244px;
    cursor: pointer;

    h1 {
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 75px;
      color: #333333;
      opacity: 1;
      margin-right: 30px;
    }

    div {
      margin-top: 31px;
    }
  }

  .myWork_check {
    margin-top: 6px;
  }
}

.myWork_tab {
  width: 1135px;
  margin: -18px auto 0;
  // overflow: hidden;

  .myWork_tabF {
    width: 110px;
    height: 40px;
    background: #5490fb;
    opacity: 1;
    border-radius: 20px 20px 0px 0px;
    text-align: center;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 20px;
    color: #ffffff;
    opacity: 1;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .bgcolor {
    width: 145px;
    height: 55px;
    background: #ffffff;
    opacity: 1;
    border-radius: 20px 20px 0px 0px;
    text-align: center;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 40px;
    color: #333333;
    opacity: 1;
    border: none;
    margin-top: -20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.myWork_Tab {
  width: 1060px;
  margin: 40px auto 0;
  // overflow: hidden;

  .myWork_tabF {
    width: 130px;
    height: 69px;
    background: #a2c2fb;
    opacity: 1;
    border-radius: 20px 20px 0px 0px;
    padding-top: 0;
    line-height: 0;
    text-align: center;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    opacity: 1;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .bgcolor {
    width: 145px;
    height: 70px;
    background: #ffffff;
    opacity: 1;
    border-radius: 20px 20px 0px 0px;
    text-align: center;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 40px;
    color: #333333;
    opacity: 1;
    border: none;
    margin-top: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.myWork_body,
.myWork_body2 {
  width: 1200px;
  height: 606px;
  background: #ffffff;
  opacity: 1;
  border-radius: 20px;
  padding-top: 40px;
  margin: 0 auto;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  .myWork_bodyL {
    margin-right: 40px;

    .myWork_bodyLW {
      width: 492px;
      height: 348px;
      background: #e2ecff;
      opacity: 1;
      border-radius: 20px;
      padding: 16px 21px;
      position: relative;

      .myWork_bodyLN {
        width: 492px;
        height: 348px;
        background: #ffffff;
        opacity: 1;
        border-radius: 20px;

        .myWork_bodyL_img {
          width: 492px;
          height: 348px;
          border-radius: 20px;
        }
      }

      .myWork_adopt {
        position: absolute;
        right: 5px;
        bottom: 5px;
      }
    }

    .myWork_bodyLX {
      margin-top: 30px;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;

      .myWork_bodyLX_work {
        width: 332px;
        height: 140px;
        background: #e2ecff;
        opacity: 1;
        border-radius: 20px;
        padding-top: 16px;
        padding-left: 30px;
        margin-right: 22px;

        h3 {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 33px;
          color: #333333;
          opacity: 1;
        }

        div {
          margin-top: 10px;

          p {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 26px;
            color: #777777;
            opacity: 1;
          }
        }
      }

      .myWork_opening {
        margin-top: 37px;
      }
    }
  }

  .myWork_bodyR {
    width: 526px;
    height: 506px;
    background: #e2ecff;
    opacity: 1;
    border-radius: 20px;
    position: relative;
    padding-top: 60px;

    .myWork_comment {
      width: 140px;
      height: 40px;
      padding: 5px;
      background: #e9f0ff;
      border-radius: 40px;
      position: absolute;
      right: 0;
      top: -14px;

      .myWork_bodyRN {
        width: 140px;
        height: 40px;
        background: #ffffff;
        border-radius: 40px;
        text-align: center;

        h3 {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 40px;
          color: #333333;
          opacity: 1;
        }
      }
    }

    .myWork_commentT {
      width: 492px;
      height: 241px;
      background: #e9f0ff;
      opacity: 1;
      border-radius: 20px;
      padding-top: 24px;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;

      .myWork_commentText {
        width: 330px;
        height: 285px;
        background: #ffffff;
        opacity: 1;
        margin-left: 30px;
        border-radius: 20px;
        padding-left: 14px;
        padding-top: 20px;
        position: relative;

        div {
          position: absolute;
          left: -10px;
          top: 30px;
          width: 20px;
          height: 20px;
          transform: rotate(45deg);
          background: #ffffff;
        }

        p {
          width: 311px;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 36px;
          color: #333333;
          opacity: 1;
        }
      }
    }
  }
}

.index_down {
  width: 305px;
  height: 271px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(204, 204, 204, 0.42);
  opacity: 1;
  border-radius: 20px;
  position: absolute;
  left: 15px;
  top: 60px;
  z-index: 1;
  padding: 0 10px;

  p {
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 42px;
    color: #777777;
    opacity: 1;
    text-align: center;
    border-bottom: 2px solid #cccccc;
    padding-top: 24px;
    padding-bottom: 23px;
  }
}

.index_down::-webkit-scrollbar {
  width: 0px;
}
</style>
