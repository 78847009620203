<!-- 首页 -->
<template>
  <div class="playVideo" @mouseenter="enter()" @mouseleave="leave()">
    <div class="vide-top" v-show="showModel">
      <img style="cursor:pointer;margin: 10px 30px 10px 50px;float: left;" src="../images/left.png" alt="" width="80px" @click="Tocurriculum">
      <span style="float: left;line-height: 100px;">{{this.video_name}}</span>
      <div style=" float: right; width: 222px; height: 45px; border: 2px solid #ffffff; border-radius: 30px; margin-right: 30px; display: flex; -webkit-box-pack: center; justify-content: center; -webkit-justify-content: center; -ms-flex-pack: center; padding-top: 15px; margin-top: 20px; cursor: pointer;" @click="showCatalog">
        <div>
          <img src="../images/mulu.png" alt="" />
        </div>
        <span style=" font-size: 30px; font-family: PingFang SC; font-weight: 500; line-height: 30px; color: #ffffff; opacity: 1; margin-left: 10px;">课程目录</span>
      </div>
      <div class="cataBox" v-if="catalogShow">
        <div style=" width: 83px; height: 50px; background: #73caf9; opacity: 1; border-radius: 0 20px 20px 0; font-size: 24px; font-family: PingFang SC; font-weight: 500; line-height: 50px; color: #ffffff; text-align: center; cursor: pointer;" @click="closeCatalog">关闭</div>
        <div class="catalog" style="display:flex;margin-bottom:60px" v-for="(item, index) in curriculumList" :key="index">
          <div style="margin-top:16px;">
            <img src="../images/player.png" alt="" draggable="false" v-show="item.state==1&&item.schedule<100">
            <img src="../images/lock.png" alt="" draggable="false" v-show="item.state==0">
            <img src="../images/complete.png" alt="" draggable="false" v-show="item.state==1&&item.schedule==100">
          </div>
          <div class="catalogName">
            <p class="catalogClass" @click="checkVideo(item.id ,item.state,index)">{{ item.title }}</p>
          </div>
        </div>
      </div>
      <div style=" width: 260px; height: 70px; background: #ffcb31; opacity: 1; border-radius: 71px; font-size: 30px; font-family: PingFang SC; font-weight: bold; line-height: 70px; color: #ffffff; opacity: 1; text-align: center; float: right; margin:15px 10px 0 0; cursor: pointer;" @click="LookIsWork($store.state.curriculumID)">查看本节课作业</div>
    </div>
    <div class="index-container">
      <video id="videoid" style="width:100%;height:100vh;border: none; outline: none" class="video-js vjs-default-skin vjs-big-play-centered" preload="none" oncontextmenu="return false" controlslist="nodownload" controls autoplay="autoplay" ref="video" :src="'http://static.yocode.com.cn' + this.video_file" type="video/mp4">
        <source :src="'http://static.yocode.com.cn' + this.video_file" type="video/mp4">
      </video>
    </div>

  </div>

</template>

<script>
import axios from 'axios';
import Qs from 'qs';
import Video from 'video.js'
export default {
  name: 'playVideo',
  data () {
    return {
      video:"",
      player:null,
      catalogShow: false,
      curriculumList: [],
      showModel:false,
      id:this.$route.query.curriculum_id,
      classes_id:window.localStorage.classes_id,
      video_file:'',
      video_name:'',
      videoCont:{},
      videoSrc: '',
      timeDisplay:0,
      timeResult:0,
      time1:null,
      timer:0
    }
  },
  created(){
    this.getVideo()
    // this.timeDisplay = Math.floor(document.getElementById('videoid').currentTime);
    // this.timeResult = Math.floor(document.getElementById('videoid').duration);
    this.getTime()
  },

  mounted(){
  },
  methods:{
    //请求数据
    getVideo(){
      axios({
        method: "post",
       // url: "https://back.yocode.com.cn/api/index/video",
        url: `${this.$axios.url}/index/video`,
        dataType: "json",
        data: Qs.stringify({
          token: window.localStorage.token,
          id:this.id,
          classes_id:window.localStorage.getItem("classId")
        }),
      }).then((res)=>{
        if (res.data.code == 1) {
          this.video_file = res.data.data.video_file;
          this.video_name = res.data.data.video_name;
          this.marks()
            //转换marks数据 视频打点数据
            for(var i=0;i< res.data.data.video_node_json.length;i++){
              var min = Number(res.data.data.video_node_json[i].time.split(':')[0])*60;
              var sec = Number(res.data.data.video_node_json[i].time.split(':')[1]);
              var xx=min+sec
              res.data.data.video_node_json[i].time = xx;
              this.videoCont=res.data.data.video_node_json;
            }
        } else {
        }
      });
    },
    getTime(){
      this.time1 = setInterval(()=>{
        this.timer += 0.25
        console.log(this.timer);
      }, 250);
    },
    sendTime(){
      axios({
        method: "post",
       // url: "https://back.yocode.com.cn/api/index/addCurriculumLog",
        url: `${this.$axios.url}/index/addCurriculumLog`,
        dataType: "json",
        data: Qs.stringify({
          token: window.localStorage.token,
          curriculum_id: this.id,
          time: this.timer,
          classes_id:window.localStorage.getItem("classId")
        }),
      }).then((res) => {
        if (res.data.code == 1) {
        } else {
        }
      });
    },
    //鼠标移入移出 showModel显示隐藏
    enter(){
      this.showModel=true;
    },
    leave(){
      this.showModel=false;
    },
    //视频打点
    marks(){
      this.$nextTick(() => {
        this.player = Video('videoid',{
          controlBar: {
            volumePanel: {
                inline: false,
                CurrentTimeDisplay: true
            }
          }
        });
        //创建标记对象
        this.player.markers({
          markerStyle: {
          // 标记点样式
            width: "1.3em",
            height: "1.3em",
            "border-radius": "50%",
            "background-color": "#3ABAFF",
            position: "absolute"
          },
          //悬停标记提示的选项
          markerTip:{
              text: function(marker) {
                return  marker.content;
              },
              time: function(marker) {
                return marker.time;
              }
          },
          //插件显示的一组标记
          markers: this.videoCont
        });

      })
    },
    // 课程视频的课程目录
    showCatalog() {
      this.catalogShow = true;
      axios({
        method: "post",
       // url: "https://back.yocode.com.cn/api/index/curriculumList",
        url: `${this.$axios.url}/index/curriculumList`,
        dataType: "json",
        data: Qs.stringify({
          token: window.localStorage.token,
          chapter_id: this.$store.state.chapterID,
          classes_id:window.localStorage.getItem("classId")
        }),
      }).then((res) => {
        if (res.data.code == 1) {
          this.curriculumList = res.data.data.curriculum_list
        } else {
        }
      });
    },
    // 点击课程目录中的课程切换视频
    checkVideo(b,s,num){
      this.$store.commit('changecurriculumID',b);
      clearInterval(this.time1),
      this.time1 = null,
      this.sendTime()
      if (s==0) {
        alert('该课程尚未解锁，在课程界面查看解锁日期，再准时学习吧！')
      }else if (s==1) {
        this.$store.commit("changecurriculumID", b);
        axios({
          method: "post",
         // url: "https://back.yocode.com.cn/api/index/video",
          url: `${this.$axios.url}/index/video`,
          dataType: "json",
          data: Qs.stringify({
            token: window.localStorage.token,
            id: b,
            classes_id:window.localStorage.getItem("classId")
          }),
        }).then((res)=>{
          if (res.data.code == 1) {
            this.video_name = res.data.data.video_name;
            this.video_file = res.data.data.video_file;
            this.catalogShow = false;
            this.$router.push({
              path: "/index/playVideo",
              query: {
                index: num,
                curriculum_id: b,
              },
            });
            this.$router.go(0)
          } else {
          }
        });
      }
    },
    closeCatalog() {
      this.catalogShow = false;
    },
    // 视频中打开scratch工作台
    LookIsWork(a) {
      window.open("about:blank").location.href = "http://scratch.xzs51.com/index.html?token=" + window.localStorage.token + "&class_id=" + window.localStorage.getItem("classId") + "&chapter_id=" + this.$store.state.chapterID + "&curriculum_id=" + a;

    },
    Tocurriculum(){
      this.$router.go(-1)
      this.player.pause(
        this.sendTime()
      )
    }
  },
 //页面离开时销毁视频对象
  destroyed() {
    this.player.dispose()
    clearInterval(this.time1)
    this.time1 = null
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss">
.playVideo{
  width: 100%;
  .vide-top{
    width: 100%;
    height: 100px;
    background: rgba(51, 51, 51, 0.6);
    border: 1px solid #707070;
    opacity: 1;
    font-size: 30px;
    color: #fff;
    position: fixed;
    top: 0;
    z-index: 9;
    .btn-right{
      width: 260px;
      height: 70px;
      background: #FFCB31;
      opacity: 1;
      border-radius: 71px;
      color: #fff;
      font-size: 30px;
      border: none;
      float: right;
      margin-top: 15px;
      margin-right: 50px;
    }
  }
  .index-container{
    position: relative;
    z-index: 3;
    .video-js{
      width: 100%;
      height: 100vh;
    }
    .video-js .vjs-control-bar{
      height: 6em;
    }
    .vjs-button>.vjs-icon-placeholder:before{
      font-size: 3.8em;
      line-height: 1.67;
    }
    .video-js .vjs-time-control{
      font-size: 2em;
      line-height: 3em;
    }
    .video-js .vjs-progress-holder{
      height: 1.3em;
    }
    .video-js .vjs-play-progress:before{
      font-size: 1.9em;
      top: -0.15em;
    }
    .vjs-tip{
      font-size: 18px;
    }
    // .vjs-marker.white-marker-class{
    //   width: 13px !important;
    //   height:13px;
    //   border-radius:100% !important;
    //   background-color: #3ABAFF !important;
    //   cursor: pointer;
    // }
  }
  .cataBox {
    width: 400px;
    height: 100vh;
    background: #eff9ff;
    opacity: 1;
    overflow-y: scroll;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9999;
    padding-top:20px;
    .catalog {
      margin-left: 32px;
      margin-top: 20px;
      img{
        width: 38px;
        height: 38px;
      }
      .catalogName{
        width: 280px;
        height: 70px;
        background: #FFFFFF;
        box-shadow: 0px 3px 10px rgba(188, 222, 242, 0.6);
        opacity: 1;
        border-radius: 39px;
        text-align: center;
        margin-left: 20px;
        cursor: pointer;

        p{
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 70px;
          color: #333333;
          opacity: 1;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .cataBox::-webkit-scrollbar {
    width: 0px;
  }
}
</style>
