<template>
<div>
  <div class="classType_header">
    <img style="cursor:pointer;" src="../images/goback.png" alt="" draggable="false" @click="goBack">
  </div>
  <h1>我的班级</h1>
  <div class="swiper-container1">
    <div class="classType_body">
      <div class="classType_name">
        <div class="classType_img">
          <img style="width:100%;height:100%;border-radius:20px" :src="'http://static.yocode.com.cn'+classList.classes_image" alt="" draggable="false">
        </div>
        <div class="classType_image">
          <router-link to="/index/myWork" class="classType_imageL">
            <img src="../images/workLook.png" alt="" draggable="false">
          </router-link>
          <img style="cursor:pointer;" class="classType_imageR" src="../images/certificate.png" alt="" draggable="false" @click="checkCertificate">
        </div>
      </div>
      <div class="classType_catalog">
        <h2>{{classList.classes_name}}</h2>
        <div class="classType_num">
          <p>已完成{{classList.user_num}}个/共{{classList.classes_num}}个编程作品</p>
        </div>
        <div class="classType_chapter">
          <div class="classType_chapter1">
            <div class="classType_chapterC">
              <h2>章节目录</h2>
            </div>
          </div>
          <div class="index_down">
            <div class="classType_chapter2" v-for="(item,index) in chapterList" :key="index">
              <div class="classType_chapterN">
                <h3>{{item.name}}</h3>
                <div style="cursor:pointer;" @click="Tochapter(item.id)">
                  <p>点击回顾</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 无证书 -->
  <div style="position:fixed;left:0;top:0;width:100%;height:100%;background:rgba(192 ,192 ,192 ,0.4);z-index:9999;" v-if="theShow">
    <div style="margin:15% auto;width:450px;height:260px;background:#FFFFFF;border-radius:30px;text-align:center;padding-top:40px">
      <img src="../images/warning.png" alt="" draggable="false" style="width:88px">
      <h3 style="margin-top:23px;font-size:18px;font-weight: 500;color:#333333;font-family: PingFang SC;">还未获得证书，继续努力学习吧！</h3>
      <h4 style="margin-top:5px;font-size:18px;font-weight: 500;color:#333333;font-family: PingFang SC;">点击确定返回</h4>
      <div style="width: 110px;height: 36px;background: #BA6A00;opacity: 1;border-radius: 25px;margin:23px auto;"><span style="color:#FFFFFF;font-size:24px;font-weight: bold;font-family: PingFang SC;line-height:36px;cursor:pointer;" @click="GoClassType">确定</span></div>
    </div>
  </div>
  <!-- 有证书 -->
  <div style="position:fixed;left:0;top:0;width:100%;height:100%;background:rgba(192 ,192 ,192 ,0.4);z-index:9999;" v-if="TheShow" @click="closeModal">
    <div style="margin:15% auto;width:724px;height:464px;background:#FFFFFF;padding:20px;">
      <img :src="myCert" alt="" draggable="false" style="">
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import Qs from "qs";
export default {
  data() {
    return {
      classList: {},
      chapterList: [],
      cIndex: 0,
      taskID: '',
      theShow: false,
      TheShow:false,
      myCert:''
    }
  },
  created() {
    this.createGetMyClass();
    this.createChapter()
  },
  methods: {
    // 初始化渲染班级完成情况
    createGetMyClass(){
      axios({
        method: 'post',
       // url: 'https://back.yocode.com.cn/api/index/getMyClasses',
        url: `${this.$axios.url}/index/getMyClasses`,
        dataType: "json",
        data: Qs.stringify({
          token: window.localStorage.token,
          classes_id:window.localStorage.getItem("classId")
        })
      }).then( (res) => {
        if (res.data.code == 1) {
          this.classList = res.data.data
        } else {}
      })
    },
    // 初始化渲染章节列表
    createChapter(){
      axios({
        method: 'post',
        //url: 'https://back.yocode.com.cn/api/index/chapterList',
        url: `${this.$axios.url}/index/chapterList`,
        dataType: "json",
        data: Qs.stringify({
          token: window.localStorage.token,
          classes_id:window.localStorage.getItem("classId")
        })
      }).then((res)=> {
        if (res.data.code == 1) {
          this.chapterList = res.data.data
        } else {}
      })
    },
    // 根据点击的章节跳转至对应章节下的课程
    Tochapter(index) {
      this.$store.state.chapterID = index
      this.$router.push({
        path: "/index/curriculum",
        query: {
          chapter_id: index
        }
      })
    },
    closeModal(){
      this.TheShow = false
    },
    // 查看最新的证书
    checkCertificate(){
      axios({
        method: 'post',
       // url: 'https://back.yocode.com.cn/api/index/getMyCert',
        url: `${this.$axios.url}/index/getMyCert`,
        dataType: "json",
        data: Qs.stringify({
          token: window.localStorage.token,
          classes_id:window.localStorage.getItem("classId")
        })
      }).then((res)=> {
        if (res.data.code == 1) {
          if (res.data.data.length == 0) {
            this.theShow = true
          }else{
            this.TheShow = true
            this.myCert = 'http://static.yocode.com.cn'+res.data.data[res.data.data.length-1].image
          }
        } else {}
      })
    },
    GoClassType(){
      this.theShow = false
    },
    // 返回上一级
    goBack() {
      this.$router.go(-1)
    }
  },
}
</script>

<style lang="scss" scoped>
.classType_header {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  position: relative;
  margin-right: 1020px;
  margin-top: 60px;
}

h1 {
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 36px;
  color: #333333;
  opacity: 1;
  text-align: center;
  // margin-top: -50px;
}

.classType_body {
  margin-top: 40px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  .classType_name,
  .classType_catalog {
    width: 492px;
    height: 460px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 20px;
    padding: 40px 54px 0 54px;
  }

  .classType_name {
    border-right: 1px dashed #CCCCCC;

    .classType_img {
      width: 492px;
      height: 300px;
      background: #C7C7C7;
      opacity: 1;
      border-radius: 20px;
    }

    .classType_process {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 26px;
      color: #777777;
      opacity: 1;
      margin-top: 12px;
    }

    .classType_image {
      overflow: hidden;
      margin-top: 40px;

      .classType_imageL {
        float: left;
      }

      .classType_imageR {
        float: right;
      }
    }
  }

  .classType_catalog{

    h2 {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 33px;
      color: #333333;
      opacity: 1;
    }

    .classType_num {
      width: 270px;
      height: 30px;
      background: #E9F0FF;
      opacity: 1;
      border-radius: 23px;
      text-align: center;
      margin-top: 19px;

      p {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 30px;
        color: #333333;
        opacity: 1;
      }
    }
  }

  .classType_chapter {
    width: 444px;
    height: 198px;
    background: #E9F0FF;
    opacity: 1;
    border-radius: 20px;
    padding: 50px 24px 0 24px;
    margin-top: 50px;
    position: relative;

    .classType_chapter1 {
      width: 140px;
      height: 40px;
      padding: 5px;
      background: #E9F0FF;
      border-radius: 40px;
      position: absolute;
      left: 0;
      top: -14px;

      .classType_chapterC {
        width: 140px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 40px;
        text-align: center;

        h2 {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 40px;
          color: #333333;
          opacity: 1;
        }
      }
    }

    .index_down {
      height: 100%;
      overflow-y: auto;

      .classType_chapter2 {

        .classType_chapterN {
          overflow: hidden;
          margin-bottom: 10px;

          h3 {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 26px;
            color: #333333;
            opacity: 1;
            float: left;
          }

          div {
            width: 100px;
            height: 26px;
            background: #7DABFF;
            opacity: 1;
            border-radius: 13px;
            float: right;
            text-align: center;

            p {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              line-height: 26px;
              color: #FFFFFF;
              opacity: 1;
            }
          }
        }
      }
    }

    .index_down::-webkit-scrollbar {
      width: 0px;
    }
  }
}

.classType_foot {
  margin-top: 40px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  .classType_center {
    margin: 35px 15px 0 15px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;

    .classType_center1 {
      width: 24px;
      height: 24px;
      background: #FFFFFF;
      border-radius: 50%;
      opacity: 1;
    }

    .classType_center2 {
      width: 24px;
      height: 24px;
      background: #E6D3B7;
      border-radius: 50%;
      opacity: 0.4;
      margin: 0 30px;
    }

    .classType_center3 {
      width: 24px;
      height: 24px;
      background: #E6D3B7;
      border-radius: 50%;
      opacity: 0.4;
    }
  }
}
.swiper-container{
  height: 680px !important;
}
.swiper-button-prev{
  left: 38%;
}
.swiper-button-next{
  right: 38%;
}
</style>
