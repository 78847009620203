<template>
<div class="curriculum">
  <div class="banner">
    <div class="bannerimg">
      <img src="../images/home/banner.png" alt="" draggable='false' />
    </div>
    <div class="banner_free">
      <div class="banner_registered">
        <p class="bannerFree">19.9元领取</p>
        <p class="banner_valueOF">价值<span>399</span>元试听课</p>
        <div class="banner_input">
          <input id="phone" type="text" maxLength="11" placeholder="请输入手机号" />
          <span id="errorformat"></span>
        </div>
        <div class="banner_button" @click="PopupShow">
          <img src="../images/home/questget.png" alt="" draggable='false' />
        </div>
      </div>
    </div>
  </div>
  <div class="home_class_system">
    <p class="childResult">ESPM+多元思维课程体系</p>
    <p class="eleYOYO">思维启蒙-熟练应用-逻辑强化-创意创造-视野开拓</p>
    <div class="home_series">
      <div v-for="(item,index) in navList" :key="index" :class = "menuIndex == index ? 'home_series_1 home_series_2' : 'home_series_2'" @click = 'menuShow(index)'>
        <p>{{item}}</p>
      </div>
    </div>
    <div class="home_series_one" v-show = 'menuIndex == 0'>
      <div class="home_series_jian"></div>
      <div class="home_series_first">
        <div class="home_series_img_1">
          <img src="../images/home/Eseries.png" alt="" draggable='false' />
        </div>
        <div class="home_series_img_2">
          <h3>Scratch 图形化编程</h3>
          <img class="home_series_img_3" src="../images/home/righttop.png" alt="" draggable='false' />
          <p class="home_series_p_1">思维启蒙 潜力激发<br/>像玩游戏一样学编程<br/>快乐完成编程基础知识搭建，寓教于乐中动手完成简单程序的制作</p>
          <div class="home_series_img_4"><p>适合 6-11岁</p></div>
          <div class="home_series_img_5">
            <img src="../images/home/Stars.png" alt="" draggable='false' />
            <span>课程知识点：天文、地理、数学、物理、艺术、英语</span>
          </div>
          <div class="home_series_img_6">
            <img src="../images/home/Stars.png" alt="" draggable='false' />
            <span>跨学科知识点：紧贴小学阶段K12课程，融合1200个小学阶段<p>课程知识点</p></span>
          </div>
        </div>
      </div>
    </div>
    <div class="home_series_two" v-show = 'menuIndex == 1'>
      <div class="home_series_jian"></div>
      <div class="home_series_first">
        <div class="home_series_img_1">
          <img src="../images/home/Sseries.png" alt="" draggable='false' />
        </div>
        <div class="home_series_img_2">
          <h3>Scratch 高级图形化编程</h3>
          <img class="home_series_img_3" src="../images/home/righttop.png" alt="" draggable='false' />
          <p class="home_series_p_1">系统思维 熟练应用<br/>逐步实现与现实应用的无缝对接<br/>算法和数据结构的学习，最后综合应用所学知识</p>
          <div class="home_series_img_4"><p>适合 8岁以上</p></div>
          <div class="home_series_img_5">
            <img src="../images/home/Stars.png" alt="" draggable='false' />
            <span>课程知识点：天文、地理、数学、物理、艺术、英语</span>
          </div>
          <div class="home_series_img_6">
            <img src="../images/home/Stars.png" alt="" draggable='false' />
            <span>制造项目：贪吃蛇、超级玛丽、愤怒的老鼠等综合游戏设计</span>
          </div>
        </div>
      </div>
    </div>
    <div class="home_series_three" v-show = 'menuIndex == 2'>
      <div class="home_series_jian"></div>
      <div class="home_series_first">
        <div class="home_series_img_1">
          <img src="../images/home/Pseries.png" alt="" draggable='false' />
        </div>
        <div class="home_series_img_2">
          <h3>Python 代码编程课</h3>
          <img class="home_series_img_3" src="../images/home/righttop.png" alt="" draggable='false' />
          <p class="home_series_p_1">逻辑强化 融会贯通<br/>北京、浙江、山东已将编程列入中高考科目<br/>认证赛事通用编程语言，孩子学习就是在比赛</p>
          <div class="home_series_img_4"><p>适合 9岁以上</p></div>
          <div class="home_series_img_5">
            <img src="../images/home/Stars.png" alt="" draggable='false' />
            <span>课程知识点：循环、判断等常用语法，数据结构、函数等知识</span>
          </div>
          <div class="home_series_img_6">
            <img src="../images/home/Stars.png" alt="" draggable='false' />
            <span>制造项目：AI 机器人、开发语音管家、人脸识别、智能导航</span>
          </div>
        </div>
      </div>
    </div>
    <div class="home_series_four" v-show = 'menuIndex == 3'>
      <div class="home_series_jian"></div>
      <div class="home_series_first">
        <div class="home_series_img_1">
          <img src="../images/home/Mseries.png" alt="" draggable='false' />
        </div>
        <div class="home_series_img_2">
          <h3>Micro: Bit 迈酷硬件课</h3>
          <img class="home_series_img_3" src="../images/home/righttop.png" alt="" draggable='false' />
          <p class="home_series_p_1">创意创造 触摸未来<br/>着眼科创能力的提升与拓展编程外延，将学到的编程知识，系统<br/>应用到 micro:bit硬件硬件组装+前沿科技模拟+学科知识融入</p>
          <div class="home_series_img_4"><p>适合 10岁以上</p></div>
          <div class="home_series_img_5">
            <img src="../images/home/Stars.png" alt="" draggable='false' />
            <span>课程知识点：物联网知识接触、人工智能、航天航空、物理机<p>械、生物化学</p></span>
          </div>
          <div class="home_series_img_6">
            <img src="../images/home/Stars.png" alt="" draggable='false' />
            <span>制造项目：智能家居、智慧城市系统</span>
          </div>
        </div>
      </div>
    </div>
    <div class="home_series_five" v-show = 'menuIndex == 4'>
      <div class="home_series_jian"></div>
      <div class="home_series_first">
        <div class="home_series_img_1">
          <img src="../images/home/Useries.png" alt="" draggable='false' />
        </div>
        <div class="home_series_img_2">
          <h3>U系列-Unique</h3>
          <div class="home_series_img_4"><p>面向国际 视野开拓</p></div>
          <img class="home_series_img_3" src="../images/home/righttop.png" alt="" draggable='false' />
          <p class="home_series_p_1">U 系列课程，发现每个孩子独一无二的闪光点<br/>U系列，YO小程特别系列课程，接轨国际通用PBL项目制课程模<br/>式，每阶段课程会从一个实际问题出发， 编程+兴趣，针对孩子不<br/>同阶段的年龄、特长、爱好进行设计。</p>
          <div class="home_series_img_5">
            <img src="../images/home/Stars.png" alt="" draggable='false' />
            <span>名师1v1国际赛事营</span>
          </div>
          <div class="home_series_img_6">
            <img src="../images/home/Stars.png" alt="" draggable='false' />
            <span>“创”艺术编程营</span>
          </div>
          <div class="home_series_img_6">
            <img src="../images/home/Stars.png" alt="" draggable='false' />
            <span>新奇“造物”编程营</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="home_advanced" style="padding-bottom:100px;"> 
    <p class="childResult">CSM进阶思维教学目标</p>
    <p class="home_eleYOYO">同步国际公认 K12 阶段 《计算机科学教师协会标准》（CSTA）引导孩子发散思维 遵循《麻省理工创造性学习螺旋》</p>
    <p class="home_eleyoyo">注重孩子实际应用和个性化需求 结合教育部颁布《信息技术课程标准》</p>
    <div class="home_objective">
      <div class="home_step">
        <img src="../images/home/home_objectives.png" alt="" draggable='false' />
        <div class="home_step_1" @mouseenter="StepFirst" @mouseleave="StepOne">
          <div class="home_step_one">
            <h3>1</h3>
            <p>解构能力</p>
          </div>
        </div>
        <div class="home_step_2" @mouseenter="StepSecond" @mouseleave="StepTwo">
          <div class="home_step_two">
            <h3>2</h3>
            <p>算法能力</p>
          </div>
        </div>
        <div class="home_step_3" @mouseenter="StepThird" @mouseleave="StepThree">
          <div class="home_step_three">
            <h3>3</h3>
            <p>沟通协作</p>
          </div>
        </div>
        <div class="home_step_4" @mouseenter="StepFourth" @mouseleave="StepFour">
          <div class="home_step_four">
            <h3>4</h3>
            <p>创意能力</p>
          </div>
        </div>
        <div class="home_step_5" @mouseenter="StepFifth" @mouseleave="StepFive">
          <div class="home_step_five">
            <h3>5</h3>
            <p>抽象能力</p>
          </div>
        </div>
        <div class="home_step_6" @mouseenter="StepSixth" @mouseleave="StepSix">
          <div class="home_step_six">
            <h3>6</h3>
            <p>模式提取</p>
          </div>
        </div>
        <div id="home_explanation_one" class="home_explanation_1" v-if="OneShow">
          <div class="home_explanation_jian"></div>
          <p>学会有效、有序地解构复杂的问题，将大问题拆解成小问题，最终逆向建构结构类知识和过程类知识</p>
        </div>
        <div id="home_explanation_two" class="home_explanation_2" v-if="TwoShow">
          <div class="home_explanation_jian"></div>
          <p>算法能力是编程和数学课程学习能力的综合表现，掌握了它就掌握了打开数理思维的启蒙大门，它由阅读算法能力、提炼算理能力、算法语言能力、应用算法能力四部分构成</p>
        </div>
        <div id="home_explanation_three" class="home_explanation_3" v-if="ThreeShow">
          <div class="home_explanation_jian"></div>
          <p>和其他小工程师共同完成大型编程作品，发挥每个成员不同位置上的最大作用，让团队优势翻倍增长</p>
        </div>
        <div id="home_explanation_four" class="home_explanation_4" v-if="FourShow">
          <div class="home_explanation_jian"></div>
          <p>玩出未来创意能力，经过充分系统的比较和可靠预测，灵活调整程序形态，从你的键盘下诞生更多创意作品</p>
        </div>
        <div id="home_explanation_five" class="home_explanation_5" v-if="FiveShow">
          <div class="home_explanation_jian"></div>
          <p>把具体变成概念，进而驾驭概念进行思考，从复杂的逻辑步骤中抓住事物的本质，让程序变得简单和有趣</p>
        </div>
        <div id="home_explanation_six" class="home_explanation_6" v-if="SixShow">
          <div class="home_explanation_jian"></div>
          <p>解决抽象形式的分类问题，使用机器的思维方式解决计算机的问题，踏入人工智能的第一步</p>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="bottom_foot">
      <p class="bottom-message">地址: 河南省郑州市金水区文化路与红旗路交叉口西300米广厦城市之巅9号楼</p>
      <p class="bottom-dizhi">COPYRIGHT2020 行知塾教育集团 <span>豫ICP备19005091号-1</span></p>
    </div>
    <div class="bottom_get">
      <div class="bottomfooter">
        <div class="bottom_free">
          <div class="bottom_img">
            <img src="../images/home/twochilds.png" alt="" />
          </div>
          <p class="bottom_valueOF">19.9元领取价值<span>399元</span>体验课</p>
          <div class="bottom_input">
            <input id="bottom_tele" class="bottom_number" type="text" maxLength="11" placeholder="请输入手机号" />
            <span id="bottom_error"></span>
            <button id="bottom_Buttom" class="bottom_button" @click="popupShow">立即领取</button>
          </div>
        </div>
      </div>
    </div>
    <div id="Popupwindow" class="PopupWindow" v-if="poPup">
      <div class="PopupEX">
        <span class="Close_popup">
          <img src="../images/home/closePopup.png" alt="" draggable='false' @click="closePop"/>
        </span>
        <div class="popup_free">
          <p class="popup_get_1">19.9元领取</p>
          <p class="popup_get_2">价值<span>399元</span>编程体验课</p>
        </div>
        <div class="popup_quick">
          <div class="popup_quickly">
            <div class="popup_input">
              <input id="popup_tele" type="text" placeholder="请输入手机号" maxLength="11" name="mobile"/>
            </div>
            <div class="popup_YZM">
              <input id="popup_event" type="text" placeholder="短信验证码" maxLength="4" />
              <span id="popup_span" @click="handleClick">获取验证码</span>
            </div>
            <div class="popup_EXP" @click="successPopShow">
              <img src="../images/home/experience.gif" alt="" draggable='false' />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="Successful_CLASS" class="Successful" v-if="successPop">
      <div class="Successful_code">
        <span class="Successful_Close" @click="closeSPop">
          <img src="../images/home/closePopup.png" alt="" draggable='false' />
        </span>
        <div class="Successful_zero">
          <p class="Successful_look">看这里<span>0</span>元体验6节编程课</p>
        </div>
        <div class="yoyoQrcode">
          <img src="../images/home/yoyoqrcode.png" alt="" draggable='false' />
        </div>
        <div class="Successful_focus">
          <p class="Successful_receive">关注【游研视界】在后台回复<span class="Successful_class">领取课程</span><br />即刻领取价值<span>399</span>元<span>6</span>节编程课<br />快来和YOYO一起体验编程的乐趣吧！</p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import Qs from "qs";
  export default {
    data() {
      return {
        menuIndex:0,
        navList:['E系列-Enlighten','S系列-Systemetic','P系列-Proficient','M系列-Maker','U系列-Unique'],
        OneShow:false,
        TwoShow:false,
        ThreeShow:false,
        FourShow:false,
        FiveShow:false,
        SixShow:false,
        poPup: false,
        successPop: false
      }
    },
    methods:{
      menuShow (index) {
        this.menuIndex = index
      },
      StepFirst(){
        this.OneShow = true
      },
      StepOne(){
        this.OneShow = false
      },
      StepSecond(){
        this.TwoShow = true
      },
      StepTwo(){
        this.TwoShow = false
      },
      StepThird(){
        this.ThreeShow = true
      },
      StepThree(){
        this.ThreeShow = false
      },
      StepFourth(){
        this.FourShow = true
      },
      StepFour(){
        this.FourShow = false
      },
      StepFifth(){
        this.FiveShow = true
      },
      StepFive(){
        this.FiveShow = false
      },
      StepSixth(){
        this.SixShow = true
      },
      StepSix(){
        this.SixShow = false
      },
      PopupShow(){
        var phone = document.getElementById('phone').value;
        if ((/^1[34578]\d{9}$/.test(phone))) {
          document.getElementById('errorformat').innerHTML = '';
          this.poPup = true
        } else {
          document.getElementById('errorformat').innerHTML = '格式错误'
          return false
        }
      },
      popupShow(){
        var phone = document.getElementById('bottom_tele').value;
        if ((/^1[34578]\d{9}$/.test(phone))) {
          document.getElementById('bottom_error').innerHTML = '';
          this.poPup = true
        } else {
          document.getElementById('bottom_error').innerHTML = '格式错误'
          return false
        }
      },
      closePop(){
        this.poPup = false
      },
      closeSPop(){
        this.successPop = false
      },
      handleClick(){
        axios({
          method: 'post',
          url: 'https://yoback.xzs51.com/api/sms/send',
          dataType: "json",
          data: Qs.stringify({
            mobile: document.getElementById('popup_tele').value,
            event: 'changemobile'
          })
        }).then(function (res) {
          if (res.data.code == 1) {
            alert(res.data.msg)
            document.getElementById('popup_span').innerHTML = "已成功发送";
          } else {
            alert(res.data.msg);
            input.buttom.disabled = false;
          }
          return false;
        })
      },
      successPopShow(){
        this.successPop = true
        this.poPup = false
      }
    }
  }
</script>

<style lang="scss" scoped>
.curriculum{
  width: 100%;
  background:#fff;
}
.childResult {
  font-size:35px;
  font-family:PingFang SC;
  font-weight:bold;
  line-height:40.98px;
  color:rgba(51,51,51,1);
  text-align: center;
  padding-top: 100px;
  opacity:1;
}
.eleYOYO{
  width: 100%;
  height: 22px;
  text-align: center;
  font-size:16px;
  font-family:PingFang SC;
  font-weight:500;
  line-height:22px;
  color:rgba(119,119,119,1);
  opacity:1;
  margin: 16px auto 0;
}
.curriculumback{
  width: 100%;
  padding-top: 10vh;
  padding-bottom: 10vh;
  background: #fff;

  img{
    width: 50%;
    padding-left: 25%;
  }
}

.classgetback{
  width: 100%;
  padding-bottom: 6vh;
  background-color:rgba(249,203,0,0.09);

  .classget {
    padding-top: 6vh;
    margin-left: 43%;

    img{
      width: 25%;
    }
  }

  .ClassGET{
    width: 51%;
    margin-left: 25%;
    margin-top: 6vh;
    overflow: hidden;

    .get{
      width: 48%;
      float: left;

      img{
        width: 100%;
      }
    }

    .series{
      width: 50%;
      margin-left: 2%;
      margin-top: 6vh;
      float: left;

      .aseriesof{
        width: 100%;
        overflow: hidden;

        .Bseries{
          float: left;
          width: 35%;
          background:rgba(255,148,1,1);
          opacity:1;
          border-radius:10px;
          text-align: center;
          color: #fff;
          font-size:0.82vw;
          font-family:PingFang SC;
          font-weight:500;
          line-height:4vh;
          padding-left: 1%;
          padding-right: 1%;
          margin-left: 23%;
          cursor: pointer;
        }
        .Aseries{
          float: left;
          width: 35%;
          border:1px solid rgba(204,204,204,1);
          opacity:1;
          border-radius:10px;
          font-size:0.82vw;
          font-family:PingFang SC;
          font-weight:500;
          line-height:4vh;
          color:rgba(153,153,153,1);
          padding-left: 1%;
          padding-right: 1%;
          text-align: center;
          margin-left: 2%;
          cursor: pointer;
        }
      }

      .ABseries ,.abseries{
        margin-top: 4vh;

        h3,h4{
          text-align: right;
        }

        h3{
          font-size:1.25vw;
          font-family:PingFang SC;
          font-weight:bold;
          line-height:36px;
          color:rgba(51,51,51,1);
          opacity:1;
        }

        h4 {
          font-size:0.7vw;
          font-family:PingFang SC;
          font-weight:500;
          line-height:36px;
          color:rgba(119,119,119,1);
          opacity:1;
        }

        p{
          width: 99%;
          font-size:0.9vw;
          font-family:PingFang SC;
          font-weight:500;
          line-height:36px;
          color:rgba(119,119,119,1);
          opacity:1;
        }
      }

      .ABseries{
        display: block;
      }
      
      .abseries{
        display: none;
      }
    }
  }
}

.abilityback{
  width: 100%;
  padding-top: 10vh;
  padding-bottom: 10vh;
  background: #fff;

  img{
    width: 50%;
    padding-left: 25%;
  }
}

.extension{
  width: 100%;
  padding-bottom: 10vh;

  .knowledgeback{
    margin-left: 43%;

    img{
      width: 25%;
    }
  }

  .classtext{
    width: 50%;
    margin: 6vh auto;
    font-size:0.98vw;
    font-family:PingFang SC;
    font-weight:500;
    line-height:4vh;
    color:rgba(29,26,26,1);
    opacity:1;
  }

  .knowledgeIMG{
    width: 70%;
    overflow: hidden;
    padding-left: 22%;

    .KnowledgeExtension{
      width: 8%;
      float: left;
      padding-left: 4.5%;

      img{
        width: 100%;
      }

      p{
        text-align: center;
        font-size:0.98vw;
        font-family:PingFang SC;
        font-weight:500;
        line-height:4vh;
        color:rgba(29,26,26,1);
        opacity:1;
      }
    }
  }
}
.bannerimg{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;

    img{
      height: 100%;
    }
}

.banner_free{
  position: absolute;
  top: 215px;
  left:70%;
  z-index: 20;
  width: 330px;
  height: 296px;

  .banner_registered{
    // padding: 34px 24px 0;
    height: 100%;
    background: #fff;
    -webkit-box-shadow: 0 8px 20px 0 rgba(0,0,0,0.2);
    box-shadow: 0 8px 20px 0 rgba(0,0,0,0.2);
    border-radius: 30px;
    padding-top: 34px;

    .bannerFree ,.banner_valueOF{
      text-align: center;
      font-size:30px;
      font-family:Nowar Warcraft Rounded CN;
      font-weight:bold;
      line-height:42px;
      color:rgba(51,51,51,1);
      opacity:1;
      letter-spacing: 0;
    }
    .banner_valueOF span{
      letter-spacing: 0;
      text-align: center;
      font-size:40px;
      font-family:PingFang SC;
      font-weight:bold;
      line-height:56px;
      color:rgba(255,203,49,1);
      opacity:1;
    }

    .banner_input {    
      margin:16px 24px 0;
      padding: 19px 43px;
      padding-right: 0;
      width: 238px;
      background:rgba(235,235,235,1);
      opacity:1;
      border-radius: 33px;

      input{
        display: inline-block;
        width: 140px;
        vertical-align: middle;
        font-size:20px;
        font-family:PingFang SC;
        font-weight:500;
        line-height:28px;
        color:rgba(153,153,153,1);
        background: rgba(235,235,235,1);
        opacity:1;
        border: none;
        outline: none;
      }

      #errorformat {    
        color: #ff5c5c;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .banner_button{
      margin-left: 11px;
      cursor: pointer;
    }
  }
}
.home_advanced{

  .home_eleYOYO ,.home_eleyoyo{
    width: 100%;
    height: 22px;
    text-align: center;
    font-size:16px;
    font-family:PingFang SC;
    font-weight:500;
    line-height:22px;
    color:rgba(119,119,119,1);
    opacity:1;
  }

  .home_eleYOYO{
    margin: 16px auto 0;
  }

  .home_eleyoyo{
    margin: 10px auto 0;
    margin-bottom: 132px;
  }

  .home_objective{
    // overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;

    .home_step{
      position: relative;

      img{
        margin-right: 96px;
      }

      .home_step_1{
        position: absolute;
        left: 40px;
        top: 16px;
        width: 145px;
        height: 145px;
        background: #FFF9E7;
        border-radius: 50%;
        opacity: 1;
        padding: 23px 0 0 23px;
        cursor: pointer;

        .home_step_one{
          width: 122px;
          height: 122px;
          background: #FEC93A;
          border-radius: 50%;
          opacity: 1;

          h3{
            font-size: 45px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 41px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            padding-top: 20px;
          }

          p{
            font-size: 20px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 34px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
          }
        }
      }

      .home_step_2{
        position: absolute;
        left: 706px;
        top: -72px;
        width: 127px;
        height: 127px;
        background: #FFF6DC;
        border-radius: 50%;
        opacity: 1;
        padding: 20px 0 0 20px;
        cursor: pointer;

        .home_step_two{
          width: 107px;
          height: 107px;
          background: #FFAB38;
          border-radius: 50%;
          opacity: 1;

          h3{
            font-size: 40px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 36px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            padding-top: 15px;
          }

          p{
            font-size: 18px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 30px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            margin-top: 5px;
          }
        }
      }

      .home_step_3{
        position: absolute;
        left: 1034px;
        top: 94px;
        width: 141.5px;
        height: 141.5px;
        background: #FFEEE2;
        border-radius: 50%;
        opacity: 1;
        padding: 22.5px 0 0 22.5px;
        cursor: pointer;

        .home_step_three{
          width: 120px;
          height: 120px;
          background: #FF953E;
          border-radius: 50%;
          opacity: 1;

          h3{
            font-size: 45px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 41px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            padding-top: 20px;
          }

          p{
            font-size: 20px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 33px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
          }
        }
      }

      .home_step_4{
        position: absolute;
        left: 809px;
        top: 315px;
        width: 127px;
        height: 127px;
        background: #FFF1EC;
        border-radius: 50%;
        opacity: 1;
        padding: 20px 0 0 20px;
        cursor: pointer;

        .home_step_four{
          width: 107px;
          height: 107px;
          background: #FF7846;
          border-radius: 50%;
          opacity: 1;

          h3{
            font-size: 40px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 36px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            padding-top: 15px;
          }

          p{
            font-size: 18px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 30px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            margin-top: 5px;
          }
        }
      }

      .home_step_5{
        position: absolute;
        left: 574px;
        top: 494px;
        width: 147px;
        height: 147px;
        background: #FFEFE4;
        border-radius: 50%;
        opacity: 1;
        padding: 23px 0 0 23px;
        cursor: pointer;

        .home_step_five{
          width: 124px;
          height: 124px;
          background: #FF5C4E;
          border-radius: 50%;
          opacity: 1;

          h3{
            font-size: 45px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 41px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            padding-top: 20px;
          }

          p{
            font-size: 20px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 33px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            margin-top: 5px;
          }
        }
      }

      .home_step_6{
        position: absolute;
        left: 1067px;
        top: 604px;
        width: 127px;
        height: 127px;
        background: #FFE3E6;
        border-radius: 50%;
        opacity: 1;
        padding: 20px 0 0 20px;
        cursor: pointer;

        .home_step_six{
          width: 107px;
          height: 107px;
          background: #FF4355;
          border-radius: 50%;
          opacity: 1;

          h3{
            font-size: 40px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 36px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            padding-top: 15px;
          }

          p{
            font-size: 18px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 30px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            margin-top: 5px;
          }
        }
      }

      .home_explanation_1{
        position: absolute;
        left: 228px;
        top: 38px;
        width: 374px;
        height: 102px;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(226, 226, 226, 0.76);
        border-radius: 20px;
        padding: 39px 52px 31px 52px;
        opacity: 1;

        .home_explanation_jian{
          width: 60px;
          height: 60px;
          box-shadow: -5px 5px 10px rgba(226, 226, 226, 0.5);
          transform: rotate(45deg);
          background: #FFFFFF;
          position: absolute;
          left: -30px;
          top: 60px;
        }

        p{
          width: 374px;
          height: 102px;
          font-size: 20px;
          font-family: Nowar Warcraft Rounded CN;
          font-weight: 400;
          line-height: 35px;
          color: #333333;
          opacity: 1;
        }
      }

      .home_explanation_2{
        position: absolute;
        left: 198px;
        top: -90px;
        width: 374px;
        height: 172px;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(226, 226, 226, 0.76);
        border-radius: 20px;
        padding: 39px 52px 31px 52px;
        opacity: 1;

        .home_explanation_jian{
          width: 60px;
          height: 60px;
          box-shadow: 5px -5px 10px rgba(226, 226, 226, 0.5);
          transform: rotate(45deg);
          background: #FFFFFF;
          position: absolute;
          left: 450px;
          top: 90px;
        }

        p{
          width: 374px;
          height: 102px;
          font-size: 20px;
          font-family: Nowar Warcraft Rounded CN;
          font-weight: 400;
          line-height: 35px;
          color: #333333;
          opacity: 1;
        }
      }

      .home_explanation_3{
        position: absolute;
        left: 528px;
        top: 108px;
        width: 374px;
        height: 102px;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(226, 226, 226, 0.76);
        border-radius: 20px;
        padding: 39px 52px 31px 52px;
        opacity: 1;

        .home_explanation_jian{
          width: 60px;
          height: 60px;
          box-shadow: 5px -5px 10px rgba(226, 226, 226, 0.5);
          transform: rotate(45deg);
          background: #FFFFFF;
          position: absolute;
          left: 450px;
          top: 60px;
        }

        p{
          width: 374px;
          height: 102px;
          font-size: 20px;
          font-family: Nowar Warcraft Rounded CN;
          font-weight: 400;
          line-height: 35px;
          color: #333333;
          opacity: 1;
        }
      }

      .home_explanation_4{
        position: absolute;
        left: 300px;
        top: 318px;
        width: 374px;
        height: 102px;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(226, 226, 226, 0.76);
        border-radius: 20px;
        padding: 39px 52px 31px 52px;
        opacity: 1;

        .home_explanation_jian{
          width: 60px;
          height: 60px;
          box-shadow: 5px -5px 10px rgba(226, 226, 226, 0.5);
          transform: rotate(45deg);
          background: #FFFFFF;
          position: absolute;
          left: 450px;
          top: 60px;
        }

        p{
          width: 374px;
          height: 102px;
          font-size: 20px;
          font-family: Nowar Warcraft Rounded CN;
          font-weight: 400;
          line-height: 35px;
          color: #333333;
          opacity: 1;
        }
      }

      .home_explanation_5{
        position: absolute;
        left: 768px;
        top: 508px;
        width: 374px;
        height: 102px;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(226, 226, 226, 0.76);
        border-radius: 20px;
        padding: 39px 52px 31px 52px;
        opacity: 1;

        .home_explanation_jian{
          width: 60px;
          height: 60px;
          box-shadow: -5px 5px 10px rgba(226, 226, 226, 0.5);
          transform: rotate(45deg);
          background: #FFFFFF;
          position: absolute;
          left: -30px;
          top: 60px;
        }

        p{
          width: 374px;
          height: 102px;
          font-size: 20px;
          font-family: Nowar Warcraft Rounded CN;
          font-weight: 400;
          line-height: 35px;
          color: #333333;
          opacity: 1;
        }
      }

      .home_explanation_6{
        position: absolute;
        left: 558px;
        top: 608px;
        width: 374px;
        height: 102px;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(226, 226, 226, 0.76);
        border-radius: 20px;
        padding: 39px 52px 31px 52px;
        opacity: 1;

        .home_explanation_jian{
          width: 60px;
          height: 60px;
          box-shadow: 5px -5px 10px rgba(226, 226, 226, 0.5);
          transform: rotate(45deg);
          background: #FFFFFF;
          position: absolute;
          left: 450px;
          top: 60px;
        }

        p{
          width: 374px;
          height: 102px;
          font-size: 20px;
          font-family: Nowar Warcraft Rounded CN;
          font-weight: 400;
          line-height: 35px;
          color: #333333;
          opacity: 1;
        }
      }
    }
  }
}
.home_class_system{

  .home_series{
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
    margin-top: 60px;

    div{
      width: 216px;
      height: 61px;
      border-radius: 45px;
      opacity: 1;
      cursor: pointer;

      p{
        font-size: 20px;
        font-family: PingFang SC;
        line-height: 60px;
        opacity: 1;
        text-align: center;
      }
    }

    .home_series_1 ,.home_series_2 ,.home_series_3 ,.home_series_4{
      margin-right: 30px;
    }

    .home_series_1{
      background: #F59F1A !important;

      p{
        font-weight: bold !important;
        color: #FFFFFF !important;
      }
    }

    .home_series_2 ,.home_series_3 ,.home_series_4 ,.home_series_5{
      background: #EEEEEE;

      p{
        font-weight: 500;
        color: #333333;
      }
    }
  }
}
.bgcolor{
  font-weight: bold;
  color: #FFFFFF;
  background: #F59F1A;
}
.home_series_one{
  background: #FFFBE8;
  padding: 60px 0;
  margin-top: 68px;
  position: relative;
  
  .home_series_jian{
    width: 83px;
    height: 83px;
    background: #FFFBE8;
    opacity: 1;
    transform: rotate(45deg);
    position: absolute;
    left: 410px;
    top: -40px;
  }

  .home_series_first{
    width: 1200px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 20px;
    margin: auto;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
    padding-top: 60px;
    padding-bottom: 60px;

    .home_series_img_1{
      margin-right: 60px;
    }

    .home_series_img_2{
      width: 536px;
      position: relative;

      h3{
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 60px;
        color: #333333;
        opacity: 1;
      }

      .home_series_img_3{
        position: absolute;
        right: 0px;
        top: -20px;
      }

      .home_series_p_1{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 28px;
        color: #333333;
        opacity: 1;
      }
    }

    .home_series_img_4{
      width: 136px;
      background: #F59F1A;
      opacity: 1;
      border-radius: 20px 0px 20px 0px;

      p{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 35px;
        color: #FFFFFF;
        opacity: 1;
        text-align: center;
        margin-top: 45px;
      }
    }

    .home_series_img_5{
      margin-top: 53px;
      overflow: hidden;

      img{
        float: left;
      }

      span{
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }

    .home_series_img_6{
      margin-top: 15px;
      overflow: hidden;

      img{
        float: left;
      }

      span{
        width: 495px;
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;

        p{
          margin-top: 15px;
        }
      }
    }
  }
}

.home_series_two{
  background: #FFFBE8;
  padding: 60px 0;
  margin-top: 68px;
  position: relative;
  
  .home_series_jian{
    width: 83px;
    height: 83px;
    background: #FFFBE8;
    opacity: 1;
    transform: rotate(45deg);
    position: absolute;
    left: 655px;
    top: -40px;
  }

  .home_series_first{
    width: 1200px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 20px;
    margin: auto;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
    padding-top: 60px;
    padding-bottom: 60px;

    .home_series_img_1{
      margin-right: 60px;
    }

    .home_series_img_2{
      width: 536px;
      position: relative;

      h3{
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 60px;
        color: #333333;
        opacity: 1;
      }

      .home_series_img_3{
        position: absolute;
        right: 0px;
        top: -20px;
      }

      .home_series_p_1{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 28px;
        color: #333333;
        opacity: 1;
      }
    }

    .home_series_img_4{
      width: 136px;
      background: #F59F1A;
      opacity: 1;
      border-radius: 20px 0px 20px 0px;

      p{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 35px;
        color: #FFFFFF;
        opacity: 1;
        text-align: center;
        margin-top: 45px;
      }
    }

    .home_series_img_5{
      margin-top: 53px;
      overflow: hidden;

      img{
        float: left;
      }

      span{
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }

    .home_series_img_6{
      margin-top: 15px;
      overflow: hidden;

      img{
        float: left;
      }

      span{
        width: 495px;
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }
  }
}

.home_series_three{
  background: #FFFBE8;
  padding: 60px 0;
  margin-top: 68px;
  position: relative;
  
  .home_series_jian{
    width: 83px;
    height: 83px;
    background: #FFFBE8;
    opacity: 1;
    transform: rotate(45deg);
    position: absolute;
    left: 900px;
    top: -40px;
  }

  .home_series_first{
    width: 1200px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 20px;
    margin: auto;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
    padding-top: 60px;
    padding-bottom: 60px;

    .home_series_img_1{
      margin-right: 60px;
    }

    .home_series_img_2{
      width: 536px;
      position: relative;

      h3{
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 60px;
        color: #333333;
        opacity: 1;
      }

      .home_series_img_3{
        position: absolute;
        right: 0px;
        top: -20px;
      }

      .home_series_p_1{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 28px;
        color: #333333;
        opacity: 1;
      }
    }

    .home_series_img_4{
      width: 136px;
      background: #F59F1A;
      opacity: 1;
      border-radius: 20px 0px 20px 0px;

      p{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 35px;
        color: #FFFFFF;
        opacity: 1;
        text-align: center;
        margin-top: 45px;
      }
    }

    .home_series_img_5{
      margin-top: 53px;
      overflow: hidden;

      img{
        float: left;
      }

      span{
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }

    .home_series_img_6{
      margin-top: 15px;
      overflow: hidden;

      img{
        float: left;
      }

      span{
        width: 495px;
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }
  }
}

.home_series_four{
  background: #FFFBE8;
  padding: 60px 0;
  margin-top: 68px;
  position: relative;
  
  .home_series_jian{
    width: 83px;
    height: 83px;
    background: #FFFBE8;
    opacity: 1;
    transform: rotate(45deg);
    position: absolute;
    left: 1145px;
    top: -40px;
  }

  .home_series_first{
    width: 1200px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 20px;
    margin: auto;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
    padding-top: 60px;
    padding-bottom: 60px;

    .home_series_img_1{
      margin-right: 60px;
    }

    .home_series_img_2{
      width: 536px;
      position: relative;

      h3{
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 60px;
        color: #333333;
        opacity: 1;
      }

      .home_series_img_3{
        position: absolute;
        right: 0px;
        top: -20px;
      }

      .home_series_p_1{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 28px;
        color: #333333;
        opacity: 1;
      }
    }

    .home_series_img_4{
      width: 136px;
      background: #F59F1A;
      opacity: 1;
      border-radius: 20px 0px 20px 0px;

      p{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 35px;
        color: #FFFFFF;
        opacity: 1;
        text-align: center;
        margin-top: 45px;
      }
    }

    .home_series_img_5{
      margin-top: 53px;
      overflow: hidden;

      img{
        float: left;
      }

      span{
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;

        p{
          margin-top: 15px;
        }
      }
    }

    .home_series_img_6{
      margin-top: 15px;
      overflow: hidden;

      img{
        float: left;
      }

      span{
        width: 495px;
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }
  }
}

.home_series_five{
  background: #FFFBE8;
  padding: 60px 0;
  margin-top: 68px;
  position: relative;
  
  .home_series_jian{
    width: 83px;
    height: 83px;
    background: #FFFBE8;
    opacity: 1;
    transform: rotate(45deg);
    position: absolute;
    left: 1395px;
    top: -40px;
  }

  .home_series_first{
    width: 1200px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 20px;
    margin: auto;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
    padding-top: 60px;
    padding-bottom: 60px;

    .home_series_img_1{
      margin-right: 60px;
    }

    .home_series_img_2{
      width: 536px;
      position: relative;

      h3{
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 60px;
        color: #333333;
        opacity: 1;
      }

      .home_series_img_3{
        position: absolute;
        right: 0px;
        top: -20px;
      }

      .home_series_p_1{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 33px;
        color: #333333;
        opacity: 1;
      }
    }

    .home_series_img_4{
      width: 186px;
      background: #F59F1A;
      opacity: 1;
      border-radius: 20px 0px 20px 0px;
      margin-bottom: 18px;

      p{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 35px;
        color: #FFFFFF;
        opacity: 1;
        text-align: center;
      }
    }

    .home_series_img_5{
      margin-top: 15px;
      overflow: hidden;

      img{
        float: left;
      }

      span{
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }

    .home_series_img_6{
      margin-top: 15px;
      overflow: hidden;

      img{
        float: left;
      }

      span{
        width: 495px;
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }
  }
}
.bottom_foot {
  width: 100%;
  height:117px;
  background:rgba(241,241,241,1);
  opacity:1;
  padding-top: 60px;
  -webkit-box-shadow: inset 0 1px 0 0 #ececec;
  box-shadow: inset 0 1px 0 0 #ececec;
  position: relative;
  z-index: 15;
  text-align: center;
  margin-bottom: 80px;

  .bottom-message {
      position: relative;
      height:17px;
      font-size:12px;
      font-family:PingFang SC;
      font-weight:500;
      line-height:17px;
      color:rgba(119,119,119,1);
      opacity:1;
  }

  .bottom-dizhi {
      position: relative;
      font-size:12px;
      font-family:PingFang SC;
      font-weight:500;
      line-height:17px;
      color:rgba(119,119,119,1);
      opacity:1;
      margin-top: 20px;

      span{
        cursor: pointer;
      }
  }
}
.bottom_get {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 160px;
  z-index: 24;
  // -webkit-transform: translateY(180px);
  // -o-transform: translateY(180px);
  // transform: translateY(180px);
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out,-o-transform .3s ease-in-out;
  min-width: 1080px;

  .bottomfooter {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-shadow: 0 2px 16px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 16px 0 rgba(0,0,0,.12);
    background:rgba(38,38,38,0.6);
    opacity:1;
    
    .bottom_free {
      position: absolute;
      bottom: 0;
      padding-left: 307px;
      -webkit-flex-basis: 1200px;
      -ms-flex-preferred-size: 1200px;
      flex-basis: 1200px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 100px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-transition: padding .3s ease-out;
      -o-transition: padding .3s ease-out;
      transition: padding .3s ease-out;
  
      .bottom_img {
        position: absolute;
        bottom: 0;
        left: 0;
        background: url('../images/home/twochilds.png') no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        width: 285px;
        height: 180px;
        opacity: 1;
        // -webkit-transition: all .3s ease-out;
        // -o-transition: all .3s ease-out;
        // transition: all .3s ease-out;
      }
  
      .bottom_valueOF {
        font-size:30px;
        font-family:PingFang SC;
        font-weight:bold;
        line-height:100px;
        color:rgba(255,255,255,1);
        opacity:1;
        margin-right: 20px;
  
        span {
          font-size:40px;
          font-family:PingFang SC;
          font-weight:800;
          line-height:56px;
          color:rgba(255,203,49,1);
          opacity:1;
          margin: 0 8px;
        }
      }
  
      .bottom_input {
        position: relative;
        -webkit-flex-basis: 569px;
        -ms-flex-preferred-size: 569px;
        flex-basis: 569px;
        overflow: hidden;
  
        .bottom_number {
          margin-right: 5px;
          background: rgba(255,255,255,1);
          border-radius: 10px;
          width: 282px;
          height: 60px;
          padding-left: 20px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          font-size: 16px;
          line-height: 42px;
          color: rgba(153,153,153,1);
          border: none;
          outline: none;
          float: left;
        }
  
        #bottom_error {
          position: absolute;
          right: 300px;
          top: 10px;
          line-height: 42px;
          margin-left: 10px;
          color: #ff5c5c;
          font-size: 16px;
        }

        .bottom_button {
          float: left;
          background: rgba(255,203,49,1);
          border-radius: 10px;
          color: #fff;
          width: 282px;
          height: 60px;
          line-height: 60px;
          font-size: 30px;
          cursor: pointer;
          -webkit-transition: all .2s;
          -o-transition: all .2s;
          transition: all .2s;
          border: none;
          outline: none;
        }

        .bottom_button:hover {
          background:rgba(255,191,0,1);
        }
      }
    }
  }
}
@media screen and (max-width: 1900px) {
  .home_series_one {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 370px;
      top: -40px;
    }
  }

  .home_series_two {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 620px;
      top: -40px;
    }
  }

  .home_series_three {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 860px;
      top: -40px;
    }
  }

  .home_series_four {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 1110px;
      top: -40px;
    }
  }

  .home_series_five {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 1360px;
      top: -40px;
    }
  }
}
@media screen and (max-width: 1650px) {
  .home_series_one {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 210px;
      top: -40px;
    }
  }

  .home_series_two {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 460px;
      top: -40px;
    }
  }

  .home_series_three {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 710px;
      top: -40px;
    }
  }

  .home_series_four {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 950px;
      top: -40px;
    }
  }

  .home_series_five {
    position: relative;

    .home_series_jian {
      width: 83px;
      height: 83px;
      background: #fffbe8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 1200px;
      top: -40px;
    }
  }
}
@media screen and (max-width:1313px){

  .bottom_img {
    display: none;
  }
  .bottom_valueOF{
    margin-left: -285px;
  }
}
.PopupWindow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.2);
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9999;
  -webkit-animation: index__fade-in___oyvx2;
  -o-animation: index__fade-in___oyvx2;
  animation: index__fade-in___oyvx2;
  -webkit-animation-duration: .3s;
  -o-animation-duration: .3s;
  animation-duration: .3s;

  .PopupEX {
    position: relative;
    width: 310px;
    height: 389px;
    padding: 51px 30px 0 260px;
    background: 50%/contain url(../images/home/popup.png) no-repeat;

    .Close_popup {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 26px;
      height: 26px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      position: absolute;
      top: 20px;
      right: 20px;
      border-radius: 50%;
      cursor: pointer;

      img {
        display: inline-block;
        width: 27px;
        height: 27px;
      }
    }

    .popup_free {
      width: 100%;
      position: relative;

      .popup_get_1{
        font-size:30px;
        font-family:PingFang SC;
        font-weight:bold;
        line-height:42px;
        color:rgba(51,51,51,1);
        opacity:1;
      }

      .popup_get_2 {
        font-size:26px;
        font-family:PingFang SC;
        font-weight:bold;
        line-height:37px;
        color:rgba(51,51,51,1);
        opacity:1;
        margin-top: 11px;

        span {
          font-size:35px;
          font-family:PingFang SC;
          font-weight:bold;
          line-height:49px;
          color:rgba(6,98,171,1);
          opacity:1;
        }
      }
    }

    .popup_quick {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: left;
      -webkit-justify-content: left;
      -ms-flex-pack: left;
      justify-content: left;
      width: 340px;
      margin: 0 auto;
      margin-top: 23px;

      .popup_quickly {
        display: inline-block;
        vertical-align: top;

        .popup_input {
          height: 49px;
          width: 310px;
          color: #333;
          background: #fff;
          border-radius: 10px;
          padding: 14px 16px;
          position: relative;
          margin-bottom: 25px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;

          input {
            width: 100%;
            height: 100%;
            padding: 0;
            font-size:16px;
            font-family:PingFang SC;
            font-weight:500;
            line-height:22px;
            color:rgba(153,153,153,1);
            opacity:1;
            border: none;
            outline: none;
          }
        }

        .popup_YZM {
          height: 49px;
          width: 310px;
          color: #333;
          background: #fff;
          border-radius: 10px;
          padding: 14px 16px;
          position: relative;
          margin-bottom: 47px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;

          input {
            width: 100%;
            height: 100%;
            padding: 0;
            font-size:16px;
            font-family:PingFang SC;
            font-weight:500;
            line-height:22px;
            color:rgba(153,153,153,1);
            opacity:1;
            outline: none;
            border: none;
          }

          span {
            display: inline-block;
            position: absolute;
            right: 0;
            top: 12px;
            width: 106px;
            height: 25px;
            padding-left: 16px;
            border-left: 2px solid rgba(204,204,204,1);
            font-size:16px;
            font-family:PingFang SC;
            font-weight:500;
            line-height:22px;
            color:rgba(6,98,171,1);
            opacity:1;
            cursor: pointer;
          }
        }

        .popup_EXP {
          cursor: pointer;
        }
      }
    }
  }
}
.Successful {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.2);
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9999;
  -webkit-animation: index__fade-in___oyvx2;
  -o-animation: index__fade-in___oyvx2;
  animation: index__fade-in___oyvx2;
  -webkit-animation-duration: .3s;
  -o-animation-duration: .3s;
  animation-duration: .3s;

  .Successful_code {
    position: relative;
    width: 503px;
    height: 397px;
    padding: 43px 0 34px 97px;
    background: 50%/contain url(../images/home/popupcode.png) no-repeat;

    .Successful_Close {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 28px;
      height: 28px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      position: absolute;
      top: 36px;
      right: 20px;
      border-radius: 50%;
      cursor: pointer;

      img {
        display: inline-block;
        width: 28px;
        height: 28px;
      }
    }

    .Successful_zero {
      width: 414px;
      position: relative;
      margin-left: 20px;

      p{
        font-size:30px;
        font-family:PingFang SC;
        font-weight:bold;
        line-height:42px;
        color:#fff;
        opacity:1;
        text-shadow: -10px -2px 8px rgb(255, 145, 0) ;

        span {
          font-size:40px;
          font-family:PingFang SC;
          font-weight:bold;
          line-height:56px;
          color:rgba(6,98,171,1);
          opacity:1;
          margin-left: 8px;
          margin-right: 8px;
          text-shadow: none;
        }
      }
    }

    .yoyoQrcode {
      width: 188px;
      height: 188px;
      margin:18px 0 15px 109px;

      img {
        height: 100%;
      }
    }

    .Successful_focus {
      width: 100%;
      margin-left: 31px;
      padding-bottom: 34px;

      .Successful_receive {
        font-size:20px;
        font-family:PingFang SC;
        font-weight:bold;
        line-height:36px;
        color:#fff;
        opacity:1;

        span {
          font-family:PingFang SC;
          font-weight:bold;
          color:rgba(6,98,171,1);
          opacity:1;
        }

        .Successful_class {
          font-size:25px;
          line-height:36px;
        }
      }
    }
  }
}
</style>